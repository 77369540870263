import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

/*
Our base font size is 16px, so for top and bottom padding here we calculate size x 8px (or 0.5rem)
Also, not all padding is created equal between mobile and desktop.
For this reason we've got the optional prop sizeDesktop which allows us to specify a different desktop size.
We used to half the size for mobile, but this is not a consistent pattern
*/
const Padding = styled.div<{ size?: number; sizeDesktop?: number }>`
  padding: ${({ size }) => (size ? `${size * 0.5}rem 0` : '1rem 0')};

  ${({ sizeDesktop }) =>
    sizeDesktop &&
    `
    @media (min-width: ${breakpoints['desktop-small']}px) {
      padding: ${sizeDesktop * 0.5}rem 0;
    }
  `}
`

export default Padding
