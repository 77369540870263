import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

import dsTheme from '../../constants/design-system/theme.json'

const { typography } = dsTheme

export const Wrapper = styled.div`
  @media (min-width: ${breakpoints.desktop}px) {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
`

export const Column = styled.div`
  @media (min-width: ${breakpoints.desktop}px) {
    flex: 3;
    &:last-of-type {
      flex: 10;
      margin-right: 2rem;
    }
  }
`

export const IntercomWrapper = styled.div`
  background-color: ${colors['Brand/PaleBlue']};
  border-radius: 8px;
  padding: 1.25rem;
  margin: 1.25rem 0;
  img {
    width: 64px;
    margin-bottom: 1.25rem;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    margin: 2rem 0;
    padding: 2.5rem;
    img {
      width: 124px;
      margin: 0 auto;
    }
  }
`

export const ButtonWrapper = styled.div`
  margin: 1rem 0;
  @media (min-width: ${breakpoints.desktop}px) {
    width: 70%;
  }
`

export const StyledH3 = styled.h3`
  ${typography['Kollektif/SmallEmphasis']};
  margin: 0 0 0.5rem;
  @media (min-width: ${breakpoints.desktop}px) {
    ${typography['Kollektif/MediumEmphasis']};
    width: 95%;
    margin-bottom: ${(props) => props.bottomMargin && '1rem'};
  }
`

export const Text = styled.p`
  ${typography['Kollektif/Small']};
  margin-bottom: 1rem;
`

export const SmallText = styled.p`
  ${typography['Kollektif/Tiny']};
`
