import React, { FC } from 'react'

import { PageSectionItem } from '@smarty-nx/contentful'
import cn from 'classnames'

import classes from './tile.module.scss'

export const Tile: FC<PageSectionItem & { className?: string }> = ({
  image,
  description,
  title,
  className,
}) => {
  return (
    <div className={cn(classes.root, className)}>
      <img
        className={classes.image}
        src={image.url}
        alt={description || title}
      />
      <span className={classes.title}>{title}</span>
    </div>
  )
}
