import React, { FC } from 'react'

import { priceToString } from '@smarty-nx/code-utils'
import { isUnlimitedPlan } from '@smarty-nx/contentful'
import cn from 'classnames'

import classes from './plan-card-compact.module.scss'
import poundIcon from '../../../static/images/icons/pound.svg'
import smiley from '../../../static/images/icons/smiley.svg'
import tagIcon from '../../../static/images/icons/tag.svg'
import { orderDmpgAttributes } from '../../../utils/dmpgAttributes'
import { convertDataToDisplay } from '../../../utils/formatters'
import { checkDataAmountIsDiff } from '../../../utils/plans'
import { PlanData, PlanMeta } from '../../../utils/plans.types'

interface PlanCardCompactProps {
  position: number
  planMeta: PlanMeta
  priceData: PlanData
  isPlanDataOnly?: boolean
  isDiscountPlan?: boolean
  rootClassName?: string
  isLabelled?: boolean
  onClick?(): void
}

export const PlanCardCompact: FC<PlanCardCompactProps> = ({
  position,
  planMeta,
  priceData,
  isPlanDataOnly = false,
  isDiscountPlan = false,
  rootClassName,
  isLabelled = false,
  onClick,
}) => {
  const { voucher, tcChip, style, originalPrice, finalPriceWithoutCredit } =
    priceData
  const defaultPrice = priceToString(originalPrice, { removeZeroPence: true })
  const hasPriceChanged = originalPrice.value !== finalPriceWithoutCredit.value
  const price = priceToString(
    hasPriceChanged ? finalPriceWithoutCredit : originalPrice,
    { removeZeroPence: true }
  )
  const defaultData = planMeta.data_number
  const [hasDataChanged, dataAmountSafe] = checkDataAmountIsDiff(
    priceData,
    planMeta
  )
  const data = convertDataToDisplay(planMeta.data_number, dataAmountSafe)
  const isUnlimited = isUnlimitedPlan(planMeta.id)

  return (
    <div
      data-testid={`${planMeta.slug}-card`}
      onClick={onClick}
      className={cn(
        classes.planInnerCard,
        classes['backgroundColour' + style?.planColor?.replace('/', '')],
        rootClassName
      )}
      {...orderDmpgAttributes(planMeta, priceData, position)}
    >
      <div className={classes.planInfo}>
        {isLabelled && (
          <span
            className={cn(
              classes.topLabel,
              classes[
                'backgroundColour' + style?.planColorPale?.replace('/', '')
              ]
            )}
          >
            {isDiscountPlan ? 'Data discount' : ''}
            {isPlanDataOnly ? 'Data only' : ''}
            {!isPlanDataOnly && !isDiscountPlan ? 'Voice' : ''}
          </span>
        )}
        <div>
          {isUnlimited ? (
            <h2
              className={cn(
                classes.planHeading,
                classes.unlimitedHeading,
                'plan-data'
              )}
              data-testid={`${planMeta.slug}-card-data`}
            >
              {planMeta.data}
            </h2>
          ) : (
            <h2
              className={cn(classes.planHeading, 'plan-data')}
              data-testid={`${planMeta.slug}-card-data`}
            >
              {data}
              <span>GB</span>
            </h2>
          )}
          <div
            className={classes.strikedData}
            aria-label={
              hasDataChanged ? `Normal data amount ${defaultData}GB` : undefined
            }
          >
            {hasDataChanged && (
              <>
                {defaultData}
                <span>GB</span>
              </>
            )}
          </div>
        </div>

        <div className={classes.priceWrapper}>
          <h2
            data-testid={`${planMeta.slug}-card-price`}
            className={classes.planPrice}
          >
            {price}
          </h2>
          {hasPriceChanged && (
            <h3
              className={classes.strikedPrice}
              aria-label={`Usually priced at ${defaultPrice}`}
              data-testid={`${planMeta.slug}-card-striked-price`}
            >
              &nbsp;{defaultPrice}&nbsp;
            </h3>
          )}
        </div>
      </div>
      <div className={classes.bottomWrapper}>
        {isDiscountPlan && (
          <p
            className={cn(
              classes.customParagraph,
              classes[
                'backgroundColour' + style?.planColorPale?.replace('/', '')
              ]
            )}
            data-testid={`${planMeta.slug}-card-discount-info`}
          >
            <img
              src={poundIcon}
              alt="Money back"
              className={classes.poundIcon}
            />
            Up to {planMeta.data_discount} back
          </p>
        )}

        {tcChip && !voucher && (
          <div className={classes.additionalInfoWrapper}>
            <img src={smiley} alt={tcChip} className={classes.smileyIcon} />
            <span>{tcChip}</span>
          </div>
        )}

        {voucher && (
          <div className={classes.additionalInfoWrapper}>
            <img
              src={tagIcon}
              alt="Voucher offer"
              className={classes.tagIcon}
            />
            <span>{voucher?.offer_chip_text}</span>
          </div>
        )}
      </div>
    </div>
  )
}
