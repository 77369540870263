import React, { FC } from 'react'

import { ContentfulProcessor } from '@smarty-nx/ds'

import classes from './reboxed.module.scss'
import { Banner } from '../banner'

interface ReboxedProps {
  items: Queries.GenericBannerFragment[]
}

export const Reboxed: FC<ReboxedProps> = ({ items }) => (
  <div className={classes.root}>
    {items.map((item, index: number) => {
      const ltr = Boolean((index + 1) % 2)
      return index === 0 ? (
        <div className={classes.first} key={item.title}>
          <h3 className={classes.header}>{item.title}</h3>
          <ContentfulProcessor
            content={item.description?.internal.content || ''}
          />
        </div>
      ) : (
        <Banner {...item} ltr={ltr} buttonLink={false} key={item.type} />
      )
    })}
  </div>
)
