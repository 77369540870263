import React from 'react'

import { css } from 'styled-components'

import loading from './loading.svg'

const LoadingDots = () => (
  <img
    alt="Animating ellipsis dots that show while something is loading"
    css={css`
      width: 40px;
    `}
    src={loading}
  />
)

export default LoadingDots
