import pageSections from '../../data/pageSections.json'

export interface PageSectionItem {
  title: string
  type: string
  description?: string
  image: {
    title: string
    url: string
  }
  link: string
}

export interface DismissibleCardItem {
  title?: string
  itemId?: string
  markdownDescription?: string
  ctaLink?: string
  backgroundImage?: {
    title: string
    url: string
  }
  icon?: {
    title: string
    file: {
      url: string
    }
  }
}

export const getPageSectionByName = (name: string) => {
  return pageSections.find(({ sectionName }) => sectionName === name)
}

export const getDismissibleCardsPageSection = () =>
  getPageSectionByName('dismissible-cards')

export const getReasonsToChoosePageSection = () =>
  getPageSectionByName('reasons-to-choose')

export const getWhatWeOfferPageSection = () =>
  getPageSectionByName('what-we-offer')

export const getYouMightBeInterestedPageSection = () =>
  getPageSectionByName('you-might-be-interested')

export const getTimelinePageSection = () => getPageSectionByName('how-it-works')

export const getWhyReboxedDashboardSection = () =>
  getPageSectionByName('why-reboxed-dashboard')

export const getAboutReboxedDashboardSection = () =>
  getPageSectionByName('about-reboxed-dashboard')

export const getReboxedPhonesMarketingSection = () =>
  getPageSectionByName('reboxed-phones-marketing')

export const getAboutReboxedMarketingSection = () =>
  getPageSectionByName('about-reboxed-marketing')

export const getRefurbishedPhonesFaqSection = () =>
  getPageSectionByName('dashboard-reboxed-faq-list')

export const getHomePagePlansCarouselSection = () =>
  getPageSectionByName('marketing-home-page-plans-carousel')

export const getAllPlansPageCarouselSection = () =>
  getPageSectionByName('marketing-all-plans-page-carousel')

export const getDashboardBannersCarouselSection = () =>
  getPageSectionByName('dashboard-banners-carousel')

export const getHowItWorksPageSection = () =>
  getPageSectionByName('how-it-works-dashboard-fp-invitation')

export const getSwitchingServicesFAQSection = () =>
  getPageSectionByName('switching-services-faq')
