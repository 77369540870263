import React from 'react'

import { List, ListItem } from './AllPlans.styles'
import { ToolTipHeading } from '../../components/DataOnlySimPlans/styles'
import { TooltipModal } from '../../components/Modals'
import { ListFragment } from '../../components/styles/Lists'

const toolTipModal = () => (
  <TooltipModal
    hasImage={false}
    tooltipComponent={<span>Learn more</span>}
    header="EU Roaming"
  >
    <ToolTipHeading>Use your SMARTY plan anywhere in the EU</ToolTipHeading>
    <List>
      <ListItem>
        If your plan includes calls and texts, they work just like home. They’re
        unlimited with no extra fees.
      </ListItem>
      <ListItem>
        Subject to our fair use limit, you can use up to 12GB of data each
        month. Data comes out your monthly allowance or data add-ons.
      </ListItem>
      <ListItem>No hidden charges. No extra costs.</ListItem>
      <ListItem>
        <a
          href="https://cdn.smarty.co.uk/files/SMARTY-Price-Guide.pdf"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="See all prices .PDF (253KB) link opens in a new window"
        >
          Please see our Price Guide for more information on our Fair Use
          Policy.
        </a>
      </ListItem>
    </List>
  </TooltipModal>
)

export const allPlansContent = {
  title: 'Our plans include',
}

export const dataOnlyPlansContent = {
  title: 'Data Only plans include',
}

export const socialTariffPlansContent = {
  title: 'Social Tariff plan includes',
}

export const planDetailsLongList = [
  '1 month plan, cancel anytime',
  <ListFragment key={1}>
    <span>Unlimited calls & texts</span>
    <span className="subtext">(Excluding Data Only Plans)</span>
  </ListFragment>,
  <ListFragment key={2}>
    <span>Fast 5G & 4G data</span>
    <span className="subtext">
      5G available with{' '}
      <a
        href="http://help.smarty.co.uk/en/articles/5871549-which-devices-are-compatible-with-5g"
        target="_blank"
        rel="noopener noreferrer"
      >
        enabled devices
      </a>
    </span>
  </ListFragment>,
  'No credit check',
  'Unrestricted tethering in the UK',
  'No speed caps',
  <ListFragment key={3}>
    <span>EU Roaming</span>

    <span className="subtext">
      Use your plan in Europe (12GB fair use limit) {toolTipModal()}
    </span>
  </ListFragment>,
  'SIM, Micro-SIM, & Nano-SIM sizes',
]

export const socialTariffList = [
  '1 month plan, cancel anytime',
  <ListFragment key={1}>
    <span>Unlimited UK calls & texts</span>
  </ListFragment>,
  <ListFragment key={2}>
    <span>Fast 5G & 4G data</span>
    <span className="subtext">
      5G available with{' '}
      <a
        href="http://help.smarty.co.uk/en/articles/5871549-which-devices-are-compatible-with-5g"
        target="_blank"
        rel="noopener noreferrer"
      >
        enabled devices
      </a>
    </span>
  </ListFragment>,
  'No credit check',
  'Unrestricted tethering in the UK',
  'No speed caps',
  <ListFragment key={3}>
    <span>EU Roaming</span>

    <span className="subtext">
      Use your plan in Europe (12GB fair use limit) {toolTipModal()}
    </span>
  </ListFragment>,
  'SIM, Micro-SIM, & Nano-SIM sizes',
]

export const dataOnlyPlanDetailsList = [
  '1 month plan, cancel anytime',
  <ListFragment key={1}>
    <span>Fast 5G & 4G data</span>
    <span className="subtext">
      5G available with{' '}
      <a
        href="http://help.smarty.co.uk/en/articles/5871549-which-devices-are-compatible-with-5g"
        target="_blank"
        rel="noopener noreferrer"
      >
        enabled devices
      </a>
    </span>
  </ListFragment>,
  'No credit check',
  'Unrestricted tethering in the UK',
  'No speed caps',
  <ListFragment key={2}>
    <span>EU Roaming</span>

    <span className="subtext">
      Use your plan in Europe (12GB fair use limit) {toolTipModal()}
    </span>
  </ListFragment>,
  'SIM, Micro-SIM, & Nano-SIM sizes',
]
