import { breakpoints } from '@smarty-nx/ds'
import styled, { css } from 'styled-components'

import Button from '../Button'

const ICON_SIZE = 38
const ICON_SPACING_LARGE = 16
const CONTENT_OFFSET = ICON_SIZE + ICON_SPACING_LARGE

const Columns = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column-reverse;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    flex-direction: row;
  }
`

const Column = styled.div`
  width: 100%;
  margin-bottom: ${({ condense }) => (condense ? 0 : '32px')};

  @media (min-width: ${breakpoints['desktop-small']}px) {
    width: ${(props) => props.width || '50%'};
    margin-bottom: 0;
  }
`

const Heading = styled.h2`
  align-self: center;
  grid-row: 1/2;
  grid-column: 3/-1;
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    margin-bottom: 0;
  }
`

const ButtonWrapper = styled.div`
  display: none;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: block;
  }
`

const StyledButton = styled(Button)`
  margin: 0 auto 16px;
`

const SecondaryCta = styled.p`
  align-self: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
`

const customIcon = css`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  margin-bottom: 1rem;
  grid-row: 1/-1;
  grid-column: 1/2;

  @media (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 0;
  }
`

const List = styled.ul`
  list-style-type: none;
  padding: 2.5rem 0 0;
  margin: 0;

  @media (min-width: ${breakpoints.desktop}px) {
    margin-left: -${CONTENT_OFFSET}px;
  }
`

const ListItem = styled.li`
  text-decoration: none;

  @media (min-width: ${breakpoints.desktop}px) {
    display: grid;
    grid-template-rows: ${ICON_SIZE}px 8px auto;
    grid-template-columns: ${ICON_SIZE}px ${ICON_SPACING_LARGE}px auto;
  }
`
const ListParagraph = styled.p`
  grid-row: 3/-1;
  grid-column: 3/-1;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 1.25;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    font-size: 18px;
    line-height: 1.44;
  }
`

const AnimationImage = styled.img`
  max-width: 272px;
  height: 547px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${breakpoints.desktop}px) {
    margin: 0;
  }
`

export {
  Columns,
  Column,
  Heading,
  ButtonWrapper,
  StyledButton,
  SecondaryCta,
  customIcon,
  List,
  ListItem,
  ListParagraph,
  AnimationImage,
}
