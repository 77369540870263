import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

import { theme } from '../../constants/theme'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;
  height: 100%;
  width: 100%;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    max-width: 80%;
    margin: 0 auto;
    padding: 60px 0;
  }
`

export const QuoteMark = styled.img`
  width: 100%;
  height: auto;
  max-width: 26px;
  padding-bottom: 16px;
  @media (min-width: ${breakpoints.tablet}px) {
    padding-bottom: 32px;
  }
`

export const BlockQuote = styled.blockquote`
  font-weight: bold;
  font-size: 34px;
  line-height: 38px;
  padding-bottom: 16px;
  margin: 0;
  text-align: center;
  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 46px;
    line-height: 54px;
    padding-bottom: 24px;
  }
  @media (min-width: ${breakpoints['desktop-small']}px) {
    max-width: ${breakpoints['desktop-small']}px;
  }
`

export const Source = styled.p`
  font-family: ${theme.fonts.fontFamilySecondary};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors['UI/LightGrey']};
`
