import React, { forwardRef } from 'react'

import classes from './checkbox.module.scss'
import { CheckboxProps } from './checkbox.types'
import { Icon } from '../icon/icon'
import { Label } from '../label/label'

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ labelContent, dataTestId, labelTestId, ...props }, ref) => {
    return (
      <div className={classes.inputWrapper}>
        <input
          className={classes.checkbox}
          ref={ref}
          data-testid={dataTestId || props.name + '-checkbox'}
          id={props.name}
          name={props.name}
          type="checkbox"
          {...props}
        />
        <Label
          className={classes.checkboxLabel}
          isInline
          htmlFor={props.name || ''}
          text={labelContent}
          dataTestId={labelTestId}
        >
          <div className={classes.customCheckbox}>
            <Icon className={classes.checkmark} name="checkmark-new" />
          </div>
        </Label>
      </div>
    )
  }
)
