import React from 'react'

import ReactModal from 'react-modal'

import {
  ContentWrapper,
  Image,
  modalStyle,
  SmallHeader2,
  StyledButton,
} from './Modals.styles'
import CloseIcon from '../../static/images/close-icon.svg'

interface ModalProps extends ReactModal.Props {
  children: React.ReactNode
  dataTestId?: string
  header?: string
  toggleModal: () => void
}

export const SimpleModal = ({
  children,
  dataTestId,
  header,
  isOpen = false,
  toggleModal,
  ...props
}: ModalProps) => (
  <ReactModal
    data-testid={dataTestId}
    style={modalStyle}
    isOpen={isOpen}
    {...props}
  >
    <StyledButton onClick={() => toggleModal()} aria-label="Close Modal">
      <Image src={CloseIcon} alt="" />
    </StyledButton>
    <ContentWrapper>
      {header && <SmallHeader2>{header}</SmallHeader2>}
      {children}
    </ContentWrapper>
  </ReactModal>
)
