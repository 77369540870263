import React from 'react'

import { CoverageParagraph, Wrapper } from './styles'
import ThreeLogo from '../../static/images/threeLogoUrl.svg'
import Icon from '../styles/Icon'
import TextLink from '../TextLink'

const CoverageChecker = () => (
  <Wrapper data-testid="coverage-checker">
    <CoverageParagraph>
      <TextLink to="/coverage-checker" dataTestId="coverage-checker-link">
        Check your coverage
      </TextLink>
      , powered by
    </CoverageParagraph>
    <Icon
      src={ThreeLogo}
      width="auto"
      height="45px"
      margin="0 0 0 10px"
      alt="three"
      loading="lazy"
      data-testid="three-icon"
    />
  </Wrapper>
)

export default CoverageChecker
