import React from 'react'

import { colors } from '@smarty-nx/ds'

import {
  HeroBanner,
  HeroSection,
  ListOfBanners,
  Tiles,
  SmallTiles,
  Columns,
  Quote,
  TwoTiles,
  TextBlock,
  Tabs,
  PlansCarousel,
} from './page-sections'
import { SiteContainer, FullWidthContainer } from '../../components/styles'
import Spacer from '../../components/styles/Spacer'
import TrustPilot from '../../components/TrustPilot'
import FaqSection from '../../modular-system/FaqSection'
import { AwardsSection } from '../../modules/page-section'
import { PlansSection } from '../plans-section/plans-section'

export const pageSectionsMapping = {
  'Hero Banner': (
    section: Queries.GenericPageSectionFragment & { index: number }
  ) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <SiteContainer>
        <HeroBanner {...section} />
      </SiteContainer>
    </FullWidthContainer>
  ),

  'List of Banners': (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <SiteContainer>
        <ListOfBanners {...section} />
      </SiteContainer>
    </FullWidthContainer>
  ),

  Tiles: (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <SiteContainer>
        <Tiles {...section} />
      </SiteContainer>
    </FullWidthContainer>
  ),

  'Small Tiles': (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <SiteContainer>
        <SmallTiles {...section} />
      </SiteContainer>
    </FullWidthContainer>
  ),

  Columns: (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <SiteContainer>
        <Columns {...section} />
      </SiteContainer>
    </FullWidthContainer>
  ),

  FAQ: (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <SiteContainer>
        <Spacer />
        <FaqSection {...section} />
        <Spacer size={2} />
      </SiteContainer>
    </FullWidthContainer>
  ),

  'Main Hero': (
    section: Queries.GenericPageSectionFragment & { index: number }
  ) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <HeroSection {...section} />
    </FullWidthContainer>
  ),

  Awards: (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <SiteContainer>
        <AwardsSection {...section} />
      </SiteContainer>
    </FullWidthContainer>
  ),

  Plans: (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <PlansSection {...section} />
    </FullWidthContainer>
  ),

  PlansCarousel: (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <PlansCarousel {...section} />
    </FullWidthContainer>
  ),

  Trustpilot: (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <TrustPilot dataTestId="homepage-trustpilot-widget" />
    </FullWidthContainer>
  ),

  Quote: (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <SiteContainer>
        <Quote {...section} />
      </SiteContainer>
    </FullWidthContainer>
  ),

  'Two Tiles': (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <TwoTiles {...section} />
    </FullWidthContainer>
  ),

  'Text Block': (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <SiteContainer>
        <TextBlock {...section} />
      </SiteContainer>
    </FullWidthContainer>
  ),

  Tabs: (section: Queries.GenericPageSectionFragment) => (
    <FullWidthContainer background={colors[section.bg?.theme || '']}>
      <SiteContainer>
        <Tabs {...section} />
      </SiteContainer>
    </FullWidthContainer>
  ),

  Hidden: null,
  'Not for Static Page': null,
} as const
