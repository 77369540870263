import React, { EventHandler, FC, ReactNode, useState } from 'react'

import { getThisPlanIncludesGroup } from '@smarty-nx/contentful'
import { BulletPointItem, Button, Icon } from '@smarty-nx/ds'
import { Link } from 'gatsby'

import { Bubbles } from './bubbles'
import classes from './selected-plan.module.scss'
import {
  CHECKOUT_TYPES,
  TRACKING_CATEGORIES,
  TRACKING_STRINGS,
} from '../../../../../constants/globalStrings'
import { triggerProductEvent } from '../../../../../utils/dataLayer'
import { buildAssetsProductsProps } from '../../../../../utils/dmpgAttributes'
import { PlanCardCompact } from '../../../../components'
import { Plan } from '../../../../page-section/popular-plans/types'
import { WiFi } from '../wifi/wifi'

declare module 'react' {
  interface CSSProperties {
    '--border-color'?: string
  }
}

interface CollapsibleProps {
  label: (cb: EventHandler<any>, isOpen: boolean) => ReactNode
  children: ReactNode
}

const Collapsible: FC<CollapsibleProps> = ({ children, label }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {label(() => setOpen(!open), open)}
      <div className={open ? classes.expanded : classes.collapsed}>
        {children}
      </div>
    </>
  )
}

export const SelectedPlan: FC<{
  plan: Plan
  position: number
}> = ({ plan, position }) => {
  const hasVoucher = !!plan.priceData.voucher
  const content = getThisPlanIncludesGroup(plan.planMeta.slug)

  const tcChip =
    plan.priceData.voucher?.offer_chip_text || plan.priceData.tcChip
  const tcText =
    plan.priceData.voucher?.plan_card_heading || plan.priceData.tcText

  return (
    <div
      className={classes.root}
      style={{
        '--border-color': plan.planMeta.color,
      }}
    >
      {tcChip && <div className={classes.chip}>{tcChip}</div>}

      <div className={classes.plan}>
        {(tcChip || tcText) && (
          <Bubbles
            color={plan.planMeta.color}
            colorLight={plan.planMeta.colorLight}
          />
        )}
        <Link
          to={`/plans/${plan.planMeta.slug}`}
          className={classes.link}
          data-item-category={TRACKING_CATEGORIES.NAVIGATION}
          data-item-name={`carousel-${plan.planMeta.slug}`}
        >
          <PlanCardCompact
            position={position}
            planMeta={plan.planMeta}
            priceData={plan.priceData}
            isLabelled
          />
        </Link>
      </div>

      <div className={classes.extra}>
        <div className={classes.text}>
          {tcText || 'Great value data with unlimited calls and texts'}
        </div>
        <div className={classes.actions}>
          <Button
            data-testid={`${plan.planMeta.slug}-card-link`}
            onClick={() =>
              triggerProductEvent(
                TRACKING_STRINGS.ADD_TO_CART,
                buildAssetsProductsProps(plan).products[0],
                CHECKOUT_TYPES.REGULAR
              )
            }
            as={(props) => (
              <a
                {...props}
                rel="noopener noreferrer"
                href={`/order/${plan.planMeta.slug}`}
              >
                Buy plan
              </a>
            )}
          />
          {hasVoucher && (
            <Button
              level="tertiary"
              className={classes.tc}
              as={(props) => (
                <a
                  {...props}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={plan.priceData.voucher?.terms_and_conditions_url}
                >
                  Full offer T&C
                </a>
              )}
            />
          )}
        </div>
      </div>

      <Collapsible
        label={(onClick, isOpen) => (
          <div onClick={onClick} className={classes.details}>
            View plan details
            <Icon
              name="chevron"
              className={isOpen ? classes.iconOpen : classes.iconClosed}
            />
          </div>
        )}
      >
        <ul
          className={classes.items}
          style={{
            '--checkmark-color-primary': plan.planMeta.colorLight,
            '--checkmark-color-secondary': plan.planMeta.color,
          }}
        >
          {content?.planIncludes.map((item) => {
            return (
              <BulletPointItem
                key={item.title}
                text={item.title}
                subText={item.subText}
                as="li"
              />
            )
          })}
          <WiFi />
        </ul>
      </Collapsible>
    </div>
  )
}
