import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Plan } from '@smarty-nx/data'
import jwtDecode from 'jwt-decode'

import api from '../../utils/api'
import { RootState } from '../rootReducer'

export interface DashboardData {
  auth: {
    jwt: string
    expiry: number
    hardExpiry: number
  }
  planStatus: string
  currentPlan: Plan | null

  [x: string]: unknown
}

interface DashboardState {
  data: DashboardData | null
}

const initialState: DashboardState = {
  data: null,
}

export const fetchRefreshToken = createAsyncThunk<
  { attributes: { jwt: string } },
  void,
  { state: RootState }
>('dashboard', async (_, { getState }) => {
  const state = getState()
  const jwt = state?.dashboard?.data?.auth?.jwt

  const { data } = await api.post(
    `/auth/refresh`,
    {},
    {
      withCredentials: true,
      headers: {
        Authorization: `Bearer: ${jwt}`,
      },
    }
  )

  return data.data
})

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRefreshToken.fulfilled, (state, action) => {
      if (state?.data) {
        const { jwt } = action.payload.attributes
        const { exp, hexp } = jwtDecode(jwt) as { hexp: number; exp: number }
        state.data.auth = { jwt, expiry: exp * 1000, hardExpiry: hexp * 1000 }
      }
    })
  },
})

export const selectUserJwt = ({ dashboard }: RootState) =>
  dashboard.data?.auth.jwt

export const selectUserJwtExpiry = ({ dashboard }: RootState) =>
  dashboard.data?.auth.expiry || null

export const selectUserJwtHardExpiry = ({ dashboard }: RootState) =>
  dashboard.data?.auth.hardExpiry || null

export default dashboardSlice.reducer
