import React from 'react'

export const PlanDataOnlyContent = {
  heading: 'Data Only plans',
  subHeading: 'Just great value data',
  toolTipSubHeading: 'No calls and texts, just great value data.',
  dataSimTooltipList: [
    'Use data in the UK and roaming EU countries',
    'Easy to manage alongside your main SIM',
    'Save 10% on every SIM in a Group plan',
  ],
  note: <strong>Note: </strong>,
  noteText:
    'Data Only plans cannot be switched or upgraded to SMARTY Unlimited calls and text plans or Money Back plans just yet.',
}

export const PlanDataOnlyContentPhase2 = {
  heading: 'Data Only plans',
  subHeading: 'Just great value data',
  toolTipSubHeading: 'No calls and texts, just great value data.',
  dataSimTooltipList: [
    'Use data in the UK and roaming EU countries',
    'Unrestricted hotspotting and no speed limits',
    'No contract: 1 month plan',
  ],
  note: <strong>Note: </strong>,
  noteText:
    'Data Only plans cannot be switched or upgraded to Voice plans or Money Back plans just yet.',
}
