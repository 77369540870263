import React, { FC } from 'react'

import {
  Button,
  ContentfulProcessor,
  Link,
  SliderOnMobile,
} from '@smarty-nx/ds'
import { Link as GatsbyLink } from 'gatsby-link'

import classes from './tiles.module.scss'
import { Heading } from '../../../../components/styles/Text'
import { isBanner } from '../../../content-type-checks'

export const Tiles: FC<Queries.GenericPageSectionFragment> = (section) => (
  <div className={classes.root}>
    {section.header && (
      <Heading className={classes.heading}>{section.header}</Heading>
    )}
    <SliderOnMobile
      options={{
        className: classes.slides,
        spaceBetween: 12,
        breakpoints: {
          0: {
            enabled: true,
            slidesPerView: 'auto',
          },
          760: {
            enabled: false,
          },
        },
      }}
      navigation={false}
      controlsClassName={classes.controls}
    >
      {(section.items || []).filter(isBanner).map((item) => (
        <div className={classes.tile} key={item.title}>
          <img
            className={classes.icon}
            src={item.image?.url || ''}
            alt={item.title}
          />
          <div className={classes.tileTitle}>{item.title}</div>

          {item.description?.internal.content && (
            <div className={classes.tileDescription}>
              <ContentfulProcessor
                content={item.description.internal.content}
              />
            </div>
          )}
          {item.link?.internal.content && (
            <>
              <br />
              <ContentfulProcessor content={item.link.internal.content} />
            </>
          )}
        </div>
      ))}
    </SliderOnMobile>

    <ContentfulProcessor
      content={section.link?.internal.content || ''}
      components={{
        a({ node: _, children, href, ...props }) {
          return (
            <Button
              {...(props as Record<string, unknown>)}
              level="primary"
              className={classes.ctaButton}
              as={(props) => (
                <Link href={href} props={props} component={GatsbyLink}>
                  {children}
                </Link>
              )}
            />
          )
        },
      }}
    />
  </div>
)
