import React from 'react'

import PropTypes from 'prop-types'

import { Icon, Root, TextWrapper } from './styles'
import { TRACKING_CATEGORIES } from '../../constants/globalStrings'
import Wifi from '../../static/images/wifi-calling.svg'

const WifiCallingBanner = ({ hasDarkBackground }) => (
  <Root hasDarkBackground={hasDarkBackground}>
    <TextWrapper>
      <Icon src={Wifi} alt="" />
      <p data-testid="wifi-calling-text">
        <strong>
          All plans include WiFi Calling to supercharge your signal
        </strong>
      </p>
      <Icon src={Wifi} alt="" hideDesktop />
    </TextWrapper>
    <div>
      <a
        href="https://smarty.co.uk/blog/wifi-calling"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="wifi-calling-link"
        data-item-category={TRACKING_CATEGORIES.NAVIGATION}
        data-item-name="learn-more-wifi-calling"
      >
        Learn more
      </a>
    </div>
  </Root>
)

WifiCallingBanner.propTypes = {
  hasDarkBackground: PropTypes.bool,
}

export default WifiCallingBanner
