import React, { FC } from 'react'

import { ContentfulProcessor } from '@smarty-nx/ds'
import cn from 'classnames'

import FaqItem from './FaqItem'
import classes from './FaqSection.module.scss'
import { faqSectionContent } from './FaqSection.strings'
import Padding from '../../components/styles/Padding'
import Spacer from '../../components/styles/Spacer'
import TextLink from '../../components/TextLink'
import { isBanner, isFaq } from '../../node_pages/content-type-checks'
import FaqBanner from '../FaqBanner'

interface FaqSectionProps extends Queries.FaqSectionFragment {
  isReferAFriend?: boolean
  darkText?: boolean
}

const FaqSection: FC<FaqSectionProps> = ({
  header,
  items,
  link,
  darkText,
  isReferAFriend,
}) => {
  if (!items) {
    return null
  }

  const faqAccordions = items.filter(isFaq)
  const faqBanners = items.filter(isBanner)

  /* half the faq content array (using ceil to round up) and assign to left and right columns */
  const leftAccordions = faqAccordions.slice(
    0,
    Math.ceil(faqAccordions.length / 2)
  )
  const rightAccordions = faqAccordions.slice(
    Math.ceil(faqAccordions.length / 2),
    faqAccordions.length
  )

  return (
    <>
      {isReferAFriend ? (
        <>
          <Spacer />
          <h2 className={cn(classes.faqTitle, classes.alignCenter)}>
            {header}
          </h2>
          <p className={classes.centered}>
            {faqSectionContent.onlyReferFriendFAQs}{' '}
            <TextLink to="/#faq">
              {faqSectionContent.viewFAQsOnNetwork}
            </TextLink>
          </p>
        </>
      ) : (
        <h2
          className={classes.faqTitle}
          data-testid={faqSectionContent.faqTitleId}
        >
          {header}
        </h2>
      )}
      <section
        className={classes.faqContainer}
        data-module-name="faq"
        data-testid="faqs-section"
      >
        <div className={classes.faqColumn}>
          {leftAccordions.map((faq) => (
            <FaqItem {...faq} darkText={darkText} key={faq.faqId} />
          ))}
        </div>
        <div className={classes.faqColumn}>
          {rightAccordions.map((faq) => (
            <FaqItem {...faq} darkText={darkText} key={faq.faqId} />
          ))}
        </div>
      </section>
      <Padding size={1.75} />

      {link && (
        <p className={cn(isReferAFriend && classes.centered)}>
          <ContentfulProcessor
            content={link.internal.content || ''}
            components={{
              a({ node: _, children, ...props }) {
                return <TextLink {...props}>{children}</TextLink>
              },
            }}
          />
        </p>
      )}

      <Spacer />
      {faqBanners.length > 0 && (
        <div className={classes.banners}>
          {faqBanners.map((banner) => (
            <FaqBanner banner={banner} key={banner.type} />
          ))}
        </div>
      )}
    </>
  )
}

export default FaqSection
