import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Plan, PlanV1 } from '@smarty-nx/data'

import api from '../../utils/api'
import { RootState } from '../rootReducer'

interface PlansState {
  loading: boolean
  plans: (PlanV1 | Plan)[]
}

const initialState: PlansState = {
  loading: false,
  plans: [],
}

export const fetchPlans = createAsyncThunk<Plan[], void, { state: RootState }>(
  'plans',
  async (_, { getState }) => {
    const state = getState()

    const queryParams = new URLSearchParams(window.location.search)
    const voucherCode =
      queryParams.get('voucher_code') ||
      state.smartySharedData.voucherCode ||
      ''
    const voucherToken = queryParams.get('preview_token')

    const plansUrl = `/v3/plans?voucher_code=${voucherCode}${
      voucherToken ? `&preview_token=${voucherToken}` : ''
    }`

    const { data } = await api.get<{
      data: {
        attributes: {
          plans: Plan[]
        }
      }
    }>(plansUrl)

    const plansData = data.data.attributes.plans

    return plansData
  }
)

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlans.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchPlans.fulfilled, (state, action) => {
      state.loading = false
      state.plans = action.payload
    })

    builder.addCase(fetchPlans.rejected, (state) => {
      state.loading = false
    })
  },
})

export default plansSlice.reducer
