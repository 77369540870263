import React, { FC } from 'react'

import { ContentfulProcessor } from '@smarty-nx/ds'

import classes from './text-block.module.scss'
import { isBanner } from '../../../content-type-checks'

export const TextBlock: FC<Queries.GenericPageSectionFragment> = (section) => (
  <div className={classes.root}>
    {(section.items || []).filter(isBanner).map((item) => (
      <div key={item.id}>
        <p className={classes.title}>
          <ContentfulProcessor content={item.title} />
        </p>
        {item.description?.internal.content && (
          <p className={classes.text}>
            <ContentfulProcessor content={item.description.internal.content} />
          </p>
        )}
      </div>
    ))}
  </div>
)
