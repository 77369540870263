import React from 'react'

import { Script } from 'gatsby'

import { Layout } from './src/components/Layout/Layout'

const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>
    <>
      {element}
      <Script
        strategy="post-hydrate"
        id="client"
        src="https://js.braintreegateway.com/web/3.81.0/js/client.min.js"
      />
      <Script
        strategy="post-hydrate"
        id="checkout"
        src="https://js.braintreegateway.com/web/3.81.0/js/paypal-checkout.min.js"
      />
      <Script
        strategy="post-hydrate"
        id="collector"
        src="https://js.braintreegateway.com/web/3.81.0/js/data-collector.min.js"
      />
      <Script
        strategy="post-hydrate"
        id="polyfill"
        src="/assets/polyfills/polyfill.min.js"
        type="text/javascript"
      />
      <Script
        strategy="post-hydrate"
        id="url-polyfill"
        src="/assets/polyfills/url-polyfill.min.js"
        type="text/javascript"
      />
      <Script
        strategy="post-hydrate"
        id="mutationobserver"
        src="/assets/polyfills/mutationobserver.min.js"
        type="text/javascript"
      />
    </>
  </Layout>
)

export default wrapPageElement
