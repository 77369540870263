import { breakpoints, colors } from '@smarty-nx/ds'
import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

import dsTheme from './design-system/theme.json'
import { globalTypography } from './design-system/typography'

const { typography } = dsTheme

const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${globalTypography}

  * {
    box-sizing: border-box;
  }

  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  body {
    background: ${colors['Brand/PrimaryBlack']};
    color: ${colors['UI/White']};
    margin: 0;
    padding: 0;
    width: 100%;
  }

  img {
    display: block;
    width: 100%;
  }

  h1, h2, h3, h4, h5 {
    font-weight: bold;
    margin: 0;
    padding: 0;
  }

  h1 {
    ${typography['Kollektif/ExtraLarge']}
    margin-bottom: 24px;
    @media (min-width: ${breakpoints['desktop-small']}px) {
      ${typography['Kollektif/Mega']}
    }
  }

  h2 {
    ${typography['Kollektif/Large']}
    margin-bottom: 32px;
    @media (min-width: ${breakpoints['desktop-small']}px) {
      ${typography['Kollektif/ExtraLarge']}
    }
  }

  h3 {
    ${typography['Kollektif/Small']}

    @media (min-width: ${breakpoints['desktop-small']}px) {
      ${typography['Kollektif/Large']}
    }
  }

  h4 {
    font-size: 18px;
    line-height: 19px;
  }

  p {
    padding: 0;
    line-height: 26px;
    margin: 0;

    &.margin--regular {
      margin-bottom: 1rem;
    }
    &.margin--large {
      margin-bottom: 1.5rem;
    }
    &.margin--none {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: bold;
  }

  a {
    color: currentColor;
  }

  small {
    ${typography['Kollektif/ExtraSmall']}
    margin: 0;
    padding: 0;
  }

  .noMargin {
    margin: 0;
  }

  .expComponent {
    display: none;
  }
`

export default GlobalStyle
