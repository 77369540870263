import React, { FC } from 'react'

import { PageSectionItem } from '@smarty-nx/contentful'
import { ContentfulProcessor, Icon } from '@smarty-nx/ds'
import { Link } from 'gatsby'

import classes from './tile.module.scss'

export const Tile: FC<PageSectionItem> = ({
  image,
  description,
  title,
  link,
}) => {
  return (
    <ContentfulProcessor
      content={link}
      components={{
        a({ node: _, href, ...props }) {
          return (
            <Link
              to={href || ''}
              title={description || title}
              className={classes.root}
              {...props}
            >
              <img className={classes.image} src={image.url} alt="" />
              <span className={classes.title}>{title}</span>
              <Icon className={classes.arrow} name="arrow" />
            </Link>
          )
        },
      }}
    />
  )
}
