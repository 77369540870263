import React from 'react'

import { colors } from '@smarty-nx/ds'

import {
  AnimationImage,
  ButtonWrapper,
  Column,
  Columns,
  customIcon,
  Heading,
  List,
  ListItem,
  ListParagraph,
  SecondaryCta,
  StyledButton,
} from './styles'
import { CONFIG } from '../../config'
import Addons from '../../static/images/addons.svg'
import Checkmark from '../../static/images/checkmark.svg'
import Unlimited from '../../static/images/unlimited.svg'
import Icon from '../styles/Icon'
import SiteContainer from '../styles/SiteContainer'
import Spacer from '../styles/Spacer'
import TextLink from '../TextLink'

const GroupWhy = () => (
  <SiteContainer>
    <Columns>
      <Column width="42%">
        <AnimationImage
          src="/assets/images/groupPreviewAnimation.gif"
          height="640"
          alt="Group preview animation"
          width="322"
        />
      </Column>
      <Column width="61%" condense>
        <List>
          <ListItem>
            <Icon src={Unlimited} alt="" css={customIcon} />
            <Heading>Choose a plan that’s right for you</Heading>
            <ListParagraph>
              Whether you’d like unlimited calls and texts, just data, or money
              back for unused data, pick the plan that suits your needs.
            </ListParagraph>
          </ListItem>
          <ListItem>
            <Icon src={Addons} alt="" css={customIcon} />
            <Heading>Easy account management</Heading>
            <ListParagraph>
              Manage everyone’s usage and payments from one place. Change plans
              or purchase add-ons for each SIM.
            </ListParagraph>
          </ListItem>
          <ListItem>
            <Icon src={Checkmark} alt="" css={customIcon} />
            <Heading>Save an extra 10% on every plan</Heading>
            <ListParagraph>
              Add up to 8 SIMs on one account, ideal for multi person households
              and devices. And, if you choose a money back plan, you’ll also get
              a data discount for unused data.
            </ListParagraph>
          </ListItem>
        </List>
        <ButtonWrapper>
          <StyledButton
            href={`${CONFIG.DASHBOARD_URL}groups`}
            color={colors['Brand/PrimaryGreen']}
          >
            Start a Group
          </StyledButton>
          <SecondaryCta>
            New to SMARTY?{' '}
            <TextLink href="/select-plan">Choose a plan to start</TextLink>
          </SecondaryCta>
        </ButtonWrapper>
      </Column>
    </Columns>
    <Spacer size={1} />
  </SiteContainer>
)

export default GroupWhy
