import React from 'react'

import PropTypes from 'prop-types'

import { Box, HamburgerWrapper, Inner } from './styles'

const Hamburger = ({ open, onClick }) => {
  const menuTitle = open ? 'Close' : 'Menu'

  return (
    <HamburgerWrapper
      className="redesign-burger"
      open={open}
      onClick={onClick}
      data-testid="burger-menu"
      title={menuTitle}
    >
      <Box>
        <Inner open={open} />
      </Box>
    </HamburgerWrapper>
  )
}

Hamburger.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Hamburger
