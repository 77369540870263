import React, { FC, ReactNode } from 'react'

import cn from 'classnames'

import classes from './alert.module.scss'
import { Icon } from '../icon/icon'

export type AlertType = 'info' | 'warning' | 'error' | 'success'

interface AlertProps {
  type: AlertType
  title: ReactNode
  children: ReactNode
  action?: ReactNode
  onClose?: () => void
  className?: string
  dataTestId?: string
}

export const Alert: FC<AlertProps> = ({
  type,
  title,
  children,
  action,
  onClose,
  className,
  dataTestId = 'alert',
}) => (
  <div
    className={cn(classes.root, classes[type], className)}
    data-testid={dataTestId}
  >
    <div className={classes.header}>
      <Icon
        name={`alert-${type}`}
        className={cn(classes.icon)}
        alt="alert icon"
      />
      <p className={cn(classes.title, classes[type])}>{title}</p>
      {onClose && (
        <Icon name="close-alert" className={classes.close} onClick={onClose} />
      )}
    </div>
    <div className={cn(classes.body, { [classes.noMargin]: !action })}>
      {children}
    </div>
    {action && <div className={classes.action}>{action}</div>}
  </div>
)
