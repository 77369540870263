import React, { FC } from 'react'

import { getYouMightBeInterestedPageSection } from '@smarty-nx/contentful'
import cn from 'classnames'

import { Tile } from './_parts'
import classes from './you-might-be-interested.module.scss'

const section = getYouMightBeInterestedPageSection()

export const YouMightBeInterestedSection: FC = () => {
  if (!section) {
    return null
  }

  return (
    <div data-module-name="might_be_interested" className={classes.root}>
      <div className={cn('redesign-container', classes.container)}>
        <div className={classes.title}>{section.title}</div>

        <div className={classes.tiles}>
          {section.items.map((item) => (
            <Tile key={item.type} {...item} />
          ))}
        </div>
      </div>
    </div>
  )
}
