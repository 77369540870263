import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

import dsTheme from '../../constants/design-system/theme.json'

const { typography } = dsTheme

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Heading = styled.h2`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`

const TopicList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    &:last-child a {
      margin-bottom: 0;
    }
  }
  @media (min-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const TopicLink = styled.a`
  ${typography['Kollektif/SmallEmphasis']};
  background-color: ${colors['Brand/PaleBlue']};
  border-radius: 3px;
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 0.725rem;
  text-decoration: none;
  white-space: nowrap;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    margin-bottom: 1.5rem;
  }
`

const Img = styled.img`
  width: 48px;
  align-self: flex-start;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    width: 164px;
    align-self: center;
  }
`

export { Wrapper, Heading, TopicList, TopicLink, Img }
