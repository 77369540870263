import React, { FC, useState } from 'react'

import { Helmet } from 'react-helmet'
import SmartBanner from 'react-smartbanner'

import 'react-smartbanner/dist/main.css'
import './app-banner.scss'

export const AppBanner: FC = () => {
  const [showBanner, setShowBanner] = useState(false)

  return (
    <>
      <Helmet
        onChangeClientState={() => {
          setShowBanner(true)
        }}
      >
        <meta name="apple-itunes-app" content="app-id=1540812668" />
        <meta name="google-play-app" content="app-id=com.hutchison3g.sundae" />
      </Helmet>
      {showBanner ? (
        <SmartBanner
          title={'SMARTY'}
          url={{
            ios: 'https://smartymobile.page.link/download',
            android: 'https://smartymobile.page.link/download',
          }}
        />
      ) : null}
    </>
  )
}
