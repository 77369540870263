import React from 'react'

import PropTypes from 'prop-types'

import {
  Icon,
  LargeIcon,
  Step,
  StepHeading,
  StepsContainer,
  Timeline,
  Title,
  WhyCard,
  WhyCardTitle,
} from './styles'
import IconDelivery from '../../static/images/simVertical.svg'
import IconActivate from '../../static/images/timeline-calendar.svg'
import IconFree from '../../static/images/timeline-free.svg'
import IconTransfer from '../../static/images/timeline-transfer.svg'

export const HowItWorksTimeline = ({ plan }) => (
  <>
    <Title>How it works</Title>
    <Timeline>
      <Step>
        <StepHeading>
          <Icon src={IconDelivery} alt="" />
          <span>Your SIM arrives in 2-5 days</span>
        </StepHeading>
      </Step>

      <Step>
        <StepHeading>
          <Icon src={IconActivate} alt="" />
          <span>Activate your SIM in minutes</span>
        </StepHeading>
      </Step>

      {plan && !plan.plan_is_data_only && (
        <Step>
          <StepHeading>
            <Icon src={IconTransfer} alt="" />
            <span>Transfer your number</span>
          </StepHeading>
        </Step>
      )}

      <Step>
        <StepHeading>
          <Icon src={IconFree} alt="" />
          <span>Bag up to a £20 gift card for referrals</span>
        </StepHeading>
      </Step>
    </Timeline>
  </>
)

export const HowItWorksSteps = ({ plan }) => (
  <>
    <Title>How it works</Title>
    <StepsContainer>
      <WhyCard>
        <LargeIcon src={IconDelivery} alt="" />
        <WhyCardTitle>Your SIM arrives in 2-5 days</WhyCardTitle>
      </WhyCard>
      <WhyCard>
        <LargeIcon src={IconActivate} alt="" />
        <WhyCardTitle>Activate your SIM in minutes</WhyCardTitle>
      </WhyCard>
      {plan && !plan.plan_is_data_only && (
        <WhyCard>
          <LargeIcon src={IconTransfer} alt="" />
          <WhyCardTitle>Transfer your number</WhyCardTitle>
        </WhyCard>
      )}
      <WhyCard>
        <LargeIcon src={IconFree} alt="" />
        <WhyCardTitle>Bag up to a £20 gift card for referrals</WhyCardTitle>
      </WhyCard>
    </StepsContainer>
  </>
)

HowItWorksSteps.propTypes = {
  plan: PropTypes.object,
}

HowItWorksTimeline.propTypes = {
  plan: PropTypes.object,
}
