import React, { FC } from 'react'

import cn from 'classnames'

import classes from './tag.module.scss'
import { Icon } from '..'

interface TagProps {
  icon: string
  name: string
  className?: string
  'data-testid'?: string
}

export const Tag: FC<TagProps> = ({ className, icon, name, ...restProps }) => (
  <div className={classes.root} {...restProps}>
    <Icon name={icon} className={cn(classes.icon, className)} />
    {name}
  </div>
)
