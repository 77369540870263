import React from 'react'

import { calculatePriceWithVAT } from '@smarty-nx/code-utils'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import PageHead from '../../components/PageHead'
import {
  BreadCrumbs,
  Card,
  Container,
  Desktop,
  Heading,
  Mobile,
  Paragraph,
  Table,
  TdPricing,
  TopFlag,
} from '../../components/styles/Roaming'
import SiteContainer from '../../components/styles/SiteContainer'
import withApp from '../../components/withApp/withApp'
import { CONFIG } from '../../config'

function Country({
  pageContext: {
    display_country,
    international_calls,
    international_sms,
    international_mms,
    international_video_mms,
    iso_country_code,
  },
}) {
  return (
    <SiteContainer>
      <BreadCrumbs>
        <Desktop>
          <Link to="/calling-abroad">Roaming & International</Link>
          <span style={{ padding: '0 10px' }}>{'>'}</span>
          <Link to="/calling-abroad/countries">Countries</Link>
          <span style={{ padding: '0 10px' }}>{'>'}</span>
          {display_country}
        </Desktop>
        <Mobile>
          {'< '}
          <Link
            to="/calling-abroad/countries"
            data-testid="select-another-country-button"
          >
            Select another country
          </Link>
        </Mobile>
      </BreadCrumbs>
      <TopFlag
        src={`/assets/images/flags/${iso_country_code.toLowerCase()}.png`}
        alt={iso_country_code}
        data-testid={`${iso_country_code}-flag`}
      />
      <Heading data-testid="country-prices-title">
        Using SMARTY to call {display_country}
      </Heading>
      <Container bgColor="#82c6ff" padding="15px">
        <Table borderColor="#79B7EB" data-testid="country-prices-table">
          <thead>
            <tr>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-testid="calls-to-mobiles-text">Calls to Mobiles</td>
              <TdPricing data-testid="calls-to-mobiles-price">
                {calculatePriceWithVAT(international_calls)}
                <span>/ min</span>
              </TdPricing>
            </tr>

            <tr>
              <td data-testid="calls-to-landlines-text">Calls to Landlines</td>
              <TdPricing data-testid="calls-to-landlines-price">
                {calculatePriceWithVAT(international_calls)}
                <span>/ min</span>
              </TdPricing>
            </tr>

            <tr>
              <td data-testid="calls-to-other-text">Calls to Other</td>
              <TdPricing data-testid="calls-to-other-price">
                {calculatePriceWithVAT(international_calls)}
                <span>/ min</span>
              </TdPricing>
            </tr>

            <tr>
              <td data-testid="sms-text">Texts</td>
              <TdPricing data-testid="sms-price">
                {calculatePriceWithVAT(international_sms)}
                <span>/ sms</span>
              </TdPricing>
            </tr>

            <tr>
              <td data-testid="picture-message-text">Picture message (MMS)</td>
              <TdPricing data-testid="picture-message-price">
                {calculatePriceWithVAT(international_mms)}
                <span>/ mms</span>
              </TdPricing>
            </tr>

            <tr>
              <td data-testid="video-message-text">Video message (MMS)</td>
              <TdPricing data-testid="video-message-price">
                {calculatePriceWithVAT(international_video_mms)}
                <span>/ mms</span>
              </TdPricing>
            </tr>

            <tr>
              <td>Other</td>
              <TdPricing>
                <span>
                  <a
                    href="/priceguide"
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label="See all prices .PDF (253KB) link opens in a new window"
                  >
                    See all prices
                  </a>
                </span>
              </TdPricing>
            </tr>
          </tbody>
        </Table>
        <Card headingColor="#82c6ff">
          <h2 className="card-heading">Before you call or text</h2>
          <h2>Buy “Out of plan” add-ons</h2>
          <p>
            All international calls and texts are paid for with your “Out of
            plan” add-ons.
          </p>
          <a href={`${CONFIG.DASHBOARD_URL}cash-addons`}>
            Buy “Out of plan” add-ons
          </a>
        </Card>
      </Container>
      <Paragraph>Prices are shown including VAT where applicable.</Paragraph>
    </SiteContainer>
  )
}

Country.propTypes = {
  pageContext: {
    display_country: PropTypes.string,
    iso_country_code: PropTypes.string,
    international_calls: PropTypes.number,
    international_sms: PropTypes.number,
    international_mms: PropTypes.number,
    international_video_mms: PropTypes.number,
  },
}

export default withApp(Country)

export const Head = ({ pageContext }) => (
  <PageHead title={`Using SMARTY to call ${pageContext.display_country}`} />
)

Head.propTypes = {
  pageContext: PropTypes.object,
}
