import React, { ReactNode } from 'react'

import { AppProps } from './withApp'
import { setAffiliateCookiesFromUrl } from '../../utils/affiliates'
import getUrlVoucherCode from '../../utils/getUrlVoucherCode'

class App extends React.Component<AppProps & { children: ReactNode }> {
  componentDidMount() {
    const { voucherCode, setVoucherCode, plans, fetchPlans } = this.props

    const urlVoucher = getUrlVoucherCode(window.location.search)
    if (urlVoucher && urlVoucher !== voucherCode) {
      setVoucherCode(urlVoucher)
    }

    setAffiliateCookiesFromUrl(window.location.search)

    if (plans.plans.length === 0) {
      fetchPlans()
    }
  }

  render() {
    return this.props.children
  }
}

export default App
