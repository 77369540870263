import { configureStore } from '@reduxjs/toolkit'

import { loadState } from './localStorage'
import { rootReducer, RootState } from './rootReducer'

const getStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  })
}

export const store = getStore(loadState())

export type AppDispatch = typeof store.dispatch
