import React, { FC, useEffect } from 'react'

import { colors } from '@smarty-nx/ds'
import { css } from 'styled-components'

import DataOnlySimPlans from '../../../components/DataOnlySimPlans'
import PageHead from '../../../components/PageHead'
import { Header2 } from '../../../components/styles/FontSize'
import FullWidthContainer from '../../../components/styles/FullWidthContainer'
import SiteContainer from '../../../components/styles/SiteContainer'
import Spacer from '../../../components/styles/Spacer'
import TrustPilot from '../../../components/TrustPilot'
import withApp, { AppProps } from '../../../components/withApp/withApp'
import FiftyFifty from '../../../modular-system/FiftyFifty'
import Hero from '../../../modular-system/Hero'
import LogoGarden from '../../../modular-system/LogoGarden'
import GroupSmileyFamily from '../../../static/images/groupSmileyFamily.svg'

const DataOnlySimPlan: FC<AppProps> = (props) => {
  const {
    fetchGroupPlans,
    groupPlans: { plans },
  } = props

  useEffect(() => {
    fetchGroupPlans()
  }, [fetchGroupPlans])
  return (
    <>
      <FullWidthContainer background={colors['UI/DarkGrey']}>
        <SiteContainer>
          <Hero
            heading={dataOnlySimContent.heading}
            subHeading={dataOnlySimContent.subHeading}
            subTitle={dataOnlySimContent.subTitle}
            benefits={dataOnlySimContent.benefits}
            imageUrl="/assets/images/landing-pages/data-sim/tablet-dongle.svg"
            imageAlt={dataOnlySimContent.heroImageAlt}
            hideButton
            subLink={undefined}
            sublinkSuffix={undefined}
            CTA={undefined}
            CTALink={undefined}
            image={undefined}
            custStyle={undefined}
          />
        </SiteContainer>
      </FullWidthContainer>

      <Spacer />
      <SiteContainer
        data-module-id={plans.length > 0 ? 'plan-modules-data-sim' : null}
        data-module_name="simple-plans"
        data-module_placement="products-section"
      >
        <DataOnlySimPlans plans={plans} />
      </SiteContainer>
      <FullWidthContainer background={colors['UI/DarkGrey']}>
        <SiteContainer>
          <FiftyFifty
            imageTextSmall
            inverse
            leftStyles={undefined}
            rightStyles={undefined}
            customProps={undefined}
            custStyle={undefined}
            custStyleProps={undefined}
          >
            <img src={dataOnlySimContent.heroImgSrc} alt="" />
            {dataOnlySimContent.heroContent}
          </FiftyFifty>
        </SiteContainer>

        <SiteContainer>
          {dataOnlySimContent.imageTextSections
            .slice(0)
            .map((fiftyFifty, index) => (
              <FiftyFifty
                key={index}
                index={index}
                imageTextSmall
                inverse={Math.abs(index % 2) === 1}
                leftStyles={undefined}
                rightStyles={undefined}
                customProps={undefined}
                custStyle={undefined}
                custStyleProps={undefined}
              >
                <img src={fiftyFifty.imageSrc} alt="" />
                {fiftyFifty.content}
              </FiftyFifty>
            ))}
        </SiteContainer>
      </FullWidthContainer>

      <FullWidthContainer background={colors['UI/DarkGrey']}>
        <SiteContainer>
          <TrustPilot
            reviewTag="SelectedReview"
            dataTestId="homepage-trustpilot-widget"
          />
        </SiteContainer>
      </FullWidthContainer>
      <SiteContainer>
        <LogoGarden
          css={css`
            margin-left: 0;
            margin-right: 0;
          `}
        />
      </SiteContainer>
    </>
  )
}

const dataOnlySimContent = {
  heading: 'Choose the Data Only plan that’s right for you',
  subHeading:
    'Check out our Data Only plans. No calls and texts, just great value data.',
  subTitle: '',
  benefits: [
    'Easy to manage alongside your main SIM',
    'Save 10% on every SIM in a Group plan',
    'Only pay for data',
  ],
  heroContent: (
    <>
      <Header2>What is a Group?</Header2>
      <p className="margin--regular kollektif-small">
        Group plans are a great way of managing up to 8 SIMs under one account.
        Whether you’re bringing family accounts together or just adding an extra
        SIM for your tablet, save time by paying from one place and get 10% off
        each SIM.
      </p>
    </>
  ),
  heroImgSrc: GroupSmileyFamily,
  heroImageAlt: 'Group smiley family',
  seo: {
    title: 'SMARTY Data Only SIM Deals',
    description: '',
  },

  imageTextSections: [
    {
      imageSrc: '/assets/images/landing-pages/data-sim/coins.svg',
      content: (
        <>
          <Header2>10% off every SIM added</Header2>
          <p className="margin--regular kollektif-small">
            For each additional SIM added we’ll give you 10% off. Manage billing
            for all of them under one account and save yourself time and money.
          </p>
        </>
      ),
    },
  ],
}

export default withApp(DataOnlySimPlan)

export const Head = () => (
  <PageHead
    title={dataOnlySimContent.seo.title}
    description={dataOnlySimContent.seo.description}
  >
    <meta name="robots" content="noindex, nofollow" />
  </PageHead>
)
