import React from 'react'

import { Container, Image } from './styles'
import { CONFIG } from '../../config'
import ExclamationMark from '../../static/images/exclamationMark.svg'
import SiteContainer from '../styles/SiteContainer'

const PageNotFoundMessage = () => (
  <SiteContainer>
    <Container>
      <Image
        src={ExclamationMark}
        alt="Oops, Page not found"
        data-testid="404-warning"
      />
      <h1 data-testid="404-title">Oops, Page not found</h1>
      <p data-testid="404-message">
        The page you are looking for doesn’t exist or has been moved.
      </p>
      <p data-testid="404-signoff">— The SMARTY team.</p>
      <p>
        Go to{' '}
        <a href={CONFIG.MARKETING_URL} data-testid="go-to-website-link">
          smarty.co.uk
        </a>
      </p>
    </Container>
  </SiteContainer>
)

export default PageNotFoundMessage
