import React, { FC } from 'react'

import cn from 'classnames'

import classes from './checkmark.module.scss'
import { Icon } from '..'

declare module 'react' {
  interface CSSProperties {
    '--checkmark-color-primary'?: string
    '--checkmark-color-secondary'?: string
  }
}

interface CheckmarkProps {
  className?: string
  primaryColor?: string
  secondaryColor?: string
}

export const Checkmark: FC<CheckmarkProps> = ({
  className,
  primaryColor,
  secondaryColor,
}) => (
  <Icon
    name="checkmark"
    className={cn(classes.checkmark, className)}
    style={{
      '--checkmark-color-primary': primaryColor,
      '--checkmark-color-secondary': secondaryColor,
    }}
  />
)
