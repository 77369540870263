import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

import dsTheme from '../../constants/design-system/theme.json'

const { typography } = dsTheme

export const Header2 = styled.h2`
  ${typography['Kollektif/Large']};
  margin-bottom: 1rem;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    ${typography['Kollektif/ExtraLarge']};
  }
`
