import React, { FC } from 'react'

import { Banner } from './banner'
import { Coverage } from './coverage'
import { Devices } from './devices'
import { Reboxed } from './reboxed'
import classes from './sections.module.scss'
import SiteContainer from '../../../components/styles/SiteContainer'
import Spacer from '../../../components/styles/Spacer'
import FaqSection from '../../../modular-system/FaqSection'
import { isBanner } from '../../content-type-checks'

const renderSection = (
  section: Queries.GenericBannerFragment | Queries.PageSectionFragment
) => {
  if (isBanner(section) && section.type === 'why-3g-switched-off') {
    return (
      <SiteContainer>
        <Banner {...section} />
      </SiteContainer>
    )
  }

  if (isBanner(section) && section.type === 'how-will-this-impact-me') {
    return (
      <SiteContainer>
        <Banner {...section} ltr />
      </SiteContainer>
    )
  }

  if (isBanner(section) && section.type === 'reliable-network-by-three') {
    return (
      <div className={classes.mediumDarkGreyBlock}>
        <SiteContainer>
          <Coverage {...section} />
        </SiteContainer>
      </div>
    )
  }

  if (isBanner(section) && section.type === 'switching-is-easy') {
    return (
      <div className={classes.primaryBlackBlock}>
        <SiteContainer>
          <Banner {...section} />
        </SiteContainer>
      </div>
    )
  }

  if (!isBanner(section) && section.type === 'devices') {
    return (
      <div className={classes.darkGreyBlock}>
        <SiteContainer>
          <Devices items={section.items as Queries.GenericBannerFragment[]} />
        </SiteContainer>
      </div>
    )
  }

  if (!isBanner(section) && section.type === 'reboxed-checked-phone') {
    return (
      <div className={classes.primaryBlackBlock}>
        <SiteContainer>
          <Reboxed items={section.items as Queries.GenericBannerFragment[]} />
        </SiteContainer>
      </div>
    )
  }

  if (!isBanner(section) && section.type === '3g-switch-off-faq-list') {
    return (
      <div id="faqs" className={classes.darkestGreyBlock}>
        <SiteContainer>
          <Spacer />
          <FaqSection {...section} />
          <Spacer size={2} />
        </SiteContainer>
      </div>
    )
  }
}
export const Sections: FC<{
  items: (Queries.GenericBannerFragment | Queries.PageSectionFragment)[]
}> = ({ items }) => (
  <>
    {items.map((section) => (
      <React.Fragment key={section.title}>
        {renderSection(section)}
      </React.Fragment>
    ))}
  </>
)
