import React, { FC } from 'react'

import { PageProps, HeadProps, graphql } from 'gatsby'

import { pageSectionsMapping } from './page-sections-mapping'
import PageHead from '../../components/PageHead'
import withApp, { AppProps } from '../../components/withApp/withApp'
import { useIsAuthenticated } from '../../hooks'
import { isPageSection, filterByAuthState } from '../content-type-checks'

type PageContext = { path: string }

type PageTemplateProps = AppProps & PageProps<Queries.CmsPageQuery, PageContext>

export const Page: FC<PageTemplateProps> = ({ data: { page } }) => {
  const isAuthenticated = useIsAuthenticated()

  if (!page) {
    // redirect to "/" ?
    return null
  }

  return (
    <div>
      {page.sections
        ?.filter(isPageSection)
        .filter(filterByAuthState(isAuthenticated))
        .map((section, index) => {
          const type =
            section.displayContentAs as keyof typeof pageSectionsMapping

          const Component = pageSectionsMapping[type]

          if (Component) {
            return <Component index={index} key={section.id} {...section} />
          }

          return null
        })}
    </div>
  )
}

export default withApp(Page)

export const Head: FC<HeadProps<Queries.CmsPageQuery, PageContext>> = ({
  data: { page },
}) => {
  return (
    <PageHead
      title={page?.title || ''}
      description={page?.seoDescription?.internal.content || ''}
    />
  )
}

export const query = graphql`
  query CmsPage($url: String) {
    page: contentfulPage(path: { eq: $url }) {
      ...GenericPage
    }
  }
`
