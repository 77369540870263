import React from 'react'

import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

import BubbleHero from '../components/BubbleHero'
import Button from '../components/Button'
import PageHead from '../components/PageHead'
import SiteContainer from '../components/styles/SiteContainer'
import Spacer from '../components/styles/Spacer'
import withApp from '../components/withApp/withApp'
import android from '../static/images/android-phone.svg'
import ios from '../static/images/apple-logo.svg'
import heroImg from '../static/images/roaming-setup.png'
import windows from '../static/images/windows-phone.svg'

const Row = styled.div`
  padding: ${({ padding }) => padding || '0'};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    flex-direction: row;
  }
`

const Card = styled.div`
  flex: 1;
  margin: 15px;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  padding: 1rem;

  img {
    max-height: 72px;
    max-width: 72px;
    width: 100%;
    margin: 4.4rem auto;
  }

  p {
    font-size: 1.125rem;
    font-weight: 400;
    margin: 15px auto;

    &.grey-color {
      color: ${colors['UI/ParagraphGrey']};
    }

    &.type {
      min-height: 50px;
      font-size: 1rem;
    }
  }

  h3 {
    color: ${colors['Brand/PrimaryGreen']};
    font-size: 1.125rem;
    font-weight: 700;
  }
`

const Title = styled.h2`
  font-size: 2rem;
  margin: 30px auto 0;
  text-align: center;
`

const NoneLink = styled.a`
  text-align: center;
  margin: 1rem auto 0;
`

const HeroWrapper = styled.div`
  max-width: 610px;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-size: 2.1rem;
    line-height: 40px;

    @media (min-width: ${breakpoints['desktop-small']}px) {
      font-size: 3.3rem;
      line-height: 3.8rem;
    }
  }

  img {
    max-width: 400px;
    margin: 0 auto;
  }

  p {
    max-width: 550px;
    margin: 20px auto;
  }
`

const RoamingSetup = () => (
  <>
    <BubbleHero>
      <HeroWrapper>
        <img
          src={heroImg}
          alt="Roaming setup."
          data-testid="roaming-setup-image"
        />
        <h1 data-testid="header">
          Set up your phone <br /> before travelling abroad
        </h1>
        <p data-testid="description">
          Your SMARTY SIM will not work abroad unless you make a one-off change
          to your phone settings. We’ve created a step-by-step guide to easily
          set up your phone.
        </p>
      </HeroWrapper>
      <Spacer />
    </BubbleHero>
    <SiteContainer>
      <Title data-testid="which-phone-text">Which phone do you have?</Title>
      <Row padding="20px 0;">
        <Card bgColor={colors['UI/DarkGrey']} data-testid="ios-card">
          <img src={ios} alt="IOS" data-testid="ios-image" />
          <p className="type" data-testid="ios-text">
            If you have an iPhone, iPad...
          </p>
          <Button
            color={colors['Brand/PrimaryGreen']}
            newTab
            href="http://help.smarty.co.uk/set-up-your-phone-to-work-at-home-and-abroad/setting-up-your-ios-device"
            data-testid="ios-button"
          >
            IOS
          </Button>
        </Card>
        <Card bgColor={colors['UI/DarkGrey']} data-testid="android-card">
          <img src={android} alt="Android" data-testid="android-image" />
          <p className="type" data-testid="android-text">
            If you have a Samsung, Huawei, Sony...
          </p>
          <Button
            color={colors['Brand/PrimaryGreen']}
            newTab
            href="http://help.smarty.co.uk/set-up-your-phone-to-work-at-home-and-abroad/setting-up-your-android-device"
            data-testid="android-button"
          >
            Android
          </Button>
        </Card>
        <Card bgColor={colors['UI/DarkGrey']} data-testid="windows-card">
          <img src={windows} alt="Windows" data-testid="windows-image" />
          <p className="type" data-testid="windows-text">
            If you have a Nokia Lumia...
          </p>
          <Button
            color={colors['Brand/PrimaryGreen']}
            newTab
            href="http://help.smarty.co.uk/set-up-your-phone-to-work-at-home-and-abroad/setting-up-your-windows-phone"
            data-testid="windows-button"
          >
            Windows Phone
          </Button>
        </Card>
      </Row>
      <NoneLink
        href="http://help.smarty.co.uk/set-up-your-phone-to-work-at-home-and-abroad/setting-up-your-phone-to-work-abroad"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="none-link"
      >
        None of these phones
      </NoneLink>
      <Row padding="20px 0;">
        <Card>
          <h3 data-testid="why-title">Why do I have to do this?</h3>
          <p className="grey-color" data-testid="why-text">
            If you’re going to travel outside the UK at some point, you’ll need
            to make a one-off change to your phone settings. Otherwise, your
            SMARTY SIM won’t work abroad.
          </p>
        </Card>
        <Card>
          <h3 data-testid="when-title">When do I have to do it?</h3>
          <p className="grey-color" data-testid="when-title">
            We recommend you to do it now, it takes 2 minutes and your phone
            will be ready to travel. You will only need to do it once.
          </p>
        </Card>
      </Row>
    </SiteContainer>
  </>
)

export default withApp(RoamingSetup)

export const Head = () => (
  <PageHead
    title="Compare our best Pay as You Go deals (Unlimited texts and calls)"
    description="SMARTY is the SIM-only mobile network that offers all the flexibility of pay as you go. Our monthly plans start from £6 and come with no minimum-term contracts."
  />
)
