import styled from 'styled-components'

export const ChecklistItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${({ margin }) => margin || '5px 0'};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 500;
  color: ${({ color }) => color};

  img {
    width: 17px;
    height: 17px;
    margin-right: 10px;
  }

  &:last-child {
    margin-bottom: 10px;
  }
`
