import {
  selectUserJwt,
  selectUserJwtExpiry,
  selectUserJwtHardExpiry,
} from '../state/dashboard/dashboard.slice'
import { useAppSelector } from '../state/rootReducer'

export const useIsAuthenticated = (): boolean => {
  const jwt = useAppSelector(selectUserJwt)
  return !!jwt
}

export const useUserJwt = () => useAppSelector(selectUserJwt)

export const useUserJwtExpiry = () => useAppSelector(selectUserJwtExpiry)

export const useUserJwtHardExpiry = () =>
  useAppSelector(selectUserJwtHardExpiry)
