// returns true if enum of strings contains the value
// (value in Enum) does not work with string enums because they are not reverse mapped:
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-4.html#string-enums
// string-initialized enums can’t be reverse-mapped to get the original enum member name
export const isInEnum = (
  value: string | null | undefined,
  enumSrc: { [s: string]: string }
): boolean => {
  if (!value) return false

  return Object.values(enumSrc).includes(value)
}
