/**
 * Formats Price objects returned by the API into strings
 *
 * Price type looks like this:
 * export interface Price {
 *   value: string,
 *   currency: string,
 * }
 *
 * Returns values like '$9.00'. '£8.40', '- £5.00'
 * Optionally round to whole pounds '£9'
 */

import { Price } from '@smarty-nx/data'

interface currenciesObj {
  [index: string]: string
}

export const currencies: currenciesObj = {
  GBP: '£',
  USD: '$',
}

interface Options {
  round?: boolean
  removeZeroPence?: boolean
}

const priceToString = (
  price: Price,
  { round = false, removeZeroPence = false }: Options = {}
): string => {
  const sign: string = currencies[price.currency] || `${price.currency} `
  const minusSign: string = Number(price.value) < 0 ? '- ' : ''
  let amountString: string = Math.abs(Number(price.value)).toFixed(
    round ? 0 : 2
  )
  if (removeZeroPence) {
    amountString = amountString.replace(/\.00$/, '')
  }
  return `${minusSign}${sign}${amountString}`
}

export default priceToString
