import { RootState, useAppSelector } from '../state/rootReducer'
import { matchPlanMeta } from '../utils/plans'
import { PlanData, PlanMeta } from '../utils/plans.types'

interface UsePlansResult {
  isLoading: boolean
  list: {
    priceData: PlanData
    planMeta: PlanMeta
  }[]
}

export const usePlans = (slugs: string[]): UsePlansResult => {
  const { plans, loading: isLoading } = useAppSelector(
    (state: RootState) => state.plans
  )

  if (isLoading || plans.length === 0) {
    return {
      isLoading: true,
      list: [],
    }
  }

  const list = slugs.map((slug) => {
    const { priceData, planMeta } = matchPlanMeta(plans, slug)
    return { priceData, planMeta }
  })

  return {
    isLoading: false,
    list,
  }
}
