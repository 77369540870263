import React, { FC, ReactNode } from 'react'

import cn from 'classnames'

import classes from './heading.module.scss'

interface HeadingProps {
  title: string
  className: string

  subtitle?: string
  info?: ReactNode
}

export const Heading: FC<HeadingProps> = ({
  className,
  title,
  subtitle,
  info,
}) => {
  return (
    <div className={cn(classes.root, className)}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subtitle}>{subtitle}</div>
      <div className={classes.info}>{info}</div>
    </div>
  )
}
