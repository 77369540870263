import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

import dsTheme from '../../constants/design-system/theme.json'

const { typography } = dsTheme

export const SiteContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: ${({ theme }) => theme.sizes.siteBaseWidth};
  padding: 0 1.5rem;
  align-self: center;
  box-sizing: border-box;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    margin-top: ${({ bannerSpacing }) => (bannerSpacing ? '80px' : 'auto')};
  }

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 0;
    flex-direction: ${(props) => props.ImageProps && 'row'};
  }
  li {
    ${typography['Kollektif/Medium']};
  }
  ul {
    padding-left: 1.5rem;
  }
`

export default SiteContainer
