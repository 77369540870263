import { Link } from 'gatsby'
import styled from 'styled-components'

export const TextLinkWrapper = styled.a<{
  to?: string
  as: string | typeof Link
  href?: string
  target?: string
  rel?: string
}>`
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`
