import React from 'react'

import { helpTopicsContent } from './HelpTopics.strings'
import {
  Heading,
  Img,
  TopicLink,
  TopicList,
  Wrapper,
} from './HelpTopics.styles'
import { TRACKING_CATEGORIES } from '../../constants/globalStrings'
import Bubble from '../../static/images/bubble.svg'
import { HideOnMobile, HideTabletUpwards } from '../styles/Responsive'

const HelpTopics = () => (
  <Wrapper>
    <div>
      <Heading data-testid="help-topics-title">
        <span>{helpTopicsContent.title}</span>
        <HideTabletUpwards>
          <Img src={Bubble} alt="" data-testid="help-topics-image-mobile" />
        </HideTabletUpwards>
      </Heading>
      <TopicList>
        {helpTopicsContent.topics.map((topic, index) => (
          <li key={index} data-testid="all-topics">
            <TopicLink
              // data-item-category / data-item-name for tracking clicks.
              // data-testid for automated tests
              data-item-category={TRACKING_CATEGORIES.NAVIGATION}
              data-item-name={topic.data_item_name}
              data-testid={`${topic.data_item_name}-topic`}
              href={topic.url}
            >
              {topic.title}
            </TopicLink>
          </li>
        ))}
      </TopicList>
    </div>
    <HideOnMobile>
      <Img src={Bubble} alt="" data-testid="help-topics-image-desktop" />
    </HideOnMobile>
  </Wrapper>
)

export default HelpTopics
