import React, {
  ButtonHTMLAttributes,
  ComponentType,
  FC,
  ReactNode,
} from 'react'

import cn from 'classnames'

import classes from './button.module.scss'

/***
 * Design system: https://www.figma.com/file/JRJ2xtZ27FkTiR6GEvt0yuDd/%E2%9D%96-SMARTY-Design-System?type=design&node-id=9654-45956&t=Omj58skJauXiNxZd-0
 */

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  level?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'secondaryLight'
    | 'link'
  size?: 'regular' | 'tiny'
  as?: ComponentType<{
    children?: ReactNode
    className: string
    [key: string]: unknown
  }>
}

export const Button: FC<ButtonProps> = ({
  className,
  level = 'primary',
  size = 'regular',
  as: Tag = 'button',
  ...props
}) => {
  return (
    <Tag
      className={cn(classes.root, classes[level], classes[size], className)}
      {...props}
    />
  )
}
