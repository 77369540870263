import React from 'react'

import PropTypes from 'prop-types'

import { Root } from './Card.styles'

const Card = ({ children, ...props }) => <Root {...props}>{children}</Root>

Card.propTypes = {
  children: PropTypes.node,
}

export default Card
