import React, { ComponentType, ReactNode } from 'react'

import { isFullUrl } from '@smarty-nx/code-utils'
import { GatsbyLinkProps } from 'gatsby-link'
import { Link as ReactRouterDomLink } from 'react-router-dom'

interface LinkProps {
  href?: string
  children: ReactNode
  className?: string
  props:
    | Omit<Omit<GatsbyLinkProps<{ to: string }>, 'ref'>, 'to'>
    | Omit<React.HTMLProps<HTMLElement>, 'ref'>
  component:
    | ComponentType<GatsbyLinkProps<{ to: string }>>
    | typeof ReactRouterDomLink
}

export const Link = ({
  href,
  children,
  props,
  className,
  component,
}: LinkProps) => {
  if (!href) return null
  if (isFullUrl(href))
    return (
      <a className={className} href={href} {...props}>
        {children}
      </a>
    )

  const LinkComponent = component

  return (
    <LinkComponent className={className} to={href} {...props}>
      {children}
    </LinkComponent>
  )
}
