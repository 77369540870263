import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

import FullWidthContainer from '../../components/styles/FullWidthContainer'

export const Header = styled.h2`
  font-size: 34px;
  line-height: 38px;
  margin-bottom: 1.5rem;
  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 46px;
    line-height: 54px;
  }
`

export const Icon = styled.img`
  max-width: 80px;
  @media (min-width: ${breakpoints.tablet}px) {
    max-width: 170px;
  }
`

export const Paragraph = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  span.restrictions {
    color: ${colors['Brand/Yellow']};
  }
  span.caps {
    color: ${colors['Brand/PrimaryGreen']};
  }
`

export const StyledFullWidthContainer = styled(FullWidthContainer)`
  padding: 1.5rem 0;
  @media (min-width: ${breakpoints.tablet}px) {
    padding: 3rem;
  }
`
