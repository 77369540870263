import React, { useState } from 'react'

import { SimpleModal } from './Modal'
import { ToolTipText } from './Modals.styles'
import TooltipIcon from '../TooltipIcon'

export interface ModalProps {
  children: React.ReactNode
  dataTestId?: string
  header?: string
  hasImage?: boolean
  tooltipComponent?: React.ReactNode
  ariaHideApp?: boolean
}

export const TooltipModal = ({
  children,
  dataTestId,
  header,
  hasImage = true,
  tooltipComponent,
  ariaHideApp,
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const modalComponent = (
    <SimpleModal
      ariaHideApp={ariaHideApp}
      header={header}
      isOpen={isOpen}
      toggleModal={toggleModal}
      dataTestId={dataTestId}
    >
      {children}
    </SimpleModal>
  )

  if (!hasImage) {
    return (
      <>
        <ToolTipText onClick={toggleModal} role="button">
          {tooltipComponent}
        </ToolTipText>
        {modalComponent}
      </>
    )
  }

  /**
   * TooltipIcon is a simple button with an image inside (and aria label for screenreaders)
   * Clicking the tooltip icon opens the modal
   */
  return (
    <TooltipIcon callBack={() => toggleModal()}>{modalComponent}</TooltipIcon>
  )
}
