import React from 'react'

import PropTypes from 'prop-types'

import {
  DefaultImage,
  HuggerPadding,
  ImageLarge,
  ImageSmall,
  LogoLink,
  Wrapper,
} from './LogoGarden.styles'
import { IMAGES_PATH, LOGO_GARDEN_TYPES } from '../../constants/globalStrings'
import uSwitch2020 from '../../static/images/awards/uswitchBestValue2020.png'
import uSwitch2021 from '../../static/images/awards/uswitchBestValue2021.svg'
import which2021 from '../../static/images/awards/Which-April2021.png'
import Hugger from '../Hugger'

const Default = ({ ...props }) => (
  <Hugger {...props} css={HuggerPadding}>
    <LogoLink
      href="https://www.uswitch.com/telecoms-awards"
      rel="noopener noreferrer nofollow"
      target="_blank"
      data-testid="uswitch-award-value-2021-link"
      showOnMobile
    >
      <ImageSmall
        alt="uSwitch Award - Best PAYG Network Winner 2021"
        src={uSwitch2021}
        showOnMobile
        loading="lazy"
        data-testid="uswitch-award-value-2021-image"
      />
    </LogoLink>
    <LogoLink
      href="https://www.uswitch.com/telecoms-awards"
      rel="noopener noreferrer nofollow"
      target="_blank"
      data-testid="uswitch-award-value-2020-link"
    >
      <ImageSmall
        alt="uSwitch Award - Best Value SIM Only Winner 2020"
        src={uSwitch2020}
        loading="lazy"
        data-testid="uswitch-award-value-2020-image"
      />
    </LogoLink>
    <ImageLarge
      alt="uSwitch Award - Best Value SIM Only 2019"
      src={`${IMAGES_PATH}/awards/uswitch-mobile-awards-best-value-sim-only.png`}
      loading="lazy"
      data-testid="uswitch-award-value-2019-image"
    />
    <ImageLarge
      alt="uSwitch Award - Best PAYG Network 2019"
      src={`${IMAGES_PATH}/awards/uswitch-mobile-award-best-payg-network.png`}
      loading="lazy"
      data-testid="uswitch-award-payg-2019-image"
    />
  </Hugger>
)

const Which = () => (
  <Wrapper>
    <a
      href="https://www.which.co.uk/reviews/mobile-phone-providers/article/best-mobile-networks-overview-amhDx1F0z41t"
      rel="noopener noreferrer"
      target="_blank"
    >
      <DefaultImage
        alt="Which Recommended Provider 2021"
        src={which2021}
        srcset={`${which2021} 2x`}
        showOnMobile
        loading="lazy"
        data-testid="which-award-value-2021-image"
      />
    </a>
    <LogoLink
      href="https://www.uswitch.com/telecoms-awards"
      rel="noopener noreferrer nofollow"
      target="_blank"
      data-testid="uswitch-award-value-2021-link"
    >
      <DefaultImage
        alt="uSwitch Award - Best PAYG Network Winner 2021"
        src={uSwitch2021}
        showOnMobile
        loading="lazy"
        data-testid="uswitch-award-value-2021-image"
      />
    </LogoLink>
    <LogoLink
      href="https://www.uswitch.com/telecoms-awards"
      rel="noopener noreferrer nofollow"
      target="_blank"
      data-testid="uswitch-award-value-2020-link"
    >
      <DefaultImage
        alt="uSwitch Award - Best Value SIM Only Winner 2020"
        src={uSwitch2020}
        loading="lazy"
        data-testid="uswitch-award-value-2020-image"
      />
    </LogoLink>
  </Wrapper>
)

const LogoGarden = ({ type = LOGO_GARDEN_TYPES.DEFAULT, ...props }) => {
  switch (type) {
    case LOGO_GARDEN_TYPES.WHICH:
      return <Which {...props} />
    default:
      return <Default {...props} />
  }
}

LogoGarden.propTypes = {
  type: PropTypes.string,
}

export default LogoGarden
