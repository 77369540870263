import { ReactNode } from 'react'

export enum Level {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Quaternary = 'quaternary',
  Quinary = 'quinary',
  Link = 'link',
  UnstyledLink = 'unstyled-link',
}

export interface ButtonProps {
  children: ReactNode
  dataTestId?: string
  disabled?: boolean
  id?: string
  level: Level
  onClick?: () => void
  to?: string
}
