import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Plan, PlanV1 } from '@smarty-nx/data'

import { CONFIG, FEATURES } from '../../config'
import api from '../../utils/api'
import { RootState } from '../rootReducer'

interface PlansState {
  loading: boolean
  plans: PlanV1[] | Plan[]
}

const initialState: PlansState = {
  loading: false,
  plans: [],
}

export const fetchGroupPlans = createAsyncThunk<
  PlanV1[],
  void,
  { state: RootState }
>('group-plans', async (_, { getState }) => {
  const state = getState()

  const isAuthenticated = !!state.dashboard.data?.auth.jwt
  const code = isAuthenticated ? CONFIG.GROUP_PLANS_VOUCHER_CODE : ''

  //@TODO: When removing v1 plans - remove this "any" type
  let plansData: any

  if (FEATURES.Q4_WINTER_SALE) {
    const { data } = await api.get<{
      data: {
        attributes: {
          plans: Plan[]
        }
      }
    }>(`/v3/plans?group_discount=${isAuthenticated}`)

    plansData = data.data.attributes.plans
  } else {
    const { data } = await api.get<{ data: PlanV1[] }>(`/v1/plans?code=${code}`)
    plansData = data.data
  }

  return plansData
})

const groupPlansSlice = createSlice({
  name: 'group-plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGroupPlans.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchGroupPlans.fulfilled, (state, action) => {
      state.loading = false
      state.plans = action.payload
    })

    builder.addCase(fetchGroupPlans.rejected, (state) => {
      state.loading = false
    })
  },
})

export default groupPlansSlice.reducer
