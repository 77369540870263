import React, { FC, useCallback } from 'react'

import { useRefreshToken } from '@smarty-nx/code-utils'
import { getOverlaysBy } from '@smarty-nx/contentful'
import { Defs } from '@smarty-nx/ds'
import { PageProps } from 'gatsby'
import styled from 'styled-components'

import { CONFIG, FEATURES } from '../../config'
import GlobalStyle from '../../constants/globalStyle'
import { useUserJwt, useUserJwtExpiry, useUserJwtHardExpiry } from '../../hooks'
import { ExitOverlay } from '../../modules/components'
import { fetchRefreshToken } from '../../state/dashboard/dashboard.slice'
import { useAppDispatch } from '../../state/rootReducer'
import Footer from '../Footer'
import Navbar from '../Navbar'

import './redesign.scss'

const SkipLink = styled.a`
  padding: 6px 15px;
  position: absolute;
  top: -40px;
  left: 0;
  color: white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  background: #424449;
  z-index: 9999;

  &:focus {
    position: absolute;
    left: 0;
    top: 0;
    outline-color: transparent;
  }
`

const fullScreenPaths = ['/coverage-checker-app']

interface LayoutProps extends PageProps {
  children: any // what is correct type here?
}

const getDashboardIframe = () =>
  CONFIG.SMARTY_ENV === 'localhost' && FEATURES.REFRESH_TOKEN ? (
    <iframe
      title="dashboard"
      src={CONFIG.DASHBOARD_URL}
      id="dashboard"
      style={{ display: 'none' }}
    />
  ) : null

export const Layout: FC<LayoutProps> = ({ children, path }) => {
  const dispatch = useAppDispatch()

  useRefreshToken({
    expiry: useUserJwtExpiry(),
    hardExpiry: useUserJwtHardExpiry(),
    jwt: useUserJwt(),
    refreshToken: useCallback(() => dispatch(fetchRefreshToken()), [dispatch]),
    timeoutMargin: CONFIG.REFRESH_TOKEN_TIMER_MARGIN as number,
    enabled: FEATURES.REFRESH_TOKEN,
  })

  // Conditionally don't show top and bottom nav
  if (fullScreenPaths.includes(path)) {
    return (
      <>
        <Defs />
        <GlobalStyle />
        <main id="maincontent">{children}</main>
      </>
    )
  }

  return (
    <>
      <Defs />
      <GlobalStyle />
      {getDashboardIframe()}
      {getOverlaysBy(path).map((overlay, index) => (
        <ExitOverlay key={index} content={overlay} />
      ))}
      <SkipLink href="#maincontent">Skip to main content</SkipLink>
      <Navbar />
      <main id="maincontent">{children}</main>

      <Footer />
    </>
  )
}
