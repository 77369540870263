import React, { FC } from 'react'

import { ContentfulProcessor } from '@smarty-nx/ds'

export const Tile: FC<Queries.GenericBannerFragment> = ({
  link,
  description,
  title,
  image,
}) => {
  const href = link?.internal.content
  const alt = description?.internal.content || image?.description || title

  if (!href) {
    return <img alt={alt} title={alt} src={image?.url || ''} />
  }

  return (
    <ContentfulProcessor
      content={href}
      components={{
        a({ node: _, ...props }) {
          return (
            <a {...props}>
              <img alt={alt} title={alt} src={image?.url || ''} />
            </a>
          )
        },
      }}
    />
  )
}
