import React, { FC } from 'react'

import { breakpoints } from '@smarty-nx/ds'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledSVG = styled.svg<{ isTopCutout: boolean }>`
  z-index: 1;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;

  @media (min-width: ${breakpoints.desktop}px) {
    bottom: -6px;
  }

  // IE 10 and up
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: none;
  }

  ${({ isTopCutout }) =>
    isTopCutout &&
    `
    top: -1px;
    bottom: auto;
  `}
`

const EllipseCutOut: FC<{
  color?: string
  isTopCutout?: boolean
  className?: string
}> = ({ color, isTopCutout, className }) => (
  <StyledSVG
    viewBox="0 0 1240 206"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    isTopCutout={!!isTopCutout}
    className={className}
  >
    {isTopCutout ? (
      <path
        d="M1240 6.26279e-05C1240 6.26279e-05 1240 319.019 1240 162.019C1080.81 66.8052 861.78 8.00008 620.001 8.00005C378.222 8.00003 159.191 66.8051 0.000919325 162.019C0.00105274 294.019 0.00105556 -4.57764e-05 0.00105556 -4.57764e-05L1240 6.26279e-05Z"
        fill={color}
      />
    ) : (
      <path
        d="M0 205.463V43.444c159.19 95.214 378.221 154.019 620 154.019s460.81-58.805 620-154.019v162.019H0z"
        fill={color}
      />
    )}
  </StyledSVG>
)

EllipseCutOut.propTypes = {
  color: PropTypes.string,
  isTopCutout: PropTypes.bool,
}

export default EllipseCutOut
