import React, { FC, ReactNode, useEffect } from 'react'

import { priceToString } from '@smarty-nx/code-utils'
import {
  getThisPlanIncludesGroup,
  isUnlimitedPlan,
} from '@smarty-nx/contentful'
import { BulletPointItem, Button, Icon } from '@smarty-nx/ds'
import cn from 'classnames'

import { Bubbles } from './bubbles'
import classes from './details.module.scss'
import { triggerProductViewEvent } from '../../../../../utils/dataLayer'
import { buildAssetsProductsProps } from '../../../../../utils/dmpgAttributes'
import { PlanData, PlanMeta } from '../../../../../utils/plans.types'

declare module 'react' {
  interface CSSProperties {
    '--background-color'?: string
  }
}

type Plan = {
  priceData: PlanData
  planMeta: PlanMeta
}

interface DetailsProps {
  onNext(): void
  onPrev(): void
  onClose(): void
  plan: Plan
  className: string
  isMobile: boolean
  renderBuyButton(): ReactNode
}

const mapping = {
  'data-only': 'Data only',
  classic: 'Voice',
  'data-discount': 'Data discount',
}

const getTitle = (plan: Plan) => {
  const tcText =
    plan.priceData.voucher?.plan_card_heading || plan.priceData.tcText

  if (tcText) {
    return <span>{tcText}</span>
  }

  const isUnlimited = isUnlimitedPlan(plan.planMeta.id)

  return (
    <span>
      {mapping[plan.priceData.categoryType]}&nbsp;
      {isUnlimited ? (
        'Unlimited'
      ) : (
        <>
          {plan.planMeta.data_number}
          <span className={classes.gb}>GB</span>
        </>
      )}
      &nbsp;|&nbsp;
      {priceToString(plan.priceData.finalPriceWithoutCredit, {
        removeZeroPence: true,
      })}
    </span>
  )
}

const Includes: FC<{ planMeta: PlanMeta }> = ({ planMeta }) => {
  const content = getThisPlanIncludesGroup(planMeta.slug)
  const items = content?.planIncludes || []
  const count = Math.ceil(items.length / 2)
  const left = items.slice(0, count)
  const right = items.slice(count)

  return (
    <div
      className={classes.bullets}
      style={{
        '--checkmark-color-primary': planMeta.colorLight,
        '--checkmark-color-secondary': planMeta.color,
      }}
    >
      {[left, right].map((column, i) => (
        <div className={classes.column} key={i}>
          {column.map((item) => (
            <BulletPointItem
              key={item.title}
              text={item.title}
              subText={item.subText}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

export const Details: FC<DetailsProps> = ({
  plan,
  onPrev,
  onNext,
  onClose,
  className,
  isMobile,
  renderBuyButton,
}) => {
  useEffect(() => {
    triggerProductViewEvent(buildAssetsProductsProps(plan).products[0])
  }, [plan])

  const hasVoucher = !!plan.priceData.voucher
  const hasBubbles =
    hasVoucher || !!plan.priceData.tcChip || !!plan.priceData.tcText

  return (
    <div className={cn(classes.root, className)}>
      <div
        data-testid={`plan-details-title`}
        className={classes.title}
        style={{
          '--background-color': plan.planMeta.color,
        }}
      >
        {hasBubbles && <Bubbles colorLight={plan.planMeta.colorLight} />}

        <Icon
          name="chevron"
          className={cn(classes.arrow, classes.leftArrow)}
          onClick={onPrev}
          data-testid="plan-details-go-prev-button"
        />

        {getTitle(plan)}

        <Icon
          name="chevron"
          className={cn(classes.arrow, classes.rightArrow)}
          onClick={onNext}
          data-testid="plan-details-go-next-button"
        />
      </div>

      <div className={classes.body}>
        {hasVoucher && plan.priceData.voucher?.roundel_type !== 'Blank' && (
          <div className={classes.chipContainer}>
            <div className={classes.chip}>
              {plan.priceData.voucher?.roundel_type}
            </div>
          </div>
        )}
        <Includes planMeta={plan.planMeta} />

        {(hasVoucher || !isMobile) && (
          <div className={classes.cta}>
            {!isMobile && renderBuyButton()}

            {hasVoucher && (
              <Button
                level="secondary"
                as={(props) => (
                  <a
                    {...props}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={plan.priceData.voucher?.terms_and_conditions_url}
                  >
                    Full offer T&Cs
                  </a>
                )}
              />
            )}
          </div>
        )}

        <Button
          onClick={onClose}
          level="tertiary"
          data-testid="plan-details-close-button"
        >
          Close
        </Button>
      </div>
    </div>
  )
}
