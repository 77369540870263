import { colors } from '@smarty-nx/ds'

const plans = {
  '500mb-data': {
    id: 'SM_MINI',
    name: '500MB',
    slug: '500mb-data',
    data_discount: null,
    data: '500MB',
    data_number: 0.5,
    circle_size: '92px',
    color: colors['Brand/Coral'],
    colorLight: colors['Brand/PaleCoral'],
    ctaTextColour: colors['UI/DarkGrey'],
    title: '500MB data SIM plan for only £5 p/m - (Unlimited calls & texts)',
    usesYellowAssets: true,
    description:
      'SIM-only phone contracts £5 per month. No credit checks and no contract. Best PAYG SIM winner (2019)',
  },
  '1gb-data': {
    id: 'SM_EXTRASMALL',
    name: '1GB',
    slug: '1gb-data',
    data_discount: '£1',
    data: '1GB',
    data_number: 1,
    circle_size: '92px',
    color: colors['Brand/Pink'],
    colorLight: colors['Brand/PalePink'],
    title: '1GB data SIM plan for only £6 p/m - (Unlimited calls & texts)',
    usesYellowAssets: false,
    description:
      'SIM-only phone contracts £6 per month. No credit checks and discounts on unused data. Best PAYG SIM winner (2019)',
  },
  '2gb-data': {
    id: 'SM_SMALL',
    name: '2GB',
    slug: '2gb-data',
    data_discount: '£2',
    data: '2GB',
    data_number: 2,
    circle_size: '110px',
    color: colors['Brand/Blue'],
    colorLight: colors['Brand/PaleBlue'],
    title: '2GB data SIM plan for only £7 p/m - (Unlimited calls & texts)',
    usesYellowAssets: false,
    description:
      'SIM-only phone contracts £7 per month. (Unlimited calls & texts) No credit checks - Best PAYG SIM winner (2019)',
  },
  '2gb-data-only': {
    id: 'SM_2GB_DATAONLY',
    name: '2GB_DATAONLY',
    slug: '2gb-data-only',
    data_discount: null,
    plan_is_data_only: true,
    data: '2GB',
    data_number: 2,
    circle_size: '110px',
    color: colors['Brand/Wave'],
    colorLight: colors['Brand/PaleWave'],
    title: '2GB data only SIM plan',
    usesYellowAssets: false,
    description: '2GB Data',
  },
  '2gb-data-ndd': {
    id: 'SM_2GB_NDD',
    name: '2GB',
    slug: '2gb-data-ndd',
    data_discount: null,
    data: '2GB',
    data_number: 2,
    circle_size: '110px',
    color: colors['Brand/Coral'],
    colorLight: colors['Brand/PaleCoral'],
    ctaTextColour: colors['UI/DarkGrey'],
    title: '2GB data SIM plan for only £5 p/m - (Unlimited calls & texts)',
    usesYellowAssets: true,
    description:
      'SIM-only phone contracts £5 per month. (Unlimited calls & texts) No credit checks - Best PAYG SIM winner (2019)',
  },
  '3gb-data': {
    id: 'SM_3GB',
    name: '3GB',
    slug: '3gb-data',
    data_discount: '£3',
    data: '3GB',
    data_number: 3,
    circle_size: '142px',
    color: colors['Brand/Peach'],
    colorLight: colors['Brand/PalePeach'],
    title: '3GB data SIM plan for only £8 p/m - (Unlimited calls & texts)',
    usesYellowAssets: false,
    description:
      'SIM-only phone contracts £8 per month. No credit checks and discounts on unused data. Best PAYG SIM winner (2019)',
  },
  '4gb-data': {
    id: 'SM_4GB_NDD',
    name: '4GB',
    slug: '4gb-data',
    data_discount: null,
    data: '4GB',
    data_number: 4,
    circle_size: '142px',
    color: colors['Brand/Yellow'],
    colorLight: colors['Brand/PaleYellow'],
    title: '4GB data SIM plan for only £6 p/m - (Unlimited calls & texts)',
    usesYellowAssets: false,
    description:
      'SIM-only phone contracts £6 per month. No credit checks and discounts on unused data. Best PAYG SIM winner (2019)',
  },
  '8gb-data': {
    id: 'SM_8GB_NDD',
    name: '8GB',
    slug: '8gb-data',
    data_discount: null,
    data: '8GB',
    data_number: 8,
    circle_size: '152px',
    color: colors['Brand/Iceberg'],
    colorLight: colors['Brand/PaleIceberg'],
    title: '8GB data SIM plan for only £7 p/m - (Unlimited calls & texts)',
    usesYellowAssets: true,
    description:
      'SIM-only phone contracts £7 per month. No credit checks and discounts on unused data. Best PAYG SIM winner (2019)',
  },
  '10gb-data': {
    id: 'SM_10GB',
    name: '10GB',
    slug: '10gb-data',
    data_discount: null,
    data: '10GB',
    data_number: 10,
    circle_size: '152px',
    color: colors['Brand/Coral'],
    colorLight: colors['Brand/PaleCoral'],
    ctaTextColour: colors['UI/DarkGrey'],
    title: '10GB data SIM plan for only £8 p/m - (Unlimited calls & texts)',
    usesYellowAssets: true,
    description:
      'SIM-only phone contracts £8 per month. No credit checks and discounts on unused data. Best PAYG SIM winner (2019)',
  },
  '12gb-data': {
    id: 'SM_12GB_NDD',
    name: '12GB',
    slug: '12gb-data',
    data_discount: null,
    data: '12GB',
    data_number: 12,
    circle_size: '152px',
    // temporary while we unblock design system v0.2.3 upgrade
    color: '#CDDF99',
    colorLight: '#EDF4DB',
    ctaTextColour: colors['UI/DarkGrey'],
    title: '12GB data SIM plan for only £8 p/m - (Unlimited calls & texts)',
    usesYellowAssets: true,
    description:
      'SIM-only phone contracts £8 per month. No credit checks and discounts on unused data. Best PAYG SIM winner (2019)',
  },
  '15gb-data-only': {
    id: 'SM_15GB_DATAONLY',
    name: '15GB_DATAONLY',
    slug: '15gb-data-only',
    data_discount: null,
    plan_is_data_only: true,
    data: '15GB',
    data_number: 15,
    circle_size: '110px',
    color: colors['Brand/Leaf'],
    colorLight: colors['Brand/PaleLeaf'],
    title: '15GB data only SIM plan',
    usesYellowAssets: false,
    description: '15GB Data',
  },
  '30gb-data': {
    id: 'SM_30GB',
    name: '30GB',
    slug: '30gb-data',
    data_discount: null,
    data: '30GB',
    data_number: 30,
    circle_size: '92px',
    color: colors['Brand/Orange'],
    colorLight: colors['Brand/PaleOrange'],
    title: '30GB data SIM plan for only £10 p/m (Unlimited calls & texts)',
    usesYellowAssets: true,
    description:
      'SIM-only phone contracts for £10 per month. No credit checks & no long contract to tie you in – Best PAYG SIM winner (2019)',
  },
  '40gb-data-only': {
    id: 'SM_40GB_DATAONLY',
    name: '40GB_DATAONLY',
    slug: '40gb-data-only',
    data_discount: null,
    plan_is_data_only: true,
    data: '40GB',
    data_number: 40,
    circle_size: '110px',
    color: colors['Brand/SandCastle'],
    colorLight: colors['Brand/PaleSandCastle'],
    title: '40GB data only SIM plan',
    usesYellowAssets: false,
    description: '40GB Data',
  },
  '50gb-data': {
    id: 'SM_50GB',
    name: '50GB',
    slug: '50gb-data',
    data_discount: null,
    data: '50GB',
    data_number: 50,
    circle_size: '92px',
    color: colors['Brand/Aqua'],
    colorLight: colors['Brand/PaleAqua'],
    title: '50GB data SIM plan for only £15 p/m (Unlimited calls & texts)',
    usesYellowAssets: true,
    description:
      'Best PAYG SIM winner (2019) SIM-only plan for £10 per month. No speed restriction & no long contract to tie you in.',
  },
  '100gb-calls-and-data': {
    id: 'SM_100GB',
    name: '100GB',
    slug: '100gb-calls-and-data',
    data_discount: null,
    data: '100GB',
    data_number: 100,
    circle_size: '92px',
    color: colors['Brand/Aqua'],
    colorLight: colors['Brand/PaleAqua'],
    title: '100GB data SIM plan for only £12 p/m (Unlimited calls & texts)',
    usesYellowAssets: true,
    description:
      'Best PAYG SIM winner (2019) SIM-only plan for £10 per month. No speed restriction & no long contract to tie you in.',
  },
  '100gb-data': {
    id: 'SM_MEGA',
    name: '100GB',
    slug: '100gb-data',
    data_discount: null,
    data: '100GB',
    data_number: 100,
    circle_size: '142px',
    color: colors['Brand/Coral'],
    colorLight: colors['Brand/PaleCoral'],
    ctaTextColour: colors['UI/DarkGrey'],
    title: '100GB data SIM plan for only £17 p/m - (Unlimited calls & texts)',
    usesYellowAssets: true,
    description:
      'SIM-only phone contracts £17 per month. No credit checks and discounts on unused data. Best PAYG SIM winner (2019)',
  },
  '100gb-data-only': {
    id: 'SM_100GB_DATAONLY',
    name: '100GB_DATAONLY',
    slug: '100gb-data-only',
    data_discount: null,
    plan_is_data_only: true,
    data: '100GB',
    data_number: 100,
    circle_size: '110px',
    color: colors['Brand/Coral'],
    colorLight: colors['Brand/PaleCoral'],
    title: '100GB data only SIM plan',
    usesYellowAssets: false,
    description: '100GB Data',
  },
  '120gb-data': {
    id: 'SM_120GB',
    name: '120GB',
    slug: '120gb-data',
    data_discount: null,
    data: '120GB',
    data_number: 120,
    circle_size: '142px',
    color: colors['Brand/Jade'],
    colorLight: colors['Brand/PaleJade'],
    ctaTextColour: colors['UI/DarkGrey'],
    title: '120GB data SIM plan for only £17 p/m - (Unlimited calls & texts)',
    usesYellowAssets: true,
    description: '120GB Data, Unlimited Text, Unlimited Voice',
  },
  '200gb-data': {
    id: 'SM_200GB',
    name: '200GB',
    slug: '200gb-data',
    data_discount: null,
    data: '200GB',
    data_number: 200,
    circle_size: '142px',
    color: colors['Brand/Rose'],
    colorLight: colors['Brand/PaleRose'],
    ctaTextColour: colors['UI/DarkGrey'],
    title: '200GB data SIM plan for only £17 p/m - (Unlimited calls & texts)',
    usesYellowAssets: true,
    description: '200GB Data, Unlimited Text, Unlimited Voice',
  },
  'unlimited-data-only': {
    id: 'SM_UNLIMITED_DATAONLY',
    name: 'UNLIMITED_DATAONLY',
    slug: 'unlimited-data-only',
    data_discount: null,
    plan_is_data_only: true,
    data: 'Unlimited',
    data_number: Infinity,
    circle_size: '110px',
    color: colors['Brand/Sky'],
    colorLight: colors['Brand/PaleSky'],
    title: 'Unlimited data only SIM plan',
    usesYellowAssets: false,
    description: 'Unlimited Data',
  },
  unlimited: {
    id: 'SM_UNLIMITED',
    name: 'Unlimited',
    slug: 'unlimited',
    data_discount: null,
    data: 'Unlimited',
    data_number: Infinity,
    circle_size: '160px',
    color: colors['Brand/Purple'],
    colorLight: colors['Brand/PalePurple'],
    title: 'Best Unlimited Data SIM Plan with (No Speed Restriction)',
    usesYellowAssets: true,
    description:
      'Our Unlimited data SIM only plans also work with tablets and mobile broadband devices like WiFi routers. (uSwitch “Best Value SIM Only” Winner 2020.)',
  },
  '150gb-data': {
    id: 'SM_150GB',
    name: '150GB',
    slug: '150gb-data',
    data_discount: null,
    data: '150GB',
    data_number: 150,
    circle_size: '142px',
    color: colors['Brand/Sage'],
    colorLight: colors['Brand/PaleSage'],
    title: '150GB data SIM plan for only £15 p/m - (Unlimited calls & texts)',
    usesYellowAssets: true,
    description: '150GB Data, Unlimited Text, Unlimited Voice',
  },
  '40gb-data': {
    id: 'SM_40GB',
    name: '40GB',
    slug: '40gb-data',
    data_discount: null,
    data: '40GB',
    data_number: 40,
    circle_size: '142px',
    color: colors['Brand/Pistachio'],
    colorLight: colors['Brand/PalePistachio'],
    title: '40GB data SIM plan for only £10 p/m - (Unlimited calls & texts)',
    usesYellowAssets: true,
    description: '40GB Data, Unlimited Text, Unlimited Voice',
  },
  '16gb-data': {
    id: 'SM_16GB',
    name: '16GB',
    slug: '16gb-data',
    data_discount: null,
    data: '16GB',
    data_number: 16,
    circle_size: '142px',
    color: colors['Brand/Cornflower'],
    colorLight: colors['Brand/PaleCornflower'],
    title: '16GB data SIM plan for only £8 p/m - (Unlimited calls & texts)',
    usesYellowAssets: true,
    description: '16GB Data, Unlimited Text, Unlimited Voice',
  },
  '5gb-data': {
    id: 'SM_5GB',
    name: '5GB',
    slug: '5gb-data',
    data_discount: null,
    data: '5GB',
    data_number: 5,
    circle_size: '142px',
    color: colors['Brand/PalePeony'],
    colorLight: colors['Brand/PalePeony'],
    title: '5GB data SIM plan for only £6 p/m - (Unlimited calls & texts)',
    usesYellowAssets: true,
    description: '5GB Data, Unlimited Text, Unlimited Voice',
  },
}

export default plans
