import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

import { Title } from '../../components/styles/Text'

const PlanHeading = styled(Title)`
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
`

const PageSection = styled.section`
  margin: 2rem auto 0;
  max-width: 100%;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    margin: 3.5rem auto 0;
  }
`

const SiteWrapper = styled.div`
  position: relative;
`

const PayPalWrapper = styled.div`
  max-width: 192px;
  margin: 2rem auto;
`

export { PlanHeading, PageSection, SiteWrapper, PayPalWrapper }
