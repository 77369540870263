import { breakpoints, colors } from '@smarty-nx/ds'
import { Styles } from 'react-modal'
import styled from 'styled-components'

import dsTheme from '../../constants/design-system/theme.json'

const { defaults, typography } = dsTheme

export const modalStyle: Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 30,
  },
  content: {
    position: 'relative',
    top: 'initial',
    right: 'initial',
    bottom: 'initial',
    left: 'initial',
    background: 'white',
    maxWidth: '100%',
    width: 520,
    maxHeight: '75vh',
    minHeight: '200px',
    marginRight: 16,
    marginLeft: 16,
    padding: 0,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: 12,
    fontFamily: 'Kollektif, Arial, sans-serif',
  },
}

export const ContentWrapper = styled.div`
  color: ${colors['Brand/PrimaryBlack']};
  padding: 2.5rem 1.25rem 1.25rem;
`

export const StyledButton = styled.button`
  background-color: ${colors['Brand/PrimaryBlack']};
  border: none;
  border-radius: ${defaults['Borders/radius']};
  cursor: pointer;
  height: 1.75rem;
  margin: 0.75rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.75rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Image = styled.img`
  width: auto;
`
export const SmallHeader2 = styled.h2`
  ${typography['Kollektif/MediumEmphasis']};
  margin-bottom: 1rem;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    ${typography['Kollektif/Large']};
  }
`
export const ToolTipText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
