import React, { ReactNode, FC } from 'react'

import cn from 'classnames'

import classes from './label.module.scss'

interface LabelProps {
  children?: ReactNode
  dataTestId?: string
  htmlFor: string
  text: ReactNode
  className?: string
  isInline?: boolean
}

export const Label: FC<LabelProps> = ({
  htmlFor,
  text,
  children,
  dataTestId,
  isInline = false,
  className,
}) => {
  return (
    <label
      className={cn(className, {
        [classes.inlineLabel]: isInline,
      })}
      data-testid={dataTestId || htmlFor + '-form-label'}
      htmlFor={htmlFor}
    >
      <span
        className={classes.labelText}
        data-testid={htmlFor + '-input-label'}
      >
        {text}
      </span>
      {children}
    </label>
  )
}
