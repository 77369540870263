import React, { FC } from 'react'

import { ContentfulProcessor } from '@smarty-nx/ds'

import classes from './devices.module.scss'

interface DevicesProps {
  items: Queries.GenericBannerFragment[]
}

export const Devices: FC<DevicesProps> = ({ items }) => {
  const [howTo, ios, android, notSure] = items
  return (
    <div className={classes.root}>
      <div className={classes.first}>
        <h3 className={classes.header}>{howTo.title}</h3>
        <p className="margin--regular">
          <ContentfulProcessor
            content={howTo.description?.internal.content || ''}
          />
        </p>
      </div>

      <div className={classes.devices}>
        {[ios, android].map((device) => (
          <div className={classes.device} key={device.title}>
            <img
              className={classes.deviceIcon}
              alt={device.image?.title || ''}
              src={device.image?.url || ''}
            />
            <h4 className={classes.deviceTitle}>{device.title}</h4>
            <ContentfulProcessor
              content={device.description?.internal.content || ''}
            />
          </div>
        ))}
      </div>

      <div className={classes.last}>
        <h4 className={classes.smallHeader}>{notSure.title}</h4>
        <p className="margin--regular">
          <ContentfulProcessor
            content={notSure.description?.internal.content || ''}
          />
        </p>
      </div>
    </div>
  )
}
