import addons from '../../data/addOnTypes.json'

export const getAddonTypes = () => {
  if (typeof addons === 'undefined') {
    return []
  } else {
    return addons
  }
}

export const getAddonByType = (type: string) =>
  getAddonTypes().find((addon) => addon.type === type)
