import { colors } from '@smarty-nx/ds'
import styled from 'styled-components'

export const Title = styled.h1<{
  center?: boolean
  marginBottom?: string
  marginTop?: string
  weight?: string
}>`
  text-align: ${({ center }) => center && 'center'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  font-weight: ${({ weight }) => weight};
`

export const Heading = styled.h2<{
  center?: boolean
  marginBottom?: string
  weight?: string
}>`
  text-align: ${({ center }) => center && 'center'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  font-weight: ${({ weight }) => weight};
`

export const Paragraph = styled.p<{
  weight?: string
  size?: string
  center?: boolean
  margin?: string
  lineHeight?: string
}>`
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: ${({ center }) => center && 'center'};
  margin: ${({ margin }) => margin || '0'};
  color: ${({ color }) => color};
`

export const HeadingTertiary = styled.h3<{ green?: boolean }>`
  margin-bottom: 16px;
  color: ${({ green }) => (green ? colors['Brand/PrimaryGreen'] : 'white')};
`
