import React from 'react'

import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import PriceTable, {
  NotAvailable,
} from '../../../components/InternationalRoaming'
import PageHead from '../../../components/PageHead'
import {
  BreadCrumbs,
  Desktop,
  Mobile,
  TopFlag,
} from '../../../components/styles/Roaming'
import SiteContainer from '../../../components/styles/SiteContainer'
import withApp from '../../../components/withApp/withApp'

function International({
  pageContext: {
    display_country,
    data,
    back_to_uk,
    back_to_uk_mobile,
    back_to_uk_other,
    local_roaming_fixed,
    local_roaming_mobile,
    local_roaming_other,
    sms_rsn1_rseu,
    inbound_calls,
    mms_picture_rmn1,
    iso_country_code,
    iso_country_name_slug,
  },
}) {
  const isRoamingNotAvailable = [
    data,
    back_to_uk,
    back_to_uk_mobile,
    back_to_uk_other,
    local_roaming_fixed,
    local_roaming_mobile,
    local_roaming_other,
    sms_rsn1_rseu,
    inbound_calls,
    mms_picture_rmn1,
  ].every((price) => price === null)

  return (
    <SiteContainer>
      <BreadCrumbs>
        <Desktop>
          <Link to="/roaming">Roaming & International</Link>
          <span style={{ padding: '0 10px' }}>{'>'}</span>
          <Link to="/roaming/international">International</Link>
          <span style={{ padding: '0 10px' }}>{'>'}</span>
          {display_country}
        </Desktop>
        <Mobile>
          {'< '}
          <Link to="/roaming/international">
            Select another International country
          </Link>
        </Mobile>
      </BreadCrumbs>
      <TopFlag
        src={`/assets/images/flags/${iso_country_code.toLowerCase()}.png`}
        alt={iso_country_code}
        data-testid={`${iso_country_code}-flag`}
      />
      {isRoamingNotAvailable ? (
        <NotAvailable
          display_country={display_country}
          iso_country_name_slug={iso_country_name_slug}
        />
      ) : (
        <PriceTable
          display_country={display_country}
          iso_country_name_slug={iso_country_name_slug}
          data={data}
          back_to_uk={back_to_uk}
          back_to_uk_mobile={back_to_uk_mobile}
          back_to_uk_other={back_to_uk_other}
          local_roaming_fixed={local_roaming_fixed}
          local_roaming_mobile={local_roaming_mobile}
          local_roaming_other={local_roaming_other}
          sms_rsn1_rseu={sms_rsn1_rseu}
          inbound_calls={inbound_calls}
          mms_picture_rmn1={mms_picture_rmn1}
        />
      )}
    </SiteContainer>
  )
}

International.propTypes = {
  pageContext: {
    display_country: PropTypes.string,
    data: PropTypes.number,
    back_to_uk: PropTypes.number,
    back_to_uk_mobile: PropTypes.number,
    back_to_uk_other: PropTypes.number,
    local_roaming_fixed: PropTypes.number,
    local_roaming_mobile: PropTypes.number,
    local_roaming_other: PropTypes.number,
    sms_rsn1_rseu: PropTypes.number,
    inbound_calls: PropTypes.number,
    mms_picture_rmn1: PropTypes.number,
    iso_country_code: PropTypes.string,
    iso_country_name_slug: PropTypes.string,
  },
}

export default withApp(International)

export const Head = ({ pageContext }) => (
  <PageHead title={`Using SMARTY in ${pageContext.display_country}`} />
)

Head.propTypes = {
  pageContext: PropTypes.object,
}
