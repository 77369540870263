import React, { FC, useEffect } from 'react'

import { PageProps } from 'gatsby'

import withApp from '../components/withApp/withApp'
import { CONFIG } from '../config'

const ID = 'bp_div'

const RedeemVoucher: FC<PageProps> = () => {
  useEffect(() => {
    if (window && document) {
      const script = document.createElement('script')
      script.src = '//cdn.co-buying.com/embedding.min.js'
      document.head.appendChild(script)

      const onLoad = () => {
        const buyapowa = new window.Buyapowa(
          {
            url: CONFIG.BUYAPOWA_ROOT_URL || '',
            market: CONFIG.BUYAPOWA_MARKET || '',
          },
          ID
        )

        buyapowa.embedReferAFriend({
          campaign: CONFIG.RAF_VOUCHER_CAMPAIGN_SLUG || '',
          locale: 'en',
        })
      }

      script.addEventListener('load', onLoad)

      return () => {
        script.removeEventListener('load', onLoad)
        document.head.removeChild(script)
      }
    }

    return undefined
  }, [])

  return (
    <div
      id={ID}
      style={{
        minHeight: 400,
        maxWidth: 940,
        margin: '50px auto',
      }}
    />
  )
}

export default withApp(RedeemVoucher)
