import planMappings from '../../data/planMappings.json'

export const getPlanMappings = () => {
  if (typeof planMappings === 'undefined') {
    return []
  } else {
    return planMappings
  }
}

export const getPlanSlugReplacement = (slug: string) =>
  getPlanMappings().find((mapping) => mapping.oldPlan.slug === slug)?.plan.slug
