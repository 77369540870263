import React from 'react'

import { calculatePriceWithVAT } from '@smarty-nx/code-utils'
import PropTypes from 'prop-types'

import { CONFIG } from '../../config'
import WifiSvg from '../../static/images/wifi.svg'
import Button from '../Button'
import RoamingTopBanner from '../RoamingTopBanner'
import {
  ButtonContainer,
  Card,
  Container,
  Heading,
  Paragraph,
  Table,
  TdPricing,
  WifiCard,
} from '../styles/Roaming'

export function NotAvailable({ display_country, iso_country_name_slug }) {
  return (
    <React.Fragment>
      <Heading>
        Using SMARTY in {display_country} is not available at the moment.
      </Heading>
      <RoamingTopBanner countrySlug={iso_country_name_slug} />
      <p>
        We’re not able to support using your SMARTY SIM in {display_country} at
        the moment. We recommend buying a local SIM or using local Wifi.
      </p>
      <ButtonContainer>
        <Button to="/roaming/international" color="#EFC873">
          Select another country
        </Button>
      </ButtonContainer>
    </React.Fragment>
  )
}

NotAvailable.propTypes = {
  display_country: PropTypes.string,
  iso_country_name_slug: PropTypes.string,
}

export default function PriceTable({
  display_country,
  iso_country_name_slug,
  data,
  back_to_uk,
  back_to_uk_mobile,
  back_to_uk_other,
  local_roaming_fixed,
  local_roaming_mobile,
  local_roaming_other,
  sms_rsn1_rseu,
  inbound_calls,
  mms_picture_rmn1,
}) {
  return (
    <>
      <Heading>Using SMARTY in {display_country}</Heading>
      <RoamingTopBanner countrySlug={iso_country_name_slug} />
      <Container bgColor="#ffd67c" padding="15px">
        <Table>
          <thead>
            <tr>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              {data ? (
                <>
                  <td>Using Internet or Data</td>
                  <TdPricing>
                    {calculatePriceWithVAT(data)}
                    <span>/ mb</span>
                  </TdPricing>
                </>
              ) : (
                <>
                  <td>Internet and Data Unavailable</td>
                  <td />
                </>
              )}
            </tr>

            <tr>
              <td>Call to UK Landlines</td>
              <TdPricing>
                {calculatePriceWithVAT(back_to_uk)}
                <span>/ min</span>
              </TdPricing>
            </tr>

            <tr>
              <td>Call to UK Mobiles</td>
              <TdPricing>
                {calculatePriceWithVAT(back_to_uk_mobile)}
                <span>/ min</span>
              </TdPricing>
            </tr>

            <tr>
              <td>Call to UK Other</td>
              <TdPricing>
                {calculatePriceWithVAT(back_to_uk_other)}
                <span>/ min</span>
              </TdPricing>
            </tr>

            <tr>
              <td>Call to {display_country} Landlines</td>
              <TdPricing>
                {calculatePriceWithVAT(local_roaming_fixed)}
                <span>/ min</span>
              </TdPricing>
            </tr>

            <tr>
              <td>Call to {display_country} Mobiles</td>
              <TdPricing>
                {calculatePriceWithVAT(local_roaming_mobile)}
                <span>/ min</span>
              </TdPricing>
            </tr>

            <tr>
              <td>Call to {display_country} Other</td>
              <TdPricing>
                {calculatePriceWithVAT(local_roaming_other)}
                <span>/ min</span>
              </TdPricing>
            </tr>

            <tr>
              <td>SMS to {display_country} numbers</td>
              <TdPricing>
                {calculatePriceWithVAT(sms_rsn1_rseu)}
                <span>/ sms</span>
              </TdPricing>
            </tr>

            <tr>
              <td>Receiving calls from any number</td>
              <TdPricing>
                {calculatePriceWithVAT(inbound_calls)}
                <span>/ min</span>
              </TdPricing>
            </tr>

            <tr>
              <td>Receiving texts from any number</td>
              <TdPricing>FREE</TdPricing>
            </tr>

            <tr>
              <td>MMS to UK numbers</td>
              <TdPricing>
                {calculatePriceWithVAT(mms_picture_rmn1)}
                <span>/ mms</span>
              </TdPricing>
            </tr>

            <tr>
              <td>Call and text other numbers</td>
              <TdPricing>
                <span>
                  <a
                    href="/priceguide"
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label="See all prices .PDF (253KB) link opens in a new window"
                  >
                    See all prices
                  </a>
                </span>
              </TdPricing>
            </tr>
          </tbody>
        </Table>
        <Card>
          <h2 className="card-heading">Before you go</h2>
          <h2>Phone settings update required</h2>
          <p>
            Your SMARTY SIM won’t work abroad unless you make a one-off change
            to your phone settings.
          </p>
          <a href="/roaming-setup">Set up your phone now</a>
          <h2>Buy “Out of plan” add-ons</h2>
          <p>
            Data, calls or texts outside the EU won’t work if you don’t have
            some “Out of plan” add-ons in your account. If you don’t use all
            your “Out of plan” add-ons, you can choose to put that money towards
            your next month or you can use it next time you need it.
          </p>
          <a href={`${CONFIG.DASHBOARD_URL}cash-addons`}>
            Buy “Out of plan” add-ons
          </a>
        </Card>
      </Container>
      <WifiCard>
        <div>
          <h2>Save money by connecting to wifi</h2>
          <p>
            Using your phone abroad is expensive. We always try and give you a
            good deal, but have less control over pricing in other countries.
          </p>
          <p>To save money you should connect to wifi wherever possible.</p>
        </div>
        <img src={WifiSvg} alt="Wifi" />
      </WifiCard>
      <Paragraph>Prices are shown including VAT where applicable.</Paragraph>
    </>
  )
}

PriceTable.propTypes = {
  display_country: PropTypes.string,
  iso_country_name_slug: PropTypes.string,
  data: PropTypes.number,
  back_to_uk: PropTypes.number,
  back_to_uk_mobile: PropTypes.number,
  back_to_uk_other: PropTypes.number,
  local_roaming_fixed: PropTypes.number,
  local_roaming_mobile: PropTypes.number,
  local_roaming_other: PropTypes.number,
  sms_rsn1_rseu: PropTypes.number,
  inbound_calls: PropTypes.number,
  mms_picture_rmn1: PropTypes.number,
}
