export const discountCardContent = {
  cta: 'View our data discount plans',
  CTALink: '/data-rollover',
  title: 'Get money back with our data discount plans ',
  textOne: 'Check out our exclusive ',
  textTwo: 'and get money back for unused data at the end of ',
  greenText: 'data discount plans ',
  yellowText: 'every ',
  textThree: 'month.',
}
