import React from 'react'

import {
  customIcon,
  HeadingSecondary,
  OfferContainer,
  OfferItem,
  OfferParagraph,
} from './styles'
import Family from '../../static/images/groupsFamily.svg'
import Sims from '../../static/images/groupsSIMs.svg'
import Icon from '../styles/Icon'
import SiteContainer from '../styles/SiteContainer'
import Spacer from '../styles/Spacer'

const GroupOffer = () => (
  <SiteContainer>
    <Spacer size={1} />
    <OfferContainer>
      <OfferItem>
        <Icon src={Family} alt="" margin="0 0 30px" css={customIcon} />
        <HeadingSecondary>Bring your family accounts together</HeadingSecondary>
        <OfferParagraph>
          You can choose plans for your kids that suit their data needs or help
          out with Mum’s account. Save time by paying from one place and get 10%
          off every SIM.
        </OfferParagraph>
      </OfferItem>
      <OfferItem>
        <Icon src={Sims} alt="" margin="0 0 30px" css={customIcon} />
        <HeadingSecondary>Adding an extra SIM is easy</HeadingSecondary>
        <OfferParagraph>
          Manage multiple SIMs for your tablet, work phone, or anything else you
          need. You can add up to 8 SIMS, including the group owner.
        </OfferParagraph>
      </OfferItem>
    </OfferContainer>
    <Spacer size={1} />
  </SiteContainer>
)

export default GroupOffer
