import { useEffect, useRef } from 'react'

export const useEventOutside = (
  callback: (e: Event) => void,
  event = 'click'
) => {
  const ref = useRef<HTMLDivElement>(null)
  const callbackRef = useRef(callback)

  useEffect(() => {
    const handler: EventListener = (e) => {
      const element = ref.current

      if (element && !element.contains(e.target as Node)) {
        callbackRef.current(e)
      }
    }

    document.addEventListener(event, handler)

    return () => {
      document.removeEventListener(event, handler)
    }
  }, [event])

  return ref
}
