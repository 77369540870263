import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

export const FullWidthContainer = styled.div<{
  background: string
  desktopBackground?: string
}>`
  background: ${({ background }) => background};
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: ${({ color }) => color};
  max-width: 100vw;
  overflow-x: hidden;
  box-sizing: border-box;

  ${({ desktopBackground }) =>
    desktopBackground &&
    `
    @media (min-width: ${breakpoints['desktop-small']}px) {
      background: ${desktopBackground};
    }
  `}
`

export default FullWidthContainer
