import React, { useCallback, useEffect, useState } from 'react'

import axios from 'axios'
import queryString from 'query-string'
import { path } from 'ramda'

import MaintenanceMessage from '../components/MaintenanceMessage'
import { CONFIG } from '../config'
import { safeLocation } from '../utils/safeWindow'
import { REDIRECT_TO_URL_KEY } from '../utils/url'

const MAINTENANCE_API_URL = `${CONFIG.API_URL}/v1/maintenance`

const Maintenance = () => {
  const [endAt, setEndAt] = useState()

  const queryParams = queryString.parse(safeLocation().search)
  const redirectTo = queryParams[REDIRECT_TO_URL_KEY]

  const redirectIfSourcePresent = useCallback(() => {
    if (redirectTo) {
      // Construct new URL, without the redirectTo value in the query:
      const newSearch = { ...queryParams, redirectTo: undefined }
      const url =
        CONFIG.SMARTY_ENV === 'localhost'
          ? CONFIG.DASHBOARD_URL
          : safeLocation().href
      const newUrl = new URL(url)
      newUrl.search = queryString.stringify(newSearch)
      newUrl.pathname = redirectTo

      // Redirect to that new URL:
      safeLocation().href = newUrl.href
    }
  }, [queryParams, redirectTo])

  // We need that only to get the endAt date of the maintenance
  // in order to give some more information to the customer.
  const refreshMaintenance = useCallback(async () => {
    const res = await axios.get(MAINTENANCE_API_URL)
    if (res.data) {
      // Bless JsonAPI structure
      const maintenanceEndAt = path(
        ['data', 'data', 'attributes', 'end_at'],
        res
      )

      // If maintenance is over - this will be null.
      // If so, no need to continue the execution of this effect.
      // The only thing we need to do before returning, is redirect
      // the customer back, in case we have the redirectTo URL present
      if (!maintenanceEndAt) {
        redirectIfSourcePresent()
        return
      }

      const endAtAsDate = new Date(maintenanceEndAt)
      setEndAt(endAtAsDate)

      // If the maintenance is over - there is nothing else to do on this page.
      // So, if a redirectTo query param is present - take the user back to that
      // URL.
      if (endAtAsDate < new Date() && redirectTo) {
        redirectIfSourcePresent()
      }
    } else {
      redirectIfSourcePresent()
    }
  }, [redirectIfSourcePresent, redirectTo])

  useEffect(() => {
    let refreshTimer = setTimeout(() => refreshMaintenance(), 2000)
    return () => {
      clearTimeout(refreshTimer)
    }
  })

  return <MaintenanceMessage endAt={endAt} />
}

export default Maintenance

export const Head = () => <title>Site in maintenance | SMARTY</title>
