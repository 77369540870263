import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

import dsTheme from '../../constants/design-system/theme.json'

const { typography } = dsTheme

interface RootProps {
  bgColor: string
}

const Root = styled.div<RootProps>`
  background-color: ${colors['UI/White']};
  border-radius: 5px;
  color: ${colors['UI/White']};
  padding: 1rem;
  text-align: center;
  position: relative;
  margin-bottom: 1.5rem;

  ${(props) =>
    props.bgColor &&
    `
    background-color: ${props.bgColor};
  `}

  @media (min-width: ${breakpoints['tablet-landscape']}px) {
    text-align: left;
    padding: 2rem;
    margin-bottom: 2.75rem;
  }
`

const FlexWrapper = styled.div`
  @media (min-width: ${breakpoints['tablet-landscape']}px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`

const Headline = styled.h2`
  ${typography['Kollektif/TinyEmphasis']};
  margin-bottom: 0;

  @media (min-width: ${breakpoints['tablet-landscape']}px) {
    ${typography['Kollektif/Large']};
  }
`

const Link = styled.a`
  ${typography['Kollektif/Tiny']};
  @media (min-width: ${breakpoints['tablet-landscape']}px) {
    ${typography['Kollektif/MediumUnderline']};
    padding-left: 2rem;
    max-width: 220px;
    width: 100%;
    align-self: start;
  }
`

export { Root, FlexWrapper, Headline, Link }
