import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

export const Root = styled.div`
  background: ${colors['UI/White']};
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
`
