import React, { FC, useState } from 'react'

import { processVars } from '@smarty-nx/code-utils'
import {
  getRaeBannerByReferralLink,
  getRaeBannerByBonusSim,
} from '@smarty-nx/contentful'
import cn from 'classnames'

import { useReferralData } from './rae-banner.hooks'
import classes from './rae-banner.module.scss'
import { ContentfulProcessor } from '../'
import { Icon } from '../icon/icon'

export type RaeBannerSource = 'referral-link' | 'bonus-sim'

interface RaeBannerProps {
  opened?: boolean
  source?: RaeBannerSource
}

/****
 * Refer and Earn Banner
 * Design: https://www.figma.com/design/4ghqaFvzGHS1meF4PcON2J/Bonus-SIM-%26-RAF?node-id=3130-136243&t=JXaecZQAt6wWzPUu-0
 */
export const RaeBanner: FC<RaeBannerProps> = ({
  opened = false,
  source = 'referral-link',
}) => {
  const [isOpen, setIsOpen] = useState(opened)
  const referralData = useReferralData()
  const isReferralLinkSource = source === 'referral-link'

  if (isReferralLinkSource && !referralData) {
    return null
  }

  const banner = isReferralLinkSource
    ? getRaeBannerByReferralLink()
    : getRaeBannerByBonusSim()

  const handleClick = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  return (
    <div className={classes.root} data-testid="rae-banner">
      <div className={classes.inner}>
        <img
          className={classes.logo}
          src={banner?.image?.url}
          alt={banner?.image?.title}
          data-testid="rae-banner-logo"
        />
        <div>
          <div className={classes.heading}>
            <p className={classes.title} data-testid="rae-banner-title">
              <ContentfulProcessor
                content={processVars(banner?.title || '', {
                  referralName: referralData?.first_name || '',
                })}
              />
            </p>
            <Icon
              name="chevron-up"
              className={cn(classes.chevron, {
                [classes.chevronOpened]: isOpen,
              })}
              onClick={handleClick}
            />
          </div>
          {isOpen && (
            <div className={classes.content} data-testid="rae-banner-content">
              <ContentfulProcessor content={banner?.description || ''} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
