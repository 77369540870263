import React, { FC } from 'react'

import cn from 'classnames'

import classes from './trust-pilot.module.scss'
import TrustPilot from '../../../components/TrustPilot'

export const TrustPilotSection: FC<{ transparent?: boolean }> = ({
  transparent,
}) => {
  return (
    <div className={cn(classes.root, transparent && classes.transparent)}>
      <TrustPilot
        reviewTag="SelectedReview"
        dataTestId="homepage-trustpilot-widget"
      />
    </div>
  )
}
