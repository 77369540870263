import React, { FC, ReactNode } from 'react'

import classes from './error-message.module.scss'
import { Icon } from '../icon/icon'

interface ErrorMessageProps {
  children: ReactNode
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ children }) => {
  return (
    <div className={classes.error}>
      <Icon name="general-alert" className={classes.errorIcon} />
      {children}
    </div>
  )
}
