import React, { FC, ReactNode } from 'react'

import cn from 'classnames'
import ReactModal from 'react-modal'

import classes from './modal.module.scss'
import { Icon } from '../icon/icon'

interface ModalProps extends ReactModal.Props {
  primaryCta?: ReactNode
  secondaryCta?: ReactNode
  preHeader?: ReactNode
  header?: string
  modalClassName?: string
  headerClassName?: string
}

ReactModal.setAppElement('body')

export const Modal: FC<ModalProps> = ({
  children,
  primaryCta,
  secondaryCta,
  header,
  testId = 'modal-content',
  preHeader,
  modalClassName,
  headerClassName,
  ...props
}) => {
  return (
    <ReactModal
      testId={testId}
      className={cn(classes.modal, modalClassName)}
      overlayClassName={classes.overlay}
      {...props}
    >
      {preHeader}
      {props.onRequestClose && (
        <button
          className={classes.closeButton}
          onClick={props.onRequestClose}
          data-testid="close-modal"
          data-item-category="GI"
          data-item-name="dismiss"
        >
          <Icon name="close" />
        </button>
      )}
      {header && (
        <h2
          className={cn(classes.header, headerClassName)}
          data-testid={`${testId}-header`}
        >
          {header}
        </h2>
      )}
      {children}
      {(primaryCta || secondaryCta) && (
        <div className={classes.actions}>
          {primaryCta}
          {secondaryCta}
        </div>
      )}
    </ReactModal>
  )
}
