import React, { FC } from 'react'

import { SliderOnMobile } from '@smarty-nx/ds'
import cn from 'classnames'

import { Tile } from './_parts'
import classes from './awards.module.scss'
import { isBanner } from '../../../node_pages/content-type-checks'

export const AwardsSection: FC<Queries.GenericPageSectionFragment> = (
  props
) => {
  const items = props.items?.filter(isBanner) || []

  if (!items.length) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={cn(classes.container, classes.swiper)}>
        <SliderOnMobile options={{ className: classes.slides }}>
          {items.map((item) => (
            <Tile {...item} key={item.type} />
          ))}
        </SliderOnMobile>
      </div>
    </div>
  )
}
