import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

export const TooltipWrapper = styled.div`
  cursor: pointer;
  position: relative;
  margin-right: auto;
  top: 0;

  @media (min-width: ${breakpoints.mobile}px) {
    margin: 0;
  }
  button {
    cursor: pointer;
    align-items: center;
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    text-decoration: none;
    width: 100%;

    &:hover,
    &:focus {
      text-decoration: none;

      > span {
        text-decoration: none;
      }
    }
  }

  [aria-hidden] {
    width: 24px;
    height: 24px;
  }
`
