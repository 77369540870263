export const helpTopicsContent = {
  title: 'Get help on a specific topic',
  topics: [
    {
      title: 'Getting started with SMARTY',
      url: 'https://help.smarty.co.uk/en/collections/607870-getting-started-with-smarty',
      data_item_name: 'getting-started',
    },
    {
      title: 'Data, calls, and texts',
      url: 'https://help.smarty.co.uk/en/collections/607861-data-calls-and-texts',
      data_item_name: 'data-calls-and-texts',
    },
    {
      title: 'Deliveries',
      url: 'https://help.smarty.co.uk/en/articles/1155166-sim-delivery',
      data_item_name: 'deliveries',
    },
    {
      title: 'Account and billing',
      url: 'https://help.smarty.co.uk/en/collections/607879-account-and-billing',
      data_item_name: 'account-and-billing',
    },
    {
      title: 'Group plans',
      url: 'https://help.smarty.co.uk/en/collections/1974243-group-plans',
      data_item_name: 'group-plans',
    },
    {
      title: 'SIM and device help',
      url: 'https://help.smarty.co.uk/en/collections/607915-sim-and-device-help',
      data_item_name: 'sim-and-device-help',
    },
    {
      title: 'Going or calling abroad',
      url: 'https://help.smarty.co.uk/en/collections/607945-going-or-calling-abroad',
      data_item_name: 'going-or-calling-abroad',
    },
    {
      title: 'Privacy and security',
      url: 'https://help.smarty.co.uk/en/collections/1358648-privacy-and-security',
      data_item_name: 'privacy-and-security',
    },
    {
      title: 'Network and service',
      url: 'https://help.smarty.co.uk/en/collections/2644490-network-and-service',
      data_item_name: 'network-and-service',
    },
  ],
}
