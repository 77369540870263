import React, { useRef } from 'react'

import { Content } from './ReferAFriend.content'
import { CtaInput, CtaWrapper } from './ReferAFriend.styles'
import { TRACKING_CATEGORIES } from '../../constants/globalStrings'
import Button, { Level } from '../Buttons'

interface ReferAFriendProps {
  buyapowaShareUrl?: string
}

const ReferAFriendCta = ({ buyapowaShareUrl }: ReferAFriendProps) => {
  const input = useRef<HTMLInputElement>(null)

  const copyRafLink = () => {
    if (!input.current) return
    input.current.select()
    document.execCommand('copy')
  }

  return (
    <CtaWrapper>
      <CtaInput
        aria-label="Referral link"
        ref={input}
        type="text"
        value={buyapowaShareUrl}
        data-testid="referral-link"
        readOnly
      />
      <Button
        onClick={copyRafLink}
        level={Level.Quaternary}
        data-item-category={TRACKING_CATEGORIES.GENERAL_INTERACTION}
        data-item-id="copy-referral-link"
        dataTestId="copy-referral-link-cta"
        disabled={false}
      >
        {Content.cta}
      </Button>
    </CtaWrapper>
  )
}

export default ReferAFriendCta
