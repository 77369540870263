import FooterEntries from '../../data/footers.json'

type FooterEntry = typeof FooterEntries[0]

export const getFooter = (
  footerType: string,
  channel: string
): FooterEntry | null => {
  let footer: FooterEntry | null = null

  if (typeof FooterEntries !== 'undefined') {
    footer =
      FooterEntries.find(
        (item) => item.application === footerType && item.channel === channel
      ) || null
  }
  return footer
}
