import React, { FC } from 'react'

import { ContentfulProcessor } from '@smarty-nx/ds'

import classes from './hero-banner.module.scss'
import { ContentfulLink } from '../../../../components/contentful-link'
import { isBanner } from '../../../content-type-checks'

export const HeroBanner: FC<
  Queries.GenericPageSectionFragment & { index: number }
> = (section) => {
  return (
    <div>
      {section.items?.filter(isBanner).map((item) => (
        <div className={classes.container} key={item.id}>
          <img
            className={classes.image}
            src={item.image?.url || ''}
            alt={item.image?.title || ''}
          />

          <div className={classes.content}>
            {section.index === 0 ? (
              <h1 className={classes.title}>{item.title}</h1>
            ) : (
              <h2 className={classes.title}>{item.title}</h2>
            )}

            {item.description?.internal.content && (
              <div className={classes.description}>
                <ContentfulProcessor
                  content={item.description.internal.content}
                />
              </div>
            )}

            {item.link?.internal.content && (
              <div className={classes.links}>
                <ContentfulLink link={item.link.internal.content} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
