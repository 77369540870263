import React from 'react'

import { colors } from '@smarty-nx/ds'

import {
  ButtonWrapper,
  List,
  ListItem,
  SecondaryCta,
  StyledButton,
} from './styles'
import { CONFIG } from '../../config'
import NumberOneImage from '../../static/images/numberOne.svg'
import NumberThreeImage from '../../static/images/numberThree.svg'
import NumberTwoImage from '../../static/images/numberTwo.svg'
import Icon from '../styles/Icon'
import SiteContainer from '../styles/SiteContainer'
import Spacer from '../styles/Spacer'
import TextLink from '../TextLink'

const GroupSteps = () => (
  <SiteContainer>
    <List>
      <ListItem>
        <Icon
          src={NumberOneImage}
          alt="Step 1"
          width="40px"
          height="40px"
          margin="0 0 1rem 0"
        />
        <div>
          <h3>Start your Group</h3>
          <p>
            Log in, go to your Group plan page and choose whether you’d like to
            add an existing SMARTY account or add a new SIM.
          </p>
        </div>
      </ListItem>
      <ListItem>
        <Icon
          src={NumberTwoImage}
          alt="Step 2"
          width="40px"
          height="40px"
          margin="0 0 1rem 0"
        />
        <div>
          <h3>We’ll keep you informed</h3>
          <p>
            If you invite an existing SMARTY account, once they accept, you’ll
            get a notification letting you know they’ve been added to your Group
            with details of their next month’s charges.
          </p>
          <p>
            If you add a new SIM, we’ll add it to your Group automatically.
            You’ll receive a notification once it is activated and the monthly
            plan has started.
          </p>
        </div>
      </ListItem>
      <ListItem>
        <Icon
          src={NumberThreeImage}
          alt="Step 3"
          width="40px"
          height="40px"
          margin="0 0 1rem 0"
        />
        <div>
          <h3>Enjoy discounted plans</h3>
          <p>
            Save an extra 10% on every SIM you add to your Group. Stay flexible
            if you change your mind. Save time and money. That’s SMARTY.
          </p>
        </div>
      </ListItem>
    </List>

    <ButtonWrapper>
      <StyledButton
        href={`${CONFIG.DASHBOARD_URL}groups`}
        color={colors['Brand/PrimaryGreen']}
      >
        Start a Group
      </StyledButton>
      <SecondaryCta>
        New to SMARTY?{' '}
        <TextLink href="/select-plan">Choose a plan to start</TextLink>
      </SecondaryCta>
    </ButtonWrapper>
    <Spacer size={1} />
  </SiteContainer>
)

export default GroupSteps
