import styled from 'styled-components'

export const ApplePayButtonRoot = styled.div`
  display: inline-block;
  border-radius: 4px;
  padding: 20px 10px;
  box-sizing: border-box;
  width: 224px;
  height: 55px;
  margin-left: 1.4rem;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: black;
  white-space: nowrap;
  color: white;
`
