import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

import dsTheme from '../../constants/design-system/theme.json'

const { typography } = dsTheme

const PlansWrapper = styled.div`
  display: block;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const MainHeadingWrapper = styled.div`
  display: flex;
`

const HeadingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const HeadingWithCoverage = styled.div`
  display: flex;
  justify-content: space-between;
`
const HeadingSecondary = styled.h2`
  ${typography['Kollektif/Large']};
  margin-bottom: 0.5rem;
  margin-right: 1rem;

  @media (min-width: ${breakpoints.tablet}px) {
    ${typography['Kollektif/ExtraLarge']};
  }
`

const HeadingSecondarySmall = styled.h2`
  ${typography['Kollektif/MediumEmphasis']};
  margin-bottom: 1.5rem;

  @media (min-width: ${breakpoints.tablet}px) {
    ${typography['Kollektif/Large']};
  }
`

const TopCoverageWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;

  // Special case (100px awkward space)
  @media (min-width: 590px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`

const List = styled.ul`
  list-style-type: none;
  margin: 1rem 0;
  padding: 0;
`

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding-bottom: 1rem;

  p:last-child {
    margin: 0;
  }
`
const ToolTipHeading = styled.p`
  ${typography['Kollektif/ExtraSmall']};
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints.tablet}px) {
    ${typography['Kollektif/Small']};
  }
`
const ItemList = styled.p`
  ${typography['Kollektif/ExtraSmall']};
`
const Note = styled.p`
  ${typography['Kollektif/Small']};
`
export {
  PlansWrapper,
  HeadingWithCoverage,
  HeadingSecondary,
  HeadingSecondarySmall,
  TopCoverageWrapper,
  MainHeadingWrapper,
  HeadingsWrapper,
  List,
  ListItem,
  ToolTipHeading,
  ItemList,
  Note,
}
