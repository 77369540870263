import React from 'react'

import cn from 'classnames'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react'

import classes from './numbers-carousel.module.scss'

type NumbersCarouselProps = {
  quantity: number
  value?: number
  index?: number
  dataTestId?: string
  ariaLabel?: string
  wrapperClassName?: string
  handleSelect: (value: number) => void
} & SwiperProps

export const NumbersCarousel = ({
  quantity,
  value = 0,
  index = 0,
  handleSelect,
  dataTestId = '',
  ariaLabel = '',
  wrapperClassName,
  ...props
}: NumbersCarouselProps) => {
  const createSlides = () => {
    return [...Array(quantity)].map((_, i) => {
      const number = i + 1
      return (
        <SwiperSlide key={number}>
          {() => (
            <div
              className={
                value === number
                  ? cn(classes.number, classes.selected)
                  : classes.number
              }
              key={number}
              onClick={() => {
                handleSelect(number)
              }}
            >
              {number}
            </div>
          )}
        </SwiperSlide>
      )
    })
  }

  return (
    <Swiper
      modules={[Navigation, Pagination]}
      aria-label={ariaLabel}
      wrapperClass={wrapperClassName}
      allowTouchMove
      initialSlide={index}
      navigation={false}
      onSlideChangeTransitionEnd={(swiper) => {
        const activeSlide = swiper.slides[swiper.activeIndex] as HTMLElement
        activeSlide?.focus()
      }}
      spaceBetween={4}
      slidesPerView="auto"
      data-testid={dataTestId}
      {...props}
    >
      {createSlides()}
    </Swiper>
  )
}
