import React, { FC, ReactNode, useEffect, useState } from 'react'

import { useRunCallbackOnEnteringViewport } from '@smarty-nx/code-utils'
import cn from 'classnames'
import { Swiper as SwiperClass } from 'swiper/types'

import { Slider, ThisPlanIncludes } from './_parts'
import classes from './plans-carousel.module.scss'
import { usePlans } from '../../../hooks'
import {
  triggerModuleLoadEvent2,
  triggerProductViewEvent,
} from '../../../utils/dataLayer'
import { buildAssetsProductsProps } from '../../../utils/dmpgAttributes'
import { Plan } from '../../page-section/popular-plans/types'

interface PlansCarouselProps {
  title: string
  heading?: ReactNode
  slugs: string[]
  dataTestId?: string
  moduleId: string
  defaultPosition: number
}

export const PlansCarousel: FC<PlansCarouselProps> = ({
  title,
  slugs,
  heading,
  dataTestId,
  moduleId,
  defaultPosition = 0,
}) => {
  const { isLoading, list } = usePlans(slugs)
  const [plan, setPlan] = useState<Plan | undefined>(undefined)

  const ref = useRunCallbackOnEnteringViewport(() => {
    triggerModuleLoadEvent2(moduleId)
  })

  const handleUpdateThisPlanIncludes = ({ realIndex }: SwiperClass) => {
    setPlan(list[realIndex])
  }

  useEffect(() => {
    if (plan) {
      triggerProductViewEvent(buildAssetsProductsProps(plan).products[0])
    }
  }, [plan])

  return (
    <>
      <div
        ref={list.length > 0 ? ref : null}
        className={cn('redesign-container', classes.container)}
      >
        <div className={classes.title}>{title}</div>
        {heading}
      </div>

      {!isLoading && (
        <Slider
          list={list}
          onAfterInit={handleUpdateThisPlanIncludes}
          onSlideChange={handleUpdateThisPlanIncludes}
          dataTestId={dataTestId}
          defaultPosition={defaultPosition}
        />
      )}

      {plan && (
        <div className={classes.includes}>
          <ThisPlanIncludes selectedPlan={plan} />
        </div>
      )}
    </>
  )
}
