import React from 'react'

import { ButtonWrapper } from './styles'
import renderLinkType from '../../utils/renderLink'

type ButtonProps = {
  ariaLabel?: string
  centered?: boolean
  color?: string
  children?: React.ReactNode
  customStyle?: any[] | any
  className?: string | any
  eventName?: string
  eventProductId?: string
  eventCheckoutType?: string
  foreColor?: string
  /* takes the width of the parent element */
  fullWidth?: boolean
  /* 384px max width */
  wide?: boolean
  href?: string
  linkStyle?: boolean
  newTab?: boolean
  onClick?: any
  to?: string
  level?: string
  custStyle?: boolean
}

const Button = ({
  href,
  to,
  color,
  foreColor,
  children,
  onClick,
  ariaLabel,
  fullWidth = false, // full width = 100% of container
  wide = false, // wide = max 384px (desktop)
  linkStyle = false,
  centered = false,
  newTab,
  className,
  customStyle,
  level = 'primary',
  /**
   * the following props are consumed within data attributes on the button.
   * the attributes are converted to objects for datalayer events */
  eventName,
  eventProductId,
  eventCheckoutType,
  custStyle,
  ...props
}: ButtonProps) => (
  <ButtonWrapper
    className={`${className || ''} button`}
    // Transient props for styled component
    $foreColor={foreColor || null}
    $level={level}
    $linkStyle={linkStyle}
    $color={color}
    $centered={centered}
    $wide={wide}
    $fullWidth={fullWidth}
    $customStyle={customStyle}
    $custStyle={custStyle}
    // Render styled component as `Link`, `button`, or `a`
    as={renderLinkType({ href, to })}
    data-dmpg-event-name={eventName && eventName}
    data-event_product_id={eventProductId && eventProductId}
    data-event_checkout-type={eventCheckoutType && eventCheckoutType}
    onClick={onClick}
    href={href}
    to={to}
    aria-label={ariaLabel}
    {...props}
    {...(newTab
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {})}
  >
    {children}
  </ButtonWrapper>
)

export default Button
