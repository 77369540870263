import React, { useEffect, useState } from 'react'

import braintree from 'braintree-web'

import { ApplePayButtonRoot } from './ApplePayButton.styles'
import { CONFIG } from '../../../config'
import api from '../../../utils/api'
import { canUseApplePay, handleApplePayButton } from '../../../utils/paypal'

interface Props {
  isSupported?: boolean
  label: string
  amount: number
}

const ApplePayButton = ({
  isSupported = canUseApplePay(),
  label,
  amount,
}: Props) => {
  const [applePayInstance, setApplePayInstance] = useState(null)

  useEffect(() => {
    const fn = async () => {
      try {
        const tokenResponse = await api('/v1/orders/token')
        if (tokenResponse?.data?.data?.attributes?.token) {
          const clientInstance = await braintree.client.create({
            authorization: tokenResponse.data.data.attributes.token,
          })
          setApplePayInstance(
            await braintree.applePay.create({
              client: clientInstance,
            })
          )
        }
      } catch (e) {
        console.error('Error is ', { e })
      }
    }
    fn()
  }, [])

  if (!isSupported) {
    return null
  }

  return (
    applePayInstance && (
      <ApplePayButtonRoot
        onClick={() =>
          handleApplePayButton({
            applePayInstance,
            displayName: CONFIG.PAYPAL_DISPLAYNAME,
            label,
            amount,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleSuccessCallback: (payload: any) => console.log(payload),
            handleErrorCallback: (e: Event) =>
              console.error('Error from Apple Pay is ', { e }),
          })
        }
      >
        Pay with Apple Pay
      </ApplePayButtonRoot>
    )
  )
}

export default ApplePayButton
