import { colors } from '@smarty-nx/ds'
import { css } from 'styled-components'

import dsTheme from './theme.json'
import { CONFIG } from '../../config'

const { spacing, typography } = dsTheme

const globalTypography = css`
  @font-face {
    font-family: Kollektif;
    src: url('${CONFIG.MARKETING_URL}assets/webfonts/Kollektif.woff')
        format('woff'),
      url('${CONFIG.MARKETING_URL}assets/webfonts/Kollektif.ttf')
        format('truetype');
    font-display: fallback;
  }

  @font-face {
    font-family: Kollektif;
    font-weight: bold;
    src: url('${CONFIG.MARKETING_URL}assets/webfonts/Kollektif-Bold.woff')
        format('woff'),
      url('${CONFIG.MARKETING_URL}assets/webfonts/Kollektif-Bold.ttf')
        format('truetype');
    font-display: fallback;
  }

  @font-face {
    font-family: Montserrat;
    font-weight: 300;
    src: url('${CONFIG.MARKETING_URL}assets/webfonts/Montserrat-Light.woff')
        format('woff'),
      url('${CONFIG.MARKETING_URL}assets/webfonts/Montserrat-Light.ttf')
        format('truetype');
    font-display: fallback;
  }

  @font-face {
    font-family: Montserrat;
    font-weight: 400;
    src: url('${CONFIG.MARKETING_URL}assets/webfonts/Montserrat-Regular.woff')
        format('woff'),
      url('${CONFIG.MARKETING_URL}assets/webfonts/Montserrat-Regular.ttf')
        format('truetype');
    font-display: fallback;
  }

  @font-face {
    font-family: Montserrat;
    font-weight: 500;
    src: url('${CONFIG.MARKETING_URL}assets/webfonts/Montserrat-Medium.woff')
        format('woff'),
      url('${CONFIG.MARKETING_URL}assets/webfonts/Montserrat-Medium.ttf')
        format('truetype');
    font-display: fallback;
  }

  @font-face {
    font-family: Montserrat;
    font-weight: 600;
    src: url('${CONFIG.MARKETING_URL}assets/webfonts/Montserrat-SemiBold.woff')
        format('woff'),
      url('${CONFIG.MARKETING_URL}assets/webfonts/Montserrat-SemiBold.ttf')
        format('truetype');
    font-display: fallback;
  }

  @font-face {
    font-family: Montserrat;
    font-weight: 700;
    src: url('${CONFIG.MARKETING_URL}assets/webfonts/Montserrat-Bold.woff')
        format('woff'),
      url('${CONFIG.MARKETING_URL}assets/webfonts/Montserrat-Bold.ttf')
        format('truetype');
    font-display: fallback;
  }

  html {
    ${typography['Base/Body']}
    ${typography['Kollektif/Small']};
  }

  body {
    color: ${colors['Brand/PrimaryBlack']};
    -webkit-font-smoothing: antialiased;
    ${typography['Kollektif/Small']};
    ${spacing['Spacing/ZeroMargin']};
  }

  .kollektif-super {
    ${typography['Kollektif/Super']}
  }

  .kollektif-mega {
    ${typography['Kollektif/Mega']}
  }

  .kollektif-extra-large {
    ${typography['Kollektif/ExtraLarge']}
  }

  .kollektif-large {
    ${typography['Kollektif/Large']}
  }

  .kollektif-medium {
    ${typography['Kollektif/Medium']}
  }

  .kollektif-medium--emphasis {
    ${typography['Kollektif/MediumEmphasis']}
  }

  .kollektif-medium--underline {
    ${typography['Kollektif/MediumUnderline']}
  }

  .kollektif-small {
    ${typography['Kollektif/Small']}
  }

  .kollektif-small--emphasis {
    ${typography['Kollektif/SmallEmphasis']}
  }

  .kollektif-extra-small {
    ${typography['Kollektif/ExtraSmall']}
  }

  .kollektif-extra-small--emphasis {
    ${typography['Kollektif/ExtraSmallEmphasis']}
  }

  .kollektif-extra-small--underline {
    ${typography['Kollektif/ExtraSmallUnderline']}
  }

  .kollektif-tiny {
    ${typography['Kollektif/Tiny']}
  }

  .kollektif-tiny--emphasis {
    ${typography['Kollektif/TinyEmphasis']}
  }

  .kollektif-tiny--underline {
    ${typography['Kollektif/TinyUnderline']}
  }

  .montserrat-large {
    ${typography['Montserrat/Large']}
  }

  .montserrat-large--emphasis {
    ${typography['Montserrat/LargeEmphasis']}
  }

  .montserrat-medium {
    ${typography['Montserrat/Medium']}
  }

  .montserrat-medium--emphasis {
    ${typography['Montserrat/MediumEmphasis']}
  }

  .montserrat-medium--underline {
    ${typography['Montserrat/MediumUnderline']}
  }

  .montserrat-small {
    ${typography['Montserrat/Small']}
  }

  .montserrat-small--emphasis {
    ${typography['Montserrat/SmallEmphasis']}
  }

  .montserrat-small--underline {
    ${typography['Montserrat/SmallUnderline']}
  }
`

export { globalTypography }
