import React, { useEffect, useRef, useState } from 'react'

import { breakpoints, colors } from '@smarty-nx/ds'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Button from '../components/Button'
import LoadingDots from '../components/LoadingDots'
import PageHead from '../components/PageHead'
import SiteContainer from '../components/styles/SiteContainer'
import Spacer from '../components/styles/Spacer'
import { Paragraph, Title } from '../components/styles/Text'
import WifiCallingBanner from '../components/WifiCallingBanner'
import withApp from '../components/withApp/withApp'
import { CONFIG } from '../config'
import { theme } from '../constants/theme'
import ThreeDots from '../static/images/threeDots.svg'

const DECORATION_BREAKPOINT = 1200
const IFRAME_PADDING_SMALL = 15
const IFRAME_PADDING_LARGE = 30
const IFRAME_NATIVE_PADDING_BREAKPOINT = '(max-width: 414px)'
const IFRAME_URL = CONFIG.NETWORK_COVERAGE_CHECKER_LINK
const THREE_URL = CONFIG.THREE_URL

const HeadingMain = styled(Title)`
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 24px;
  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 40px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 48px;
    line-height: 52px;
  }
  @media (min-width: ${breakpoints.mobile}px) {
    font-size: 24px;
    margin-bottom: 16px;
  }
`

const HeroContainer = styled.div`
  max-width: 940px;
  margin: 0 auto;
  text-align: center;
  padding: 0 18px;
  position: relative;
  margin-bottom: ${IFRAME_PADDING_LARGE}px;
  @media (min-width: ${breakpoints.tablet}px) {
    margin-bottom: ${IFRAME_PADDING_SMALL}px;
  }
  @media ${IFRAME_NATIVE_PADDING_BREAKPOINT} {
    margin-bottom: 0;
  }

  &.wifi-banner {
    @media (min-width: ${breakpoints.mobile}px) {
      display: none;
    }
  }
`

const Heading = styled.p`
  font-family: ${theme.fonts.fontFamily};
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 485px;
`

const YellowText = styled.span`
  color: ${colors['Brand/Yellow']};
`

const LoadingState = styled.div`
  position: absolute;
  top: 16%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (min-width: ${breakpoints.tablet}px) {
    top: 10%;
  }
`
const Decoration = styled.img`
  position: absolute;
  height: 179px;
  width: 170px;
  top: 0;
  left: ${(props) => (props.left ? '0' : 'auto')};
  right: ${(props) => (!props.left ? '0' : 'auto')};
  transform: ${(props) => !props.left && 'rotateY(180deg)'};
  @media (max-width: ${DECORATION_BREAKPOINT}px) {
    display: none;
  }
`

const CoverageChecker = () => {
  const iframeRef = useRef()
  const [loaded, setLoaded] = useState(false)
  const [iframeHeight, setIframeHeight] = useState(440)
  const currentIframe = iframeRef.current

  useEffect(() => {
    if (currentIframe) {
      setLoaded(true)
    }
  }, [currentIframe])

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === THREE_URL) {
        setIframeHeight(event.data)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => window.removeEventListener('message', handleMessage)
  }, [])

  return (
    <>
      <HeroContainer className="wifi-banner">
        <WifiCallingBanner hasDarkBackground />
      </HeroContainer>
      <SiteContainer
        css={css`
          max-width: 100vw;
          position: relative;
          padding: 0;
          @media ${IFRAME_NATIVE_PADDING_BREAKPOINT} {
            padding-top: 0;
          }
        `}
      >
        {!loaded && (
          <LoadingState>
            <LoadingDots
              css={css`
                width: 80px;
              `}
            />
            <Paragraph>Loading coverage map</Paragraph>
          </LoadingState>
        )}
        <div
          loading="lazy"
          css={css`
            opacity: 0;
            visibility: hidden;
            transition: all 350ms cubic-bezier(0.65, 0.05, 0.36, 1);
            will-change: opacity, visibility;
            ${loaded &&
            `  opacity: 1;
           visibility: visible;`}
          `}
        >
          <Spacer
            size={0.5}
            css={css`
              top: -${IFRAME_PADDING_LARGE}px;
              left: 0;
              background: white;
              position: absolute;
              background: #424449;
              right: 0;
              z-index: -1;
              @media (min-width: ${breakpoints.tablet}px) {
                top: -${IFRAME_PADDING_SMALL}px;
              }
              @media ${IFRAME_NATIVE_PADDING_BREAKPOINT} {
                display: none;
              }
            `}
          />
          <iframe
            ref={iframeRef}
            title="coverage-checker"
            src={IFRAME_URL}
            height={iframeHeight}
            data-testid="coverage-checker-iframe"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            onLoad={() => setLoaded(true)}
            style={{
              width: '100%',
              border: 'none',
              margin: '0px auto',
              display: 'block',
              overflow: 'hidden',
              marginTop: 0,
              paddingTop: 0,
              marginBottom: 80,
            }}
          />
        </div>
      </SiteContainer>
      <HeroContainer>
        <Decoration src={ThreeDots} alt="" left />
        <HeadingMain>Reason to love SMARTY</HeadingMain>
        <Heading>
          With our network covering <YellowText>99.5%</YellowText> of the UK
          population (outdoor), you’re likely to be one of them.{' '}
        </Heading>
        <Button
          to="/all-plans"
          color={colors['Brand/PrimaryGreen']}
          customStyle={css`
            max-width: 288px;
            margin: 0 auto;
            border-radius: 8px;
          `}
        >
          Explore Our Plans
        </Button>
        <Spacer size={0.5} />
        <Decoration src={ThreeDots} alt="" />
      </HeroContainer>
    </>
  )
}

CoverageChecker.propTypes = {
  left: PropTypes.bool,
}

export default withApp(CoverageChecker)

export const Head = () => (
  <PageHead
    title="Coverage checker"
    description="SMARTY is a new SIM-only mobile network that’s built to be fair, transparent and smart."
  >
    <meta name="referrer" content="same-origin" />
  </PageHead>
)
