import React from 'react'

import Page from '../../../node_pages/page-template/generic-page'
import { usePagePreview } from '../_hooks'

function PagePreview({ id }: { id: string }) {
  const page = usePagePreview(id)

  // types comes from the gatsby, it's hard to satisfy all the prop checks
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Page data={{ page }} />
}

export default PagePreview
