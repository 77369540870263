import React, { forwardRef, ReactNode, useId } from 'react'

import cn from 'classnames'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide, SwiperProps } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'

import 'swiper/css'

import 'swiper/css/pagination'
import 'swiper/css/navigation'

import classes from './slider.module.scss'
import { Icon } from '..'
import { breakpoints } from '../../../index'

const removeStyleAttr = (swiper: SwiperClass) => {
  if (swiper && !swiper.params.enabled) {
    swiper.wrapperEl.removeAttribute('style')
    swiper.slides.forEach((slide) => slide.removeAttribute('style'))
  }
}

interface SliderProps {
  children: ReactNode[]
  options?: SwiperProps
  pagination?: boolean
  navigation?: boolean
  enabled?: boolean
  controlsClassName?: string
}

export const SliderOnMobile = forwardRef<SwiperRef, SliderProps>(
  (
    {
      children,
      pagination = true,
      navigation = true,
      controlsClassName,
      options: opts,
    },
    ref
  ) => {
    const controls = useId().replace(/:/g, '')
    const options: SwiperProps = {
      speed: 500,
      spaceBetween: 16,
      breakpoints: {
        0: {
          enabled: true,
          slidesPerView: 'auto',
          centeredSlides: true,
        },
        [breakpoints['desktop-small']]: {
          enabled: false,
        },
      },
      modules: [Pagination, Navigation],
      pagination: {
        clickable: true,
        el: `.${controls} .${classes.pagination}`,
      },
      navigation: {
        prevEl: `.${controls} .${classes.leftArrow}`,
        nextEl: `.${controls} .${classes.rightArrow}`,
      },
      ...opts,
      className: cn(classes.root, opts?.className),
    }

    return (
      <>
        <Swiper
          ref={ref}
          onInit={removeStyleAttr}
          onResize={removeStyleAttr}
          onBreakpoint={removeStyleAttr}
          {...options}
        >
          {children.map((child, i) => (
            <SwiperSlide key={i}>{child}</SwiperSlide>
          ))}
        </Swiper>

        {(pagination || navigation) && (
          <div className={cn(classes.controls, controls, controlsClassName)}>
            {navigation && (
              <Icon
                name="arrow"
                className={cn(classes.arrow, classes.leftArrow)}
              />
            )}

            {pagination && <div className={classes.pagination} />}

            {navigation && (
              <Icon
                name="arrow"
                className={cn(classes.arrow, classes.rightArrow)}
              />
            )}
          </div>
        )}
      </>
    )
  }
)
