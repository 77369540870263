import React, { FC } from 'react'

import { Button, ContentfulProcessor, Icon, Link } from '@smarty-nx/ds'
import cn from 'classnames'
import { Link as GatsbyLink } from 'gatsby'

import classes from './banner.module.scss'

interface BannerProps extends Queries.GenericBannerFragment {
  ltr?: boolean
  buttonLink?: boolean
}

export const Banner: FC<BannerProps> = ({
  title,
  description,
  image,
  link,
  ltr = false,
  buttonLink = true,
}) => (
  <div className={cn(classes.root, ltr && classes.ltr)}>
    <div className={classes.left}>
      <img src={image?.url || ''} alt={image?.title || ''} />
    </div>

    <div className={classes.right}>
      <h2 className={classes.header}>{title}</h2>
      <p className="margin--regular">
        <ContentfulProcessor content={description?.internal.content || ''} />
      </p>
      {link && (
        <ContentfulProcessor
          content={link.internal.content || ''}
          components={{
            a({ node: _, children, href, ...props }) {
              return buttonLink ? (
                <Button
                  {...(props as Record<string, unknown>)}
                  level="primary"
                  className={classes.ctaButton}
                  as={(props) => (
                    <Link href={href} props={props} component={GatsbyLink}>
                      {children}
                    </Link>
                  )}
                />
              ) : (
                <Link
                  className={classes.ctaLink}
                  href={href}
                  props={props}
                  component={GatsbyLink}
                >
                  {children}
                  <Icon
                    name="small-chevron-right"
                    className={classes.chevron}
                  />
                </Link>
              )
            },
          }}
        />
      )}
    </div>
  </div>
)
