import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 30px 10px;
  text-align: center;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    padding: 100px 20px;
  }

  h1 {
    font-size: 1.9rem;
    margin: 40px 0;

    @media (min-width: ${breakpoints['desktop-small']}px) {
      font-size: 3rem;
    }
  }

  p {
    margin: 15px 0;
  }
`

export const Image = styled.img`
  max-width: 80px;
  display: block;
  margin: 0 auto;
`
