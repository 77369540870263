import React from 'react'

import { breakpoints } from '@smarty-nx/ds'
import { css } from 'styled-components'

import { ContentContainer, HeadingSecondary, Text } from './styles'
import CheckImage from '../../static/images/checkmark.svg'
import {
  CheckIcon,
  Columns,
  HalfWidthColumn,
  List,
  ListItem,
} from '../styles/Lists'
import SiteContainer from '../styles/SiteContainer'
import Spacer from '../styles/Spacer'
import { HeadingTertiary } from '../styles/Text'
import TextLink from '../TextLink'

const ownerContent = [
  'Make all of the Group’s payments and manage all payment details',
  'See every member’s plan, usage, and call history starting from the day they join the Group',
  'Edit every member’s plan and purchase add-ons to their plan',
  'Remove any member from the Group and/or cancel the service for any member',
  'Add friends and family members to the group, up to a total of 8, including the group owner',
]

const memberContent = [
  'See their own plan, usage, and call history',
  'Edit their own personal details',
]

const bearInMindContent = [
  'Each Group can only have one owner and role cannot be transferred to another person.',
  'Group members won’t have access to any other group member’s account, unless they’re the group owner.',
  'Group members won’t have access to any payment related actions, such as buying add-ons or changing plans.',
  'Group members with an existing offer will start receiving the group discount once their offer expires. Group members with an existing offer that doesn’t expire will keep their offer and the group discount won’t be applied.',
]

const GroupHowItWorks = () => (
  <SiteContainer>
    <ContentContainer>
      <Spacer size={1} />

      <HeadingSecondary>How does it work?</HeadingSecondary>
      <Text
        css={css`
          margin-bottom: 40px;
          @media (min-width: ${breakpoints.desktop}px) {
            margin-bottom: 48px;
          }
        `}
      >
        Start a Group from your dashboard and become the group owner by adding a
        member. A Group can be just 2 plans up to a total of 8, and as soon as
        there are 2 plans in the Group you start saving. Any SMARTY customer who
        is at least 18 years old can start a Group.
      </Text>
      <Columns>
        <HalfWidthColumn>
          <HeadingTertiary green>Group owners can</HeadingTertiary>
          <List>
            {ownerContent.map((item, index) => (
              <ListItem key={index}>
                <CheckIcon src={CheckImage} alt="" />
                <Text>{item}</Text>
              </ListItem>
            ))}
          </List>
          <HeadingTertiary green>Group members can</HeadingTertiary>
          <List condense>
            {memberContent.map((item, index) => (
              <ListItem key={index}>
                <CheckIcon src={CheckImage} alt="" />
                <Text>{item}</Text>
              </ListItem>
            ))}
          </List>
        </HalfWidthColumn>
        <HalfWidthColumn condense>
          <HeadingTertiary green>Things to bear in mind</HeadingTertiary>
          <List>
            {bearInMindContent.map((item, index) => (
              <ListItem key={index}>
                <CheckIcon src={CheckImage} alt="" />
                <Text>{item}</Text>
              </ListItem>
            ))}
          </List>
          <HeadingTertiary>Need help?</HeadingTertiary>
          <Text>
            Visit our{' '}
            <TextLink href="https://help.smarty.co.uk/en/collections/1974243-smarty-groups">
              SMARTY Groups support pages
            </TextLink>
          </Text>
        </HalfWidthColumn>
      </Columns>
      <Spacer size={2} />
    </ContentContainer>
  </SiteContainer>
)

export default GroupHowItWorks
