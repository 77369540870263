import { CONFIG } from '../config'

export const RICH_DATA_STRINGS = {
  FAQ_ITEM_PROP: 'mainEntity',
  FAQ_ITEM_TYPE: 'https://schema.org/FAQPage',
  QUESTION_ITEM_TYPE: 'https://schema.org/Question',
  ANSWER_ITEM_TYPE: 'https://schema.org/Answer',
  ANSWER_ITEM_PROP: 'acceptedAnswer',
  TEXT_ITEM_PROP: 'text',
}

export const CHECKOUT_TYPES = {
  PAYPAL: 'paypal-checkout',
  REGULAR: 'regular-checkout',
  APPLE_PAY: 'apple-pay-checkout',
  GOOGLE_PAY: 'google-pay-checkout',
}

export const TRACKING_STRINGS = {
  ADD_TO_CART: 'product.interact.manual.click.addToCart',
  CART_LOAD: 'cart.load.auto.dataLayer.load',
  HERO_STRING: 'hero-view-our-plans',
  VIEW_PLANS: 'view-our-plans',
  MODULE_LOAD: 'module.load.auto.dataLayer.load',
  PRODUCT_VIEW: 'product.interact.manual.view',
  REGISTRATION_SUCCESS: 'user.interact.auto.success.regSuccess',
  SCREEN_LOAD: 'screen.load.auto.dataLayer.load',
  TRANSACTION_SUCCESS: 'transaction.load.auto.success.transactionSuccess',
  GENERAL_INFO: 'general-info',
  MODULE_CLICK: 'moduleItem.interact.manual.click',
}

export const TRACKING_CATEGORIES = {
  CONTACT: 'CO',
  LANDING_PAGE: 'LP',
  NAVIGATION: 'NV',
  USER_ACCOUNT: 'UA',
  GENERAL_INTERACTION: 'GI',
}

export const LOGO_GARDEN_TYPES = {
  DEFAULT: 'DEFAULT',
  WHICH: 'WHICH',
}

export const GLOBAL_STATUSES = {
  ACTIVE: 'active',
  AWAITING_NEW_SIM: 'awaiting_new_sim',
  AWAITING_NUMBER: 'awaiting_number',
  AWAITING_ORDER: 'awaiting_order',
  AWAITING_UNDELIVERED_SIM: 'awaiting_undelivered_sim',
  EXPIRED: 'expired',
  FAILED: 'failed',
  FULFILLED: 'fulfilled',
  NEW_SIM_ACTIVATION_REQUESTED: 'new_sim_activation_requested',
  NEW_SIM_REQUESTED: 'awaiting_new_sim',
  PAUSED: 'paused',
  PAYMENT_REQUIRED: 'payment-required',
  PENDING: 'pending',
  REQUESTED: 'requested',
  SUCCESS: 'success',
  SUSPENDED: 'suspended',
  TERMINATED: 'terminated',
}

export const PLAN_STATES = {
  active: GLOBAL_STATUSES.ACTIVE,
  expired: GLOBAL_STATUSES.EXPIRED,
  paymentRequired: GLOBAL_STATUSES.PAYMENT_REQUIRED,
  paused: GLOBAL_STATUSES.PAUSED,
  suspended: GLOBAL_STATUSES.SUSPENDED,
}

export const PAGE_CONSTANTS = {
  HOMEPAGE: {
    title: 'HomePage',
    testId: 'home-page',
    route: '/',
  },
  DASHBOARD: {
    title: 'Dashboard',
    testId: 'dashboard-container',
    route: '/dashboard',
  },
  CASH_ADDONS: {
    title: '',
    testId: 'cash-addon-container',
    route: '/dashboard/cash-addons',
  },
  CHANGE_PLAN: {
    title: 'Change plan',
    testId: 'change-plan-container',
    route: '/dashboard/change-plan',
  },
  CHANGE_PLAN_CONFIRMATION: {
    title: 'Confirm your plan change',
    testId: 'change-plan-confirmation-container',
    route: '/dashboard/change-plan',
  },
  RENEW_PLAN: {
    title: 'Renew plan',
    testId: 'renew-plan-container',
    route: '/dashboard/renew',
  },
  RENEW_PLAN_CONFIRMATION: {
    title: 'Renew your plan',
    testId: 'renew-plan-confirmation-container',
    route: '/dashboard/renew',
  },
  CONVERT_CASH_BALANCE: {
    title: '',
    testId: 'convert-cash-balance-container',
    route: '/dashboard/convert-cash-balance',
  },
  DATA_ADDONS: {
    title: '',
    testId: 'data-addon-container',
    route: '/dashboard/data-addons',
  },
  GROUPS: {
    route: '/dashboard/groups',
  },
  GROUPS_ADD_SIM: {
    title: 'Add a new SIM',
    route: '/dashboard/groups/data-sim',
  },
  GROUPS_MANAGE: {
    route: '/dashboard/groups/manage',
  },
  MANAGE_ADDONS: {
    title: 'Your add-ons',
    testId: 'manage-addon-container',
    route: '/dashboard/manage-add-ons',
  },
  PAYMENT: {
    title: 'Renew your plan',
    testId: '',
    route: '/dashboard/payment',
  },
  PAYMENT_SETTING: {
    title: 'Payment setting',
    testId: '',
    route: '/dashboard/settings/payment',
  },
  REFER_A_FRIEND: {
    title: '',
    testId: '',
    route: '/dashboard/referrals',
  },
  RENEW: {
    title: 'Renew your plan',
    testId: '',
    route: '/dashboard/renew',
  },
  SELECT_PLAN: {
    title: 'Select plan',
    testId: '',
    route: '/select-plan',
  },
  TOP_UP_HISTORY: {
    title: 'Top-up history',
    testId: 'top-up-history-container',
    route: '/dashboard/top-up-history',
  },
}

export const PLAN_CATEGORIES = {
  VOICE_SIM: 'voice-sim',
  DATA_DISCOUNT_SIM: 'data-discount-sim',
  DATA_ONLY_SIM: 'data-only-sim',
}

export const IMAGES_PATH = `${CONFIG.MARKETING_URL}assets/images`

export const TRUSTPILOT_BREAKPOINT = 534
