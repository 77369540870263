/* TODO: Remove this component along with Contentful node
(https://app.contentful.com/spaces/o5bu645mjc1y/environments/master/entries/GxNhb129Rw0g4H0ll5kNC?focusedField=title)
once RAE epic https://smarty-mobile.atlassian.net/browse/DIGDEV-8517 is live and REFER_AND_EARN FF is on
*/
import React, { FC } from 'react'

import { processVars } from '@smarty-nx/code-utils'
import { getRafPersonalisedBanner } from '@smarty-nx/contentful'
import { ContentfulProcessor } from '@smarty-nx/ds'

import { useBannerContent } from './hooks'
import {
  FluidBanner,
  FluidContentContainer,
  SmallContent,
  SmallCopy,
  SmallImage,
  Title,
} from './styles'

export const RafBanner: FC = () => {
  const bannerContent = useBannerContent()

  if (!bannerContent) {
    return null
  }
  const banner = getRafPersonalisedBanner()

  return (
    <FluidBanner>
      <FluidContentContainer>
        <SmallImage src={banner?.image?.url} alt="Sharing is caring" />

        <SmallCopy>
          <Title>
            <ContentfulProcessor
              content={processVars(banner?.title || '', {
                referralName: bannerContent?.first_name || '',
              })}
            />
          </Title>
          <SmallContent>
            <ContentfulProcessor content={banner?.description || ''} />
          </SmallContent>
        </SmallCopy>
      </FluidContentContainer>
    </FluidBanner>
  )
}
