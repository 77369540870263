import React from 'react'

import { colors } from '@smarty-nx/ds'
import { Parallax } from 'react-scroll-parallax'
import { css } from 'styled-components'

import {
  HeadingMain,
  HeroBackground,
  HeroContainer,
  SecondaryCta,
  SmileyFamily,
  SmileyFamilyParallax,
  SmileyLarge,
  SmileyMedium,
  SmileySmall,
} from './styles'
import { CONFIG } from '../../config'
import { useIsAuthenticated } from '../../hooks'
import DotsBackground from '../../static/images/groupDots.svg'
import SmileyFamilyGrouped from '../../static/images/groupSmileyFamily.svg'
import SmileyLeft from '../../static/images/groupSmileyLeft.svg'
import SmileyMiddle from '../../static/images/groupSmileyMiddle.svg'
import SmileyRight from '../../static/images/groupSmileyRight.svg'
import Button from '../Button'
import TextLink from '../TextLink'

const MAX_WIDTH = 288

const GroupHero = () => {
  const isAuthenticated = useIsAuthenticated()
  const url = isAuthenticated
    ? `${CONFIG.DASHBOARD_URL}groups/add-sim`
    : `${CONFIG.DASHBOARD_URL}groups`

  return (
    <HeroContainer>
      <HeroBackground src={DotsBackground} alt="Dots background" />

      <SmileyFamily src={SmileyFamilyGrouped} alt="" />

      <SmileyFamilyParallax>
        <Parallax x={[-20, 20]} tagOuter="figure">
          <SmileyLarge src={SmileyLeft} alt="" />
        </Parallax>
        <SmileySmall src={SmileyMiddle} alt="" />
        <Parallax x={[20, -20]} tagOuter="figure">
          <SmileyMedium src={SmileyRight} alt="" />
        </Parallax>
      </SmileyFamilyParallax>
      <HeadingMain>Save 10% on every SIM with a Group plan</HeadingMain>
      <Button
        href={url}
        data-testid="group-hero-cta"
        id="group-hero-cta"
        color={colors['Brand/PrimaryGreen']}
        customStyle={css`
          max-width: ${MAX_WIDTH}px;
          margin: 0 auto 16px;
        `}
      >
        Start a Group
      </Button>
      <SecondaryCta>
        New to SMARTY?{' '}
        <TextLink href="/select-plan">Choose a plan to start</TextLink>
      </SecondaryCta>
    </HeroContainer>
  )
}

export default GroupHero
