import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

export const HideOnMobile = styled.div`
  display: none;

  @media (min-width: ${breakpoints.tablet}px) {
    display: inherit;
  }
`

export const HideOnTablet = styled.div`
  display: none;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: ${(props) => (props.display ? props.display : 'inherit')};
  }
`

export const HideTabletUpwards = styled.div`
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const HideSmallDesktopUpwards = styled.div`
  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: none;
  }
`
