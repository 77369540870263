import React, { FC, ReactNode } from 'react'

import cn from 'classnames'

import classes from './typography.module.scss'
import { typography } from '../../typography.json'

export interface TypographyProps {
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div'
  variant: keyof typeof typography
  className?: string
  children: ReactNode
}

export const Typography: FC<TypographyProps> = ({
  component: Component = 'p',
  variant,
  className,
  ...props
}) => {
  const variantClassName = `font${String(variant).replace('/', '')}`
  return (
    <Component
      className={cn(classes[variantClassName], className)}
      {...props}
    />
  )
}
