import React from 'react'

import PropTypes from 'prop-types'

import {
  BottomLeftRoundelWrapper,
  BottomRightRoundelWrapper,
  RoundelImage,
  TopRightRoundelWrapper,
} from './styles'
import { IMAGES_PATH } from '../../constants/globalStrings'

const OfferRoundel = ({
  voucher,
  roundelSide,
  roundelPosition,
  isPlanCardSmall,
  usesYellowAssets,
}) => {
  const { code, roundel_type } = voucher

  // 3 cases at the mo.
  let RoundelWrapper
  switch (roundelPosition) {
    case 'left': {
      RoundelWrapper = BottomLeftRoundelWrapper
      break
    }

    case 'right': {
      RoundelWrapper = BottomRightRoundelWrapper
      break
    }

    // topRight
    default: {
      RoundelWrapper = TopRightRoundelWrapper
    }
  }

  let suffix
  let imageUrl
  if (roundel_type) {
    // Values get returned from Contentful with special characters and spaces so regex is to remove them (eg. 10% Off to 10-off)
    const type = roundel_type.replace(/[^A-Z0-9]+/gi, '-').toLowerCase()
    suffix = usesYellowAssets ? 'yellow' : 'teal'
    // The images are named with the side of the arrow so need to flip the side
    imageUrl = `${IMAGES_PATH}/voucher-roundels/${type}-${
      roundelSide === 'right' ? 'left' : 'right'
    }-${suffix}.svg`
  } else {
    // This is the old format where roundels where uploaded for each voucher
    // Keeping it around for now until we're sure it's no longer used
    suffix = usesYellowAssets ? '-yellow' : ''
    imageUrl = `${IMAGES_PATH}/voucher-roundels/${code}-${roundelSide}${suffix}.png`
  }

  return (
    <RoundelWrapper isPlanCardSmall={isPlanCardSmall}>
      <RoundelImage
        src={imageUrl}
        alt=""
        loading="lazy"
        data-testid="offer-roundel"
      />
    </RoundelWrapper>
  )
}

OfferRoundel.propTypes = {
  voucher: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  roundelSide: PropTypes.oneOf(['left', 'right']),
  roundelPosition: PropTypes.oneOf(['left', 'right', 'topRight']),
  isPlanCardSmall: PropTypes.bool,
  usesYellowAssets: PropTypes.bool,
}

export default OfferRoundel
