import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

export const TwoColumn = styled.div`
  display: flex;
  @media (max-width: ${breakpoints['tablet-landscape'] - 1}px) {
    flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: ${breakpoints['tablet-landscape'] - 1}px) {
      width: 100%;
    }
  }
`

export const ThreeColumn = styled.div`
  display: flex;
  @media (max-width: ${breakpoints['tablet-landscape'] - 1}px) {
    flex-direction: column;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 16px);
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: ${breakpoints['tablet-landscape'] - 1}px) {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
`

export const FourColumn = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints['tablet-landscape'] - 1}px) {
    justify-content: space-around;
  }

  @media (max-width: ${breakpoints['tablet'] - 1}px) {
    flex-direction: column;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: calc(25% - 16px);
    margin-right: 20px;
    box-sizing: border-box;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: ${breakpoints['tablet-landscape'] - 1}px) {
      width: calc(50% - 16px);
      margin-bottom: 1rem;
      margin-right: 0;
    }

    @media (max-width: ${breakpoints['tablet'] - 1}px) {
      width: 100%;
      margin-right: 0;
    }
  }
`
