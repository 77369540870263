import { getPlanCategory } from './getPlanCategory'
import { PlanData, PlanMeta } from './plans.types'

const voucherDmpgAttributes = (priceData, type) => {
  const { originalPrice, finalPriceWithoutCredit, voucher } = priceData
  const voucherAttributes = {}
  if (voucher) {
    voucherAttributes['data-' + type + '_discount_value_display-gross'] =
      (parseInt(originalPrice.value, 10) -
        parseInt(finalPriceWithoutCredit.value, 10)) *
      0.01
    voucherAttributes['data-' + type + '_discount_coupon'] = voucher.code
  }
  return voucherAttributes
}

export const orderDmpgAttributes = (planMeta, priceData, position: number) =>
  priceData && {
    'data-type': 'products',
    'data-position': position,
    'data-item-id': planMeta.slug,
    'data-asset_name': planMeta.slug,
    'data-asset_category': getPlanCategory(planMeta),
    'data-asset_value_charge-type': 'monthly',
    'data-asset_value_display-gross': priceData.finalPriceWithoutCredit.value,
    'data-asset_value_display-tax':
      priceData.finalPriceWithoutCredit.value * 0.2,
    ...voucherDmpgAttributes(priceData, 'asset'),
  }

export const productDmpgAttributes = (plan, priceData) =>
  priceData && {
    'data-product_id': plan.slug,
    'data-product_name': plan.slug,
    'data-product_category': getPlanCategory(plan),
    'data-product_value_charge-type': 'monthly',
    'data-product_value_display-gross': priceData.finalPriceWithoutCredit.value,
    'data-product_value_display-tax':
      priceData.finalPriceWithoutCredit.value * 0.2,
    ...voucherDmpgAttributes(priceData, 'product'),
  }

export const buildAssetsProductsProps = (plan: {
  planMeta: PlanMeta
  priceData: PlanData
}) => {
  return {
    products: [
      {
        id: plan.planMeta.slug,
        name: plan.planMeta.slug,
        category: getPlanCategory(plan.planMeta),
        value: {
          displayGross: plan.priceData.finalPriceWithoutCredit.value,
          displayTax: plan.priceData.finalPriceWithoutCredit.value * 0.2,
        },
        discount: {
          coupon: plan.priceData.voucher?.offer_chip_text,
          value: {
            displayGross: plan.priceData.discountAmount,
          },
        },
      },
    ],
  }
}
