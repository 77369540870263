import { useEffect, useState } from 'react'

import { updateItems, updateLink, updateTypename } from './_tools'
import api from '../../utils/api'

const getPageById = (id: string) => api.get(`/v1/contentful-preview/${id}`)

export const usePagePreview = (id: string) => {
  const [page, setPage] = useState<unknown | null>(null)

  useEffect(() => {
    getPageById(id)
      .then(({ data }) => {
        if ('data' in data && 'page' in data.data) {
          const { page } = data.data

          if (page) {
            page.sections = page.sectionsCollection.items
              .map(updateTypename)
              .map(updateLink)
              .map(updateItems)

            setPage(page)
          }
        }
      })
      .catch(() => void 0)
  }, [id])

  return page
}
