import React from 'react'

import { graphql } from 'gatsby'

import Homepage from '.'
import PageHead from '../components/PageHead'
import withApp from '../components/withApp/withApp'
import { RICH_DATA_STRINGS } from '../constants/globalStrings'

const FriendPage = (props) => <Homepage {...props} />

export default withApp(FriendPage)

export const Head = () => (
  <PageHead
    title={'Simple, honest mobile'}
    canonical={'https://smarty.co.uk'}
    itemType={RICH_DATA_STRINGS.FAQ_ITEM_TYPE}
  >
    <meta name="robots" content="noindex" />
  </PageHead>
)

export const query = graphql`
  query {
    contentfulPageSection(sectionName: { eq: "default-faq-list" }) {
      ...FaqSection
    }
  }
`
