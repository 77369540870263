import React, { ReactNode, FC, LiHTMLAttributes } from 'react'

import cn from 'classnames'

import classes from './list.module.scss'
import { Icon } from '../icon/icon'

interface ListProps {
  children?: ReactNode
  dataTestId?: string
  className?: string
  items?: ListItemProps[]
  showContainers?: boolean
  reverse?: boolean
  itemsIconId?: string
  spaceBetween?: boolean
  rounded?: boolean
}

export interface ListItemProps
  extends Omit<LiHTMLAttributes<HTMLLIElement>, 'content'> {
  children?: ReactNode
  content?: ReactNode
  subContent?: string
  iconId?: string
  dataTestId?: string
  reverse?: boolean
  spaceBetween?: boolean
  action?: () => void
  hide?: boolean
}

export const List: FC<ListProps> = ({
  children,
  items,
  dataTestId,
  className,
  itemsIconId,
  showContainers = false,
  reverse = false,
  spaceBetween = false,
  rounded = false,
}) => {
  return (
    <ul
      data-testid={dataTestId}
      className={cn(classes.list, className, {
        [classes.showItemContainers]: showContainers,
        [classes.reversed]: reverse,
        [classes.rounded]: rounded,
      })}
    >
      {children || null}
      {items?.map((el) => {
        return (
          el.content &&
          !el.hide && (
            <ListItem iconId={itemsIconId} spaceBetween={spaceBetween} {...el}>
              {el.content}
            </ListItem>
          )
        )
      })}
    </ul>
  )
}

export const ListItem: FC<ListItemProps> = ({
  children,
  iconId,
  dataTestId,
  subContent,
  reverse = false,
  spaceBetween = false,
  action,
  content,
  ...props
}) => {
  return (
    <li
      {...props}
      data-testid={dataTestId}
      className={cn({
        [classes.reversed]: reverse,
        [classes.spaceBetween]: spaceBetween,
        [classes.clickable]: action,
      })}
      onClick={action ? () => action() : undefined}
    >
      {iconId && <Icon className={classes.contentIco} name={iconId} />}
      <span className={classes.contentWrapper}>
        <span className={classes.content}>{children || content}</span>
        {subContent && <span className={classes.subContent}>{subContent}</span>}
      </span>
    </li>
  )
}
