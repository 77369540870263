import React from 'react'

import { ButtonOuter, ButtonText, LinkOuter } from './Button.styles'
import { ButtonProps } from './Button.types'

const Button = (props: ButtonProps) => {
  const {
    children,
    dataTestId = 'button',
    disabled = false,
    id,
    level,
    to,
  } = props

  return to ? (
    <LinkOuter
      data-testid={dataTestId}
      level={level}
      id={id}
      tabIndex={0}
      to={to}
    >
      <ButtonText data-testid={`${dataTestId}-text`}>{children}</ButtonText>
    </LinkOuter>
  ) : (
    <ButtonOuter
      data-testid={dataTestId}
      disabled={disabled}
      id={id}
      tabIndex={0}
      {...props}
    >
      <ButtonText data-testid={`${dataTestId}-text`}>{children}</ButtonText>
    </ButtonOuter>
  )
}

export default Button
