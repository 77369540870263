import React, { FC, forwardRef, ReactNode, useRef, useState } from 'react'

import {
  useInMediaRange,
  useRunCallbackOnEnteringViewport,
} from '@smarty-nx/code-utils'
import { Button, SliderOnMobile } from '@smarty-nx/ds'
import cn from 'classnames'
import { SwiperRef, SwiperProps } from 'swiper/react'

import { Details, Heading } from './_parts'
import classes from './plan-tiles-batch.module.scss'
import {
  CHECKOUT_TYPES,
  TRACKING_STRINGS,
} from '../../../constants/globalStrings'
import {
  triggerModuleClickEvent,
  triggerModuleLoadEvent2,
  triggerProductEvent,
} from '../../../utils/dataLayer'
import { buildAssetsProductsProps } from '../../../utils/dmpgAttributes'
import { PlanMeta, PlanData } from '../../../utils/plans.types'
import { PlanCardCompact } from '../index'

type Plan = {
  priceData: PlanData
  planMeta: PlanMeta
}

interface PlanTilesBatchProps {
  title: string
  plans: Plan[]
  subtitle?: string
  info?: ReactNode
  className?: string
  dataTestId: string
  moduleId: string
  moduleName: string
}

const getIndex = (plans: Plan[], plan: Plan | undefined): number => {
  return plans.findIndex(
    ({ planMeta }) => planMeta.slug === plan?.planMeta.slug
  )
}

const Carousel = forwardRef<
  SwiperRef,
  {
    children: ReactNode[]
    initialSlide: number
    onSlideChangeTransitionEnd(index: number): void
  }
>(({ children, initialSlide, onSlideChangeTransitionEnd }, ref) => {
  const options: SwiperProps = {
    loop: false,
    rewind: true,
    breakpoints: {},
    init: false,
    runCallbacksOnInit: false,
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 48,
    className: classes.slider,
    initialSlide,
    onSlideChangeTransitionEnd(swiper) {
      onSlideChangeTransitionEnd(swiper.activeIndex)
    },
  }

  return (
    <SliderOnMobile
      ref={ref}
      options={options}
      navigation={false}
      controlsClassName={classes.controls}
    >
      {children}
    </SliderOnMobile>
  )
})

Carousel.displayName = 'Carousel'

export const PlanTilesBatch: FC<PlanTilesBatchProps> = ({
  plans,
  title,
  subtitle,
  info,
  className,
  dataTestId,
  moduleId,
  moduleName,
}) => {
  const isMobile = useInMediaRange()
  const [activePlan, setActivePlan] = useState<Plan | undefined>()
  const selectedIndex = getIndex(plans, activePlan)
  const refCarousel = useRef<SwiperRef>(null)

  const ref = useRunCallbackOnEnteringViewport(() => {
    triggerModuleLoadEvent2(moduleId)
  })

  const setPlan = (shift: -1 | 1) => {
    if (isMobile) {
      if (shift === 1) {
        refCarousel.current?.swiper.slideNext()
      }

      if (shift === -1) {
        refCarousel.current?.swiper.slidePrev()
      }
    } else {
      const nextIndex = (selectedIndex + shift + plans.length) % plans.length
      setActivePlan(plans[nextIndex])
    }
  }

  const handlePlanClick = (plan: Plan) => {
    if (!isMobile || (isMobile && !activePlan)) {
      setActivePlan(plan)
      triggerModuleClickEvent(plan.planMeta.slug, 'products', moduleId)
    }

    ref.current?.scrollIntoView()
  }

  const renderPlans = () =>
    plans.map((plan, index) => (
      <PlanCardCompact
        position={index + 1}
        key={plan.planMeta.slug}
        onClick={() => handlePlanClick(plan)}
        planMeta={plan.planMeta}
        priceData={plan.priceData}
        isPlanDataOnly={plan.planMeta.plan_is_data_only}
        rootClassName={cn(
          classes.plan,
          activePlan?.planMeta.slug === plan.planMeta.slug && classes.active
        )}
      />
    ))

  const renderBuyButton = () => {
    if (!activePlan) {
      return null
    }

    return (
      <Button
        className={cn(isMobile && classes.mobileCta)}
        onClick={() =>
          triggerProductEvent(
            TRACKING_STRINGS.ADD_TO_CART,
            buildAssetsProductsProps(activePlan).products[0],
            CHECKOUT_TYPES.REGULAR
          )
        }
        as={(props) => (
          <a
            {...props}
            href={`/order/${activePlan.planMeta.slug}`}
            role="button"
            data-testid={`${activePlan.planMeta.slug}-card-link`}
          >
            Buy plan
          </a>
        )}
      />
    )
  }

  return (
    <div
      ref={plans.length > 0 ? ref : null}
      data-testid={dataTestId}
      className={cn(
        classes.root,
        className,
        !!activePlan && classes.hasDetails
      )}
      data-module-id={moduleId}
      data-module_name={moduleName}
      data-module_placement="plan-list"
    >
      {/* shown on desktop view only */}
      {!isMobile && (
        <Heading
          title={title}
          subtitle={subtitle}
          info={info}
          className={classes.headOuter}
        />
      )}

      {isMobile && activePlan && (
        <>
          {/*shown on mobile view only */}
          <Heading
            title={title}
            subtitle={subtitle}
            info={info}
            className={classes.headInner}
          />

          {plans.length > 0 && (
            <Carousel
              ref={refCarousel}
              initialSlide={selectedIndex}
              onSlideChangeTransitionEnd={(index) => {
                setActivePlan(plans[index])
              }}
            >
              {renderPlans()}
            </Carousel>
          )}

          {renderBuyButton()}
        </>
      )}

      {(!isMobile || (isMobile && !activePlan)) && (
        <div className={cn(classes.box, classes.grid)}>
          {/*shown on mobile view only */}
          {isMobile && (
            <Heading
              title={title}
              subtitle={subtitle}
              info={info}
              className={classes.headInner}
            />
          )}
          {renderPlans()}
        </div>
      )}

      {activePlan && (
        <Details
          className={classes.details}
          plan={activePlan}
          isMobile={isMobile}
          renderBuyButton={renderBuyButton}
          onClose={() => setActivePlan(undefined)}
          onPrev={() => setPlan(-1)}
          onNext={() => setPlan(1)}
        />
      )}
    </div>
  )
}
