import React, { FC } from 'react'

import { TooltipModal } from '../../../../components/Modals'
import {
  List,
  ListItem,
  ToolTipHeading,
  ToolTipPara,
} from '../../../../components/PlanSectionPhase2/styles'
import { CheckIcon } from '../../../../components/styles/Lists'
import CheckImage from '../../../../static/images/checkmark.svg'

export const VoiceInfoModal: FC = () => {
  return (
    <TooltipModal header="Voice plans" ariaHideApp={false}>
      <ToolTipHeading>
        You choice of data, with unlimited calls & texts. That’s simple and
        SMARTY
      </ToolTipHeading>
      <ToolTipHeading>
        As always, 1 month plans. No contracts, no credit checks and no
        surprises at the end of the month. Cancel or change plans anytime. Pay
        in advance and enjoy the flexibility.
      </ToolTipHeading>
    </TooltipModal>
  )
}

export const DataOnlyInfoModal: FC = () => {
  return (
    <TooltipModal header="Data Only plans" ariaHideApp={false}>
      <ToolTipHeading>
        No calls and texts, just great value data.
      </ToolTipHeading>
      <List>
        <ListItem>
          <CheckIcon src={CheckImage} alt="" />
          <ToolTipPara>
            Use data in the UK and roaming EU countries{' '}
          </ToolTipPara>
        </ListItem>
        <ListItem>
          <CheckIcon src={CheckImage} alt="" />
          <ToolTipPara>
            Unrestricted hotspotting and no speed limits
          </ToolTipPara>
        </ListItem>
        <ListItem>
          <CheckIcon src={CheckImage} alt="" />
          <ToolTipPara>No contract: 1 month plan</ToolTipPara>
        </ListItem>
      </List>
      <ToolTipHeading>
        <strong>Note: </strong>
        Data Only plans cannot be switched or upgraded to Voice plans or Money
        Back plans just yet.
      </ToolTipHeading>
    </TooltipModal>
  )
}

export const DataDiscountInfoModal: FC = () => {
  return (
    <TooltipModal header="Data Discount plans" ariaHideApp={false}>
      <ToolTipHeading>
        Get money back for unused data at the end of every month.
      </ToolTipHeading>
      <ToolTipHeading>
        Perfect for people who don’t use all their data and want to save money.
      </ToolTipHeading>
      <ToolTipHeading>
        The way it works is simple. The base cost of our data discount plans is
        £5, which covers unlimited standard calls, texts and running the
        network. The rest is simply for data. So we think it’s only fair to take
        the data you haven’t used and give you money back for it - at the same
        rate you bought it, £1 per GB.
      </ToolTipHeading>
      <ToolTipHeading>
        We even calculate using remaining MBs, not GBs.
      </ToolTipHeading>
      <ToolTipHeading>
        Any unused data credit gets used towards the amount you need to pay for
        your next month when you renew your plan.
      </ToolTipHeading>
    </TooltipModal>
  )
}
