import styled from 'styled-components'

import { theme } from '../../constants/theme'

const BlockContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: ${theme.sizes.blockWrapperWidth};
  padding: 0;
  align-self: center;
  box-sizing: border-box;
`

export default BlockContainer
