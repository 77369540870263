import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

import Button from '../Button'

const List = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: flex;
    justify-content: space-between;
  }
`

const ListItem = styled.li`
  margin-bottom: 1.5rem;
  display: flex;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: block;
    width: 30%;
    margin-bottom: 0;
  }

  img {
    position: relative;
    left: -5px;

    @media (min-width: ${breakpoints['desktop-small']}px) {
      left: 0;
    }
  }

  h3 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: 1.17;
  }

  p {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 1rem;
  }
`

const ButtonWrapper = styled.div`
  display: block;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: none;
  }
`

const StyledButton = styled(Button)`
  margin: 0 auto 16px;
`

const SecondaryCta = styled.p`
  align-self: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
`

export { List, ListItem, ButtonWrapper, StyledButton, SecondaryCta }
