import React, { useState } from 'react'

import algoliasearch from 'algoliasearch/lite'
import PropTypes from 'prop-types'
import { InstantSearch } from 'react-instantsearch-dom'

import {
  SearchResultDescription,
  SearchResultLink,
  SearchWrapper,
  StyledHighlight,
  StyledHits,
  StyledSearchBox,
} from './styles'
import { CONFIG } from '../../config'
import SearchIcon from '../../static/images/icons/search.svg'

// Uses React InstantSearch library for Algolia https://www.algolia.com/doc/guides/building-search-ui/what-is-instantsearch/react/
// Because they are all styled they are imported directly from ./styles
const algoliaClient = algoliasearch(
  CONFIG.ALGOLIA_APP_ID,
  CONFIG.ALGOLIA_APP_KEY
)

// To prevent empty search requests being sent on page load we wrap the AlgoliaClient in a proxy to enable additional logic
// https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react/
const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return undefined
    }
    return algoliaClient.search(requests)
  },
}

// A Hit is the imported component which provides a list of search results from Intercom (via Algolia)
const Hit = ({ hit }) => (
  <SearchResultLink href={hit.url} target="_blank">
    <StyledHighlight attribute="title" hit={hit} tagName="mark" />
    {hit.description != null && (
      <SearchResultDescription>{hit.description}</SearchResultDescription>
    )}
  </SearchResultLink>
)

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
}

const Search = () => {
  const [searchQuery, setSearchQuery] = useState('')

  return (
    <SearchWrapper>
      <InstantSearch searchClient={searchClient} indexName="Articles">
        {/* Some UI changes of the Searchbox are handled via props & we're using...
             - translations > to customise the placeholder text
             - submit > over riding the submit magnifying icon with our own SVG
             - reset > hiding the manual reset button (which clears the search) to match own designs
        */}
        <StyledSearchBox
          translations={{ placeholder: 'Search by keywords...' }}
          reset={false}
          submit={<img src={SearchIcon} alt="" />}
          onChange={(event) => {
            setSearchQuery(event.currentTarget.value)
          }}
        />
        {searchQuery !== '' && <StyledHits hitComponent={Hit} />}
      </InstantSearch>
    </SearchWrapper>
  )
}

export default Search
