import { colors } from '@smarty-nx/ds'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { Level } from './Button.types'
import dsTheme from '../../constants/design-system/theme.json'

const { defaults, typography } = dsTheme

interface ButtonProps {
  level: Level
}

const PrimaryButton = css`
  background-color: ${colors['Brand/PrimaryGreen']};
  color: ${colors['Brand/PrimaryBlack']};

  &:hover,
  &:focus {
    background-color: ${colors['Brand/PalePrimaryGreen']};
  }
`

const SecondaryButton = css`
  background-color: ${colors['Brand/PalePrimaryGreen']};
  color: ${colors['Brand/PrimaryBlack']};

  &:hover,
  &:focus {
    background-color: ${colors['Brand/PrimaryGreen']};
  }
`

const TertiaryButton = css`
  background-color: ${colors['UI/LightestGrey']};
  border: 1px solid ${colors['UI/Grey']};
  color: ${colors['Brand/PrimaryBlack']};

  &:hover,
  &:focus {
    background-color: ${colors['UI/LightGrey']};
  }
`

const QuaternaryButton = css`
  background-color: ${colors['UI/DarkestGrey']};
  color: ${colors['UI/White']};

  &:hover,
  &:focus {
    background-color: ${colors['UI/DarkestGrey']};
  }
`

const QuinaryButton = css`
  background-color: ${colors['Brand/PrimaryBlack']};
  color: ${colors['UI/White']};

  &:hover,
  &:focus {
    background-color: ${colors['Brand/PrimaryBlack']};
  }
`

const LinkButton = css`
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const UnstyledLinkButton = css`
  background-color: transparent;
  color: inherit;
  flex: initial;
  justify-content: flex-start;
  text-decoration: underline;
  width: auto;
  span {
    text-align: left;
  }
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const DisabledButton = css`
  background-color: ${colors['UI/LightestGrey']};
  color: ${colors['UI/MediumGrey']};
  cursor: default;
  pointer-events: none;

  &:hover,
  &:focus {
    cursor: default;
  }
`

const getStyles = (level: Level) => {
  switch (level) {
    case Level.Primary:
      return PrimaryButton
    case Level.Secondary:
      return SecondaryButton
    case Level.Tertiary:
      return TertiaryButton
    case Level.Quaternary:
      return QuaternaryButton
    case Level.Quinary:
      return QuinaryButton
    case Level.Link:
      return LinkButton
    case Level.UnstyledLink:
      return UnstyledLinkButton
    default:
      return PrimaryButton
  }
}

const buttonCss = css`
  ${typography['Kollektif/ExtraSmallEmphasis']};
  ${defaults['Transition/hover']};
  align-items: center;
  border: none;
  border-radius: ${defaults['Borders/radius-large']};
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 2.5rem;
  text-decoration: none;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 2px solid ${colors['Brand/Orange']};
  }
`

const ButtonOuter = styled.button<ButtonProps>`
  ${buttonCss};
  ${(props) => getStyles(props.level)};
  ${(props) => props.disabled && DisabledButton};
  padding: ${(props) =>
    props.level === Level.Link || props.level === Level.UnstyledLink
      ? '0'
      : '1rem'};
`

const LinkOuter = styled(Link)<ButtonProps>`
  ${buttonCss};
  ${(props) => getStyles(props.level)};
  padding: ${(props) =>
    props.level === Level.Link || props.level === Level.UnstyledLink
      ? '0'
      : '1rem'};
`

const ButtonText = styled.span`
  text-align: center;
  width: 100%;
`

export { ButtonOuter, ButtonText, LinkOuter }
