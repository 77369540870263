import React, { FC } from 'react'

import cn from 'classnames'

import classes from './alert-circle.module.scss'
import { Icon } from '..'

declare module 'react' {
  interface CSSProperties {
    '--alert-circle-color'?: string
  }
}

interface CheckmarkProps {
  className?: string
  color?: string
}

export const AlertCircle: FC<CheckmarkProps> = ({ className, color }) => {
  return (
    <Icon
      name="alert-circle"
      className={cn(classes.alertCircle, className)}
      style={{ '--alert-circle-color': color }}
    />
  )
}
