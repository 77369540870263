import React, { FC } from 'react'

import { Button, ContentfulProcessor } from '@smarty-nx/ds'
import { Link as GatsbyLink } from 'gatsby-link'

import classes from './quote.module.scss'
import { isBanner } from '../../../content-type-checks'

export const Quote: FC<Queries.GenericPageSectionFragment> = (section) => {
  return (
    <>
      {(section.items || []).filter(isBanner).map((item) => (
        <div key={item.id} className={classes.root}>
          {item.image?.url && (
            <img
              className={classes.image}
              src={item.image?.url || ''}
              alt={item.image?.title || ''}
            />
          )}
          <p className={classes.title}>
            <ContentfulProcessor content={item.title} />
          </p>
          {item.description?.internal.content && (
            <div className={classes.description}>
              <ContentfulProcessor
                content={item.description.internal.content}
              />
            </div>
          )}
          {item.link?.internal.content && (
            <ContentfulProcessor
              content={item.link.internal.content}
              components={{
                a({ node: _, href, ...props }) {
                  return (
                    <Button
                      {...(props as Record<string, unknown>)}
                      as={(props) => <GatsbyLink {...props} to={href || ''} />}
                      className={classes.button}
                    />
                  )
                },
              }}
            />
          )}
        </div>
      ))}
    </>
  )
}
