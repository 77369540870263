import React from 'react'

import { breakpoints, colors } from '@smarty-nx/ds'
import PropTypes from 'prop-types'
import { css } from 'styled-components'

import {
  BenefitsWrapper,
  ContentWrapper,
  Heading,
  Image,
  ImageWrapper,
  reverseLeftStyles,
  reverseRightStyles,
  SubHeading,
} from './Hero.styles'
import Button from '../../components/Button'
import {
  CheckIcon,
  ListItem,
  ListParagraphSmall,
} from '../../components/styles/Lists'
import TextLink from '../../components/TextLink'
import {
  TRACKING_CATEGORIES,
  TRACKING_STRINGS,
} from '../../constants/globalStrings'
import CheckImage from '../../static/images/checkmark.svg'
import FiftyFifty from '../FiftyFifty'

const Hero = ({
  heading,
  subHeading,
  subTitle,
  subLink,
  sublinkSuffix,
  benefits,
  CTA,
  CTALink,
  image,
  imageUrl,
  hideButton,
  imageAlt = '',
  custStyle,
  ...props
}) => (
  <FiftyFifty
    custStyle={custStyle}
    {...props}
    css={css`
      padding: 24px 0;
      overflow: hidden;
      @media (min-width: ${breakpoints.desktop}px) {
        padding: ${(props) => (props.custStyle ? '2.5rem 0' : '3rem 0')};
      }
    `}
    leftStyles={css`
      ${reverseLeftStyles};
      @media (min-width: ${breakpoints.desktop}px) {
        padding-right: 20px;
      }
    `}
    rightStyles={reverseRightStyles}
  >
    <ContentWrapper>
      <Heading custStyle={custStyle}>{heading}</Heading>
      {subHeading && (
        <SubHeading>
          {subHeading}
          {subLink && (
            <React.Fragment>
              <TextLink href={subLink}>{subTitle}</TextLink>
              {sublinkSuffix}
            </React.Fragment>
          )}
        </SubHeading>
      )}
      <BenefitsWrapper custStyle={custStyle}>
        {benefits.map((item, index) => (
          <ListItem key={index}>
            <CheckIcon src={CheckImage} alt="" />
            <ListParagraphSmall>{item}</ListParagraphSmall>
          </ListItem>
        ))}
      </BenefitsWrapper>
      {!hideButton && (
        <Button
          href={CTALink}
          color={colors['Brand/PrimaryGreen']}
          data-item-category={TRACKING_CATEGORIES.GENERAL_INTERACTION}
          data-item-name={TRACKING_STRINGS.HERO_STRING}
          custStyle={custStyle}
        >
          {CTA}
        </Button>
      )}
    </ContentWrapper>
    {image || (
      <ImageWrapper custStyle={custStyle}>
        <Image
          css={css`
      background-size: contain;
      }
    `}
          alt={imageAlt}
          imageUrl={imageUrl}
        />
      </ImageWrapper>
    )}
  </FiftyFifty>
)

Hero.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  benefits: PropTypes.node,
  CTA: PropTypes.string,
  CTALink: PropTypes.string,
  subLink: PropTypes.string,
  sublinkSuffix: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  imageUrl: PropTypes.string,
  hideButton: PropTypes.bool,
  custStyle: PropTypes.bool,
}

export default Hero
