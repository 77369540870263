import React from 'react'

import { roamingPageBanners } from '@smarty-nx/contentful'

import { FlexWrapper, Headline, Link, Root } from './RoamingTopBanner.styles'

const RoamingTopBanner = ({ countrySlug }: { countrySlug: string }) => {
  const bannerContent = roamingPageBanners.find(
    ({ country }) => country === countrySlug
  )

  if (bannerContent && bannerContent.enabled) {
    return (
      <Root bgColor={bannerContent.backgroundColor}>
        <FlexWrapper>
          <Headline data-testid="roaming-banner-headline">
            {bannerContent.headline}
          </Headline>

          <Link
            href={bannerContent.ctaLink}
            data-testid="roaming-banner-link"
            rel="noopener noreferrer"
            target="_blank"
            aria-label={`${bannerContent.ctaText} link opens in a new window`}
          >
            {bannerContent.ctaText}
          </Link>
        </FlexWrapper>
      </Root>
    )
  }
  return null
}

export default RoamingTopBanner
