import { breakpoints } from '@smarty-nx/ds'
import styled, { css } from 'styled-components'

import { Title } from '../styles/Text'

const HERO_BREAKPOINT = 762

const HeroContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 40px 18px 18px;
  z-index: 2;
  position: relative;
  @media (min-width: ${breakpoints.desktop}px) {
    padding: 40px 0 18px;
  }
`

const SecondaryCta = styled.p`
  font-size: 14px;
  line-height: 1.42;
  font-weight: 700;
  align-self: center;
  margin-bottom: 48px;
  text-align: center;
  @media (min-width: ${breakpoints.tablet}px) {
    margin-bottom: 32px;
  }
`

const HeadingMain = styled(Title)`
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.17;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 2.875rem;
    line-height: 1.17;
  }
`

const HeroBackground = styled.img`
  width: 314px;
  height: 130px;
  padding-top: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  display: none;

  @media (min-width: ${HERO_BREAKPOINT}px) {
    display: block;
  }
`

const SmileyLarge = styled.img`
  height: 114px;
  width: 106px;
`

const SmileySmall = styled.img`
  height: 87px;
  width: 78px;
  position: relative;
  top: -20px;
`

const SmileyMedium = styled.img`
  height: 111px;
  width: 100px;
  position: relative;
  top: 15px;
  left: -10px;
`

const sharedLogoStyles = css`
  position: absolute;
  top: 13%;
  left: 0;
  right: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`

const SmileyFamily = styled.img`
  margin: 0 auto 1.5rem;
  width: 214px;
  height: 100px;

  @media (min-width: ${HERO_BREAKPOINT}px) {
    display: none;
  }
`
const SmileyFamilyParallax = styled.div`
  ${sharedLogoStyles};
  width: 90%;
  margin: 0 auto;
  .parallax-outer {
    &:first-child {
      z-index: 1;
    }
    position: relative;
    z-index: 2;
    margin: 0;
  }
  @media (max-width: 761px) {
    display: none;
  }
`

export {
  HeroContainer,
  SecondaryCta,
  HeadingMain,
  HeroBackground,
  SmileyLarge,
  SmileySmall,
  SmileyMedium,
  SmileyFamily,
  SmileyFamilyParallax,
}
