import { colors } from '@smarty-nx/ds'
import styled, { css } from 'styled-components'

export const HamburgerWrapper = styled.button`
  height: ${(props) => props.theme.sizes.navbarHeight};
  width: ${(props) => props.theme.sizes.navbarHeight};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  cursor: pointer;
  position: relative;
  margin: 0;
  padding: 0;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  background-color: ${colors['Brand/PrimaryBlack']};
  color: ${colors['UI/White']};
  border: 0;
  text-align: left;

  ${(props) =>
    props.open &&
    css`
      background-color: ${colors['Brand/PrimaryGreen']};
      color: ${colors['UI/DarkGrey']};
    `};

  @media (min-width: 900px) {
    display: none;
  }
`

export const Inner = styled.span`
  margin-top: 6px;
  &,
  &::before,
  &::after {
    width: 25px;
    height: 2px;
    background-color: ${(props) =>
      props.open ? colors['UI/DarkGrey'] : colors['UI/White']};
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: -6px;
  }

  &::after {
    bottom: -6px;
  }

  ${(props) =>
    props.open &&
    css`
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
      }

      &::after {
        transition: bottom 0.075s 0.12s ease,
          transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }

      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease,
          transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    `}
`

export const Box = styled.span`
  width: 25px;
  height: 14px;
  display: block;
`
