import React from 'react'

import { TRACKING_CATEGORIES } from '../constants/globalStrings'

// https://blog.mailtrap.io/email-obfuscation/
// dataItemName for tracking clicks
export const obfuscateEmail = (
  user,
  site,
  dataItemName = null,
  dataTestId = null
) => (
  <a
    data-item-category={TRACKING_CATEGORIES.CONTACT}
    data-item-name={dataItemName}
    data-testid={dataTestId || dataItemName}
    href={`mailto:${user}@${site}`}
  >
    {user}@{site}
  </a>
)
