import React from 'react'

import { breakpoints, colors } from '@smarty-nx/ds'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Card from '../components/Card/Card'
import ChatDetails from '../components/ChatDetails'
import ChristmasOpeningHours from '../components/ChristmasOpeningHours'
import EllipseCutOut from '../components/EllipseCutOut'
import HelpTopics from '../components/HelpTopics/HelpTopics'
import PageHead from '../components/PageHead'
import Search from '../components/Search'
import FullWidthContainer from '../components/styles/FullWidthContainer'
import Padding from '../components/styles/Padding'
import SiteContainer from '../components/styles/SiteContainer'
import withApp from '../components/withApp/withApp'
import { FEATURES } from '../config'
import {
  RICH_DATA_STRINGS,
  TRACKING_CATEGORIES,
} from '../constants/globalStrings'
import FaqSection from '../modular-system/FaqSection'
import { obfuscateEmail } from '../utils/obfuscateEmail'

const styledContainer = css`
  position: relative;
  overflow: hidden;
`

const styledTitle = css`
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 2rem;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    margin-top: 3rem;
    margin-bottom: 3.5rem;
  }
`

const styledSubtitle = css`
  margin-bottom: 1.5rem;
`

const styledH2 = css`
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`

const styledHeading = css`
  margin-bottom: 1rem;
`

const styledCard = css`
  position: relative;
  z-index: 10;
`

const EmailContact = styled.p`
  font-size: 14px;
  line-height: 20px;

  small {
    display: block;
    font-size: 12px;
    line-height: 18px;
  }

  a {
    text-decoration: none;
  }
`

const Contact = ({
  data: {
    contentfulPageSection: { header, items, link },
  },
}) => (
  <>
    <FullWidthContainer
      css={styledContainer}
      background={colors['Brand/Yellow']}
      color={colors['Brand/PrimaryBlack']}
    >
      <EllipseCutOut color={colors['UI/DarkGrey']} isTopCutout />
      <SiteContainer>
        <h1 css={styledTitle} data-testid="contact-page-title">
          {contactContent.title}
        </h1>

        <Card css={styledCard}>
          <HelpTopics />
          <h2 css={styledH2} data-testid="search-title">
            {contactContent.searchTitle}
          </h2>
          <Search />
        </Card>

        <Padding size={2.5} sizeDesktop={3.5} />
        <FaqSection darkText header={header} items={items} link={link} />
        <Padding size={2.5} sizeDesktop={3.5} />

        <Card>
          <h2 css={styledHeading} data-testid="other-ways-title">
            {contactContent.chat.title}
          </h2>
          <ChatDetails />
          {contactContent.emailContact}
        </Card>
        {FEATURES.CHRISTMAS_OPENING_HOURS && (
          <>
            <Padding size={2.5} sizeDesktop={3.5} />
            <ChristmasOpeningHours />
          </>
        )}
        <Padding size={2.5} sizeDesktop={3.5} />
      </SiteContainer>
    </FullWidthContainer>

    <FullWidthContainer
      css={styledContainer}
      background={colors['UI/DarkGrey']}
    >
      <Padding size={2.5} sizeDesktop={3.5} />
      <SiteContainer>
        <h2 css={styledSubtitle} data-testid="pr-title">
          {contactContent.PR.title}
        </h2>
        {contactContent.PR.text}
      </SiteContainer>
      <Padding size={2.5} sizeDesktop={3.5} />
    </FullWidthContainer>

    <FullWidthContainer
      css={styledContainer}
      background={colors['UI/White']}
      color={colors['Brand/PrimaryBlack']}
    >
      <Padding size={2.5} sizeDesktop={3.5} />
      <SiteContainer>
        <h2 css={styledSubtitle} data-testid="complaints-title">
          {contactContent.complaints.title}
        </h2>
        {contactContent.complaints.text}
      </SiteContainer>
      <Padding size={2.5} sizeDesktop={3.5} />
    </FullWidthContainer>
  </>
)

const contactContent = {
  title: 'Contact us',
  seo: {
    title: 'Contact us - FAQ',
    descripton: '',
  },
  emailContact: (
    <EmailContact data-testid="support-info-text">
      Alternatively, you can email us at{' '}
      {obfuscateEmail('team', 'smarty.co.uk', 'smarty-email-link')}
      <small>The team normally respond within a day</small>
    </EmailContact>
  ),
  // obfuscateEmail() now takes a third param for a data-item-name (for tracking)
  PR: {
    title: 'PR and Media Enquiries',
    text: (
      <p data-testid="pr-text">
        If you’d like to talk to our press team about anything, please contact
        us at:
        <br />
        {obfuscateEmail(
          'SMARTYmedia',
          'smarts.agency',
          'pr-media-enquiries-link'
        )}
      </p>
    ),
  },
  searchTitle: 'Or search help articles',
  chat: {
    title: 'How to get more help',
  },
  complaints: {
    title: 'Making a complaint',
    text: (
      <p data-testid="complaints-text">
        If you need to make a complaint, please initially use our web chat to
        see if we can help.
        <br />
        To escalate an issue, please fill in a complaints form by going here:
        <br />
        <a
          href="/complaints"
          data-item-category={TRACKING_CATEGORIES.NAVIGATION}
          data-item-name="complaints-link"
          data-testid="complaints-link"
        >
          smarty.co.uk/complaints
        </a>
      </p>
    ),
  },
}

export default withApp(Contact)

export const Head = () => (
  <PageHead
    title={contactContent.seo.title}
    description={contactContent.seo.description}
    itemType={RICH_DATA_STRINGS.FAQ_ITEM_TYPE}
  />
)

Contact.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query {
    contentfulPageSection(sectionName: { eq: "contact-faq-list" }) {
      ...FaqSection
    }
  }
`
