import React, { FC } from 'react'

import classes from './bubbles.module.scss'

export const Bubbles: FC<{ colorLight: string }> = ({ colorLight }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 641 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.root}
    >
      <circle
        cx="3"
        cy="3"
        transform="matrix(.99928 -.03781 -.03642 -.99934 602.219 55.223)"
        fill="#68696A"
        r="3"
      />
      <circle
        cx="3"
        cy="3"
        transform="matrix(.99928 -.03781 -.03642 -.99934 87.219 29.223)"
        fill="#68696A"
        r="3"
      />
      <circle
        opacity=".5"
        cx="3"
        cy="3"
        transform="matrix(.99928 -.03781 -.03642 -.99934 .219 67.223)"
        fill="#68696A"
        r="3"
      />
      <circle
        cx="5"
        cy="5"
        r="5"
        transform="matrix(.99928 -.03781 -.03642 -.99934 127.363 68.371)"
        fill={colorLight}
      />
      <circle
        cx="5"
        cy="5"
        r="5"
        transform="matrix(.99928 -.03781 -.03642 -.99934 319.363 68.371)"
        fill={colorLight}
      />
      <circle
        cx="5"
        cy="5"
        r="5"
        transform="matrix(.99928 -.03781 -.03642 -.99934 524.363 29.371)"
        fill={colorLight}
      />
      <circle
        cx="5"
        cy="5"
        r="5"
        transform="matrix(.99928 -.03781 -.03642 -.99934 5.363 15.371)"
        fill={colorLight}
      />
      <circle
        cx="5"
        cy="5"
        r="5"
        transform="matrix(.99928 -.03781 -.03642 -.99934 247.363 5.371)"
        fill={colorLight}
      />
      <circle
        cx="5"
        cy="5"
        r="5"
        transform="matrix(.99928 -.03781 -.03642 -.99934 630.363 5.371)"
        fill={colorLight}
      />
      <circle
        cx="3"
        cy="3"
        transform="matrix(.99928 -.03781 -.03642 -.99934 405.219 5.222)"
        fill="#68696A"
        r="3"
      />
    </svg>
  )
}
