import React, { FC } from 'react'

import { ContentfulProcessor } from '@smarty-nx/ds'

import classes from './hero.module.scss'
import { ContentfulLink } from '../../../../components/contentful-link'
import { isBanner } from '../../../content-type-checks'

export const HeroSection: FC<
  Queries.GenericPageSectionFragment & { index: number }
> = (section) => {
  const item = section.items && section.items.filter(isBanner)[0]

  if (!item) {
    return null
  }

  return (
    <div className={classes.pageBanner}>
      <img
        className={classes.image}
        src={item.image?.url || ''}
        alt={item.image?.title || ''}
      />
      <div className={classes.pageBannerContent}>
        {section.index === 0 ? (
          <h1 className={classes.title}>{item.title}</h1>
        ) : (
          <h2 className={classes.title}>{item.title}</h2>
        )}
        <ContentfulProcessor
          content={item.description?.internal.content || ''}
          components={{ p: 'p' }}
        />
        <ContentfulLink
          link={item.link?.internal.content || ''}
          className={classes.ctaButton}
        />
      </div>
    </div>
  )
}
