import React, { FC } from 'react'

import { ContentfulProcessor } from '@smarty-nx/ds'

import classes from './FaqBanner.module.scss'

interface FaqBannerProps {
  banner: Queries.GenericBannerFragment
}

const FaqBanner: FC<FaqBannerProps> = ({
  banner: { link, description, image },
}) => (
  <div className={classes.banner}>
    {image && (
      <img
        alt={image.title || ''}
        src={image.url || ''}
        className={classes.image}
        data-testid="simple-banner-image"
      />
    )}
    <div className={classes.textContainer}>
      <h2 className={classes.title} data-testid="simple-banner-title">
        <ContentfulProcessor content={link?.internal.content || ''} />
      </h2>
      <p
        className={classes.description}
        data-testid="simple-banner-description"
      >
        <ContentfulProcessor content={description?.internal.content || ''} />
      </p>
    </div>
  </div>
)

export default FaqBanner
