import React from 'react'

import { getPlanBySlug } from '@smarty-nx/contentful'
import cn from 'classnames'
import Countdown from 'react-countdown'

import { countdownBannerContent } from './CountdownBanner.content'
import classes from './CountdownBanner.module.scss'
import { CountdownBannerProps } from './CountdownBanner.types'
import { timeFormat } from '../../utils/timeFormat'

const getNewDataAllowanceOrName = (
  planName,
  dataAllowanceGB,
  dataAllowanceWithPromosGB
) => {
  if (dataAllowanceGB > 0 && dataAllowanceGB === dataAllowanceWithPromosGB) {
    return dataAllowanceGB + 'GB'
  }

  if (dataAllowanceGB > 0 && dataAllowanceWithPromosGB !== dataAllowanceGB) {
    return dataAllowanceWithPromosGB + 'GB'
  }

  return planName
}

const CountdownBanner = ({
  planName,
  planSlug,
  voucherExpiryDate,
  discountedPrice,
  originalPrice,
  dataAllowanceGB,
  dataAllowanceWithPromosGB,
}: CountdownBannerProps) => {
  const planMeta = getPlanBySlug(planSlug)
  if (!planMeta) {
    return null
  }

  const { slug, planColor } = planMeta

  const dataAllowanceOrName = getNewDataAllowanceOrName(
    planName,
    dataAllowanceGB,
    dataAllowanceWithPromosGB
  )
  const oldDataAllowance =
    dataAllowanceGB > 0 && dataAllowanceWithPromosGB !== dataAllowanceGB
      ? dataAllowanceGB + 'GB'
      : null

  const price =
    originalPrice === discountedPrice ? originalPrice : discountedPrice
  const oldPrice = originalPrice !== discountedPrice && !oldDataAllowance ? originalPrice : null

  return (
    <a
      className={cn(
        classes.root,
        classes[`backgroundColour${planColor?.theme.replace('/', '')}`]
      )}
      href={`/order/${slug}`}
    >
      <div className={classes.wrapper}>
        <div className={classes.dataAndPriceSection}>
          <div>
            <span className={classes.dataOrName}>
              {dataAllowanceOrName}
            </span>
          </div>
          <div className={classes.dataAndPriceSecondLine}>
            {oldDataAllowance && (
              <span className={classes.originalData}>{oldDataAllowance}</span>
            )}
            {oldPrice && (
              <span className={classes.originalPrice}> £{oldPrice}</span>
            )}
            <span className={classes.price}>£{price}</span>
          </div>
        </div>

        <div className={classes.timeSection}>
          {countdownBannerContent.durations.map((d) => (
            <div className={classes.time} key={d.time}>
              <Countdown
                date={voucherExpiryDate}
                renderer={(props) => (
                  <div className={classes.timeColumn}>
                    <div className={classes.timeNumbers}>
                      <p className={classes.timeNumber}>
                        {timeFormat(props[d.time])[0]}
                      </p>
                      <p className={classes.timeNumber}>
                        {timeFormat(props[d.time])[1]}
                      </p>
                    </div>
                    <p className={classes.timeLegend}>{d.text_to_display}</p>
                  </div>
                )}
              />
            </div>
          ))}
        </div>
      </div>
    </a>
  )
}

export default CountdownBanner
