import { useEffect, useState } from 'react'

import api from '../../assets/api'

const BUYAPOWA_KEY = 'bp_e'

interface ReferrerTexts {
  content: string
  first_name?: string
  image_src: string
  title: string
}

interface ReferrerResponse {
  id: string
  data: {
    attributes: {
      primary: ReferrerTexts
      secondary: ReferrerTexts
    }
  }
}

export const getUrlBuyapowaCode = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get(BUYAPOWA_KEY)
  // Param values can resolve to empty strings, so lets be consistent and return null
  if (code === null || code === '') {
    return null
  } else {
    // Removing bp_e query parameter from the search
    window?.history.replaceState({}, document.title, window.location.origin)
  }

  return code
}

const getFriend = async (code: string) => {
  try {
    const response = await api.get(`/v1/findFriend?friendToken=${code}`)
    const { data } = response.data
    const { customer_id, voucher_code } = data.attributes

    window.localStorage.setItem('referrer_voucher_code', voucher_code)
    window.localStorage.setItem('referrer_customer_id', customer_id)
  } catch (e) {
    // Do nothing.
  }
}

const getContent = async (): Promise<ReferrerTexts | undefined> => {
  try {
    const referrerId = window.localStorage.getItem('referrer_customer_id')

    // Fails silently e.g. users that never use a RAF link
    if (referrerId) {
      const response = await api.get<ReferrerResponse>('/v1/referrer', {
        params: { referrer_id: referrerId },
      })
      const { data } = response.data
      const { attributes } = data

      return attributes['primary']
    }
  } catch (e) {
    // Do nothing
  }
}

export const useReferralData = () => {
  const [referralData, setReferralData] = useState<ReferrerTexts | undefined>()

  useEffect(() => {
    const buyapowaCode = getUrlBuyapowaCode()

    if (buyapowaCode) {
      getFriend(buyapowaCode).then(getContent).then(setReferralData)
    } else {
      getContent().then(setReferralData)
    }
  }, [])

  return referralData
}
