import { useEffect, useState } from 'react'

import { useUserJwt } from './use-is-authenticated'
import api from '../utils/api'

async function fetchReferralLink(jwt: string): Promise<string | undefined> {
  try {
    const {
      data: { data },
    } = await api.get<{
      data: { attributes: { referral_url: string } }
    }>(`/v1/users/referral_link`, {
      headers: {
        Authorization: `Bearer: ${jwt}`,
      },
    })

    return data.attributes.referral_url
  } catch (_) {
    // do nothing
  }
}

export const useReferralLink = () => {
  const jwt = useUserJwt()
  const [referralLink, setReferralLink] = useState<string | undefined>()

  useEffect(() => {
    if (jwt) {
      fetchReferralLink(jwt).then(setReferralLink)
    }
  }, [jwt])

  return referralLink
}
