import { useEffect } from 'react'

interface UseRefreshTokenProps {
  expiry: number | null
  hardExpiry: number | null
  jwt: string | undefined
  refreshToken: () => void
  timeoutMargin: number
  enabled?: boolean
}

export const useRefreshToken = ({
  expiry,
  jwt,
  refreshToken,
  timeoutMargin,
  enabled = true,
  hardExpiry,
}: UseRefreshTokenProps) => {
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    // Don't run if it's in iframe (checking by window.self === window.top)
    if (
      expiry &&
      hardExpiry &&
      jwt &&
      window.self === window.top &&
      enabled &&
      hardExpiry > expiry
    ) {
      const timeToRefresh = expiry - Date.now() - timeoutMargin
      const calculatedTimeout = timeToRefresh > 0 ? timeToRefresh : 0
      timeout = setTimeout(async () => {
        clearTimeout(timeout)
        await refreshToken()
      }, calculatedTimeout)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [refreshToken, expiry, jwt, timeoutMargin, enabled, hardExpiry])
}
