export * from './app-download/app-download'
export * from './awards/awards'
export * from './all-plans-heading/all-plans-heading'
export * from './herobanner/herobanner'
export * from './how-it-works/how-it-works'
export * from './plan-batches/plan-batches'
export * from './popular-plans/popular-plans'
export * from './reasons-to-choose/reasons-to-choose'
export * from './trust-pilot/trust-pilot'
export * from './what-we-offer/what-we-offer'
export * from './you-might-be-interested/you-might-be-interested'
