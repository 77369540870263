import React, { FC } from 'react'

import { ContentfulProcessor } from '@smarty-nx/ds'

import classes from './coverage.module.scss'
import TextLink from '../../../../components/TextLink'

export const Coverage: FC<Queries.GenericBannerFragment> = ({
  description,
  image,
  link,
}) => (
  <div className={classes.root}>
    <div className={classes.statementWrapper}>
      <ContentfulProcessor
        content={description?.internal.content || ''}
        components={{
          h3({ node: _, children, ...props }) {
            return (
              <h3 className={classes.heading} {...props}>
                {children}
              </h3>
            )
          },
        }}
      />
      <img
        src={image?.url || ''}
        className={classes.imgLogo}
        alt="Three"
        loading="lazy"
        data-testid="three-logo"
      />
    </div>
    <p data-testid="coverage-text" className={classes.smallParagraph}>
      <ContentfulProcessor
        content={link?.internal.content || ''}
        components={{
          a({ node: _, children, href, ...props }) {
            return (
              <TextLink to={href} {...props}>
                {children}
              </TextLink>
            )
          },
        }}
      />
    </p>
  </div>
)
