import React from 'react'

import { Button } from '@smarty-nx/ds'
import { Link } from 'gatsby'

import classes from './less-malarkey.module.scss'
import EllipseCutOut from '../../components/EllipseCutOut'
import PageHead from '../../components/PageHead'
import withApp from '../../components/withApp/withApp'
import { CONFIG } from '../../config'
import { TRACKING_CATEGORIES } from '../../constants/globalStrings'
import logo from '../../static/images/less-malarkey/logo.gif'

const embedYouTubeURL = 'https://www.youtube-nocookie.com/embed/euoPD3DODTo'

const LessMalarkey = () => {
  return (
    <div className={classes.lessMalarkey}>
      <div className={classes.topBanner}>
        <div className={classes.topBannerContent}>
          <h1>
            Less Malarkey
            <br />
            More SMARTY
          </h1>
          <p className="margin--regular kollektif-medium">
            At SMARTY, we don’t believe in needless complexity. That’s why we
            offer SIM only plans with no contract, no annual price rises, 5G and
            EU Roaming (up to 12GB) included at no extra cost, all with
            unlimited calls and texts.
          </p>
        </div>
        <EllipseCutOut className={classes.cutOut} />
      </div>

      <div className={classes.videoSection} id="video">
        <iframe
          src={embedYouTubeURL}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      </div>

      <div className={classes.bottomBanner}>
        <p className="margin--regular kollektif-small">
          We believe in brilliant simplicity and strive to call out the
          Malarkey. No matter what we do, we’ll always ask, is there a simpler
          way? In our new TV advert, we challenge those moments of everyday
          Malarkey.
        </p>
        <img src={logo} alt="" />
        <h2>Check out our plans</h2>
        <Button
          data-item-category={TRACKING_CATEGORIES.NAVIGATION}
          data-item-name="goto-homepage-plans"
          as={(props) => (
            <Link
              {...props}
              to={`${CONFIG.MARKETING_URL}#plans`}
              target="_blank"
            >
              View all plans
            </Link>
          )}
        />
      </div>
    </div>
  )
}

export default withApp(LessMalarkey)

export const Head = () => (
  <PageHead
    title="Less Malarkey More SMARTY"
    description="Less Malarkey More SMARTY"
  />
)
