import exitIntentOverlays from '../../data/exitIntentOverlaysEntries.json'
import wcmatch from 'wildcard-match'

const getExitIntentOverlays = () => {
  if (typeof exitIntentOverlays === 'undefined') {
    return []
  } else {
    return exitIntentOverlays
  }
}

interface ExitIntentOverlay {
  title: string
  asset: Asset
  displayedOnPaths: string[]
}

interface Asset {
  title: string
  description: string
  link: string
  image: {
    title: string
    url: string
  }
}

export const getOverlaysBy = (path: string) => {
  const results: Asset[] = []
  getExitIntentOverlays().map((overlay: ExitIntentOverlay) => {
    const isMatch = wcmatch(overlay.displayedOnPaths)
    if (isMatch(path)) {
      results.push(overlay.asset)
    }
  })
  return results
}
