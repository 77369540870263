import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

import dsTheme from '../../constants/design-system/theme.json'

const { typography } = dsTheme

const PlansSectionContainer = styled.div`
  padding: 2rem 0 0;
`

const PlansSectionHeading = styled.h2`
  ${typography['Kollektif/Large']};
  margin-bottom: 2rem;

  @media (min-width: ${breakpoints.tablet}px) {
    ${typography['Kollektif/ExtraLarge']};
    padding-left: 0.5rem;
  }
`

const HeadingSecondary = styled.h2`
  ${typography['Kollektif/Large']};
  margin-bottom: 0.5rem;
  padding: 0 1rem 0 0;

  @media (min-width: ${breakpoints.tablet}px) {
    ${typography['Kollektif/ExtraLarge']};
    padding: 0 1rem 0 0.5rem;
  }
`

const HeadingSecondarySmall = styled.h2`
  ${typography['Kollektif/Medium']};
  font-weight: 400;
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints.tablet}px) {
    ${typography['Kollektif/Large']};
    font-weight: 400;
    padding: 0 0 0 0.5rem;
  }
`

const HeadingWithCoverage = styled.div`
  display: flex;
  justify-content: space-between;
`

const PlansWrapper = styled.div`
  padding-bottom: 2rem;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const MainHeadingWrapper = styled.div`
  display: flex;
`

const HeadingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  align-items: flex-start;
  display: flex;
  margin: 0 0 1rem;
`

const ToolTipHeading = styled.p`
  ${typography['Kollektif/ExtraSmall']};
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints.tablet}px) {
    ${typography['Kollektif/Small']};
  }
`

const ToolTipPara = styled.p`
  ${typography['Kollektif/ExtraSmall']};
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export {
  HeadingSecondary,
  HeadingSecondarySmall,
  HeadingsWrapper,
  HeadingWithCoverage,
  List,
  ListItem,
  MainHeadingWrapper,
  PlansSectionContainer,
  PlansSectionHeading,
  PlansWrapper,
  ToolTipHeading,
  ToolTipPara,
}
