import React, { useState } from 'react'

import PropTypes from 'prop-types'

import {
  AccordionArrow,
  AccordionButton,
  AccordionContent,
  AccordionWrapper,
} from './FaqAccordion.styles'
import {
  RICH_DATA_STRINGS,
  TRACKING_CATEGORIES,
} from '../../constants/globalStrings'
import DarkDropdown from '../../static/images/dark-dropdown.svg'
import Dropdown from '../../static/images/dropdown.svg'

const FaqAccordion = ({
  children,
  title,
  id,
  dataTestId,
  dataItemName,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => setIsOpen(!isOpen)

  return (
    /* itemScope, itemProp, itemType are Google Microdata attributes */
    <AccordionWrapper
      id={id}
      itemScope
      itemProp={RICH_DATA_STRINGS.FAQ_ITEM_PROP}
      itemType={RICH_DATA_STRINGS.QUESTION_ITEM_TYPE}
      {...props}
    >
      <AccordionButton
        onClick={handleClick}
        aria-expanded={isOpen}
        aria-controls={id}
        id={`label-${id}`}
        darkText={props.darkText}
        /*
          these are for our click events
          data-item-category and data-item-name are both scraped from the DOM
        */
        data-item-category={TRACKING_CATEGORIES.NAVIGATION}
        data-item-name={dataItemName}
      >
        <h3 data-testid={dataTestId} itemProp="name">
          {title}
        </h3>
        <AccordionArrow isOpen={isOpen}>
          <img src={props.darkText ? DarkDropdown : Dropdown} alt="" />
        </AccordionArrow>
      </AccordionButton>
      <AccordionContent
        id={id}
        aria-labelledby={`label-${id}`}
        hidden={!isOpen}
        /* Google Microdata attributes */
        itemScope
        itemProp={RICH_DATA_STRINGS.ANSWER_ITEM_PROP}
        itemType={RICH_DATA_STRINGS.ANSWER_ITEM_TYPE}
      >
        <div itemProp={RICH_DATA_STRINGS.TEXT_ITEM_PROP}>{children}</div>
      </AccordionContent>
    </AccordionWrapper>
  )
}

FaqAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  dataItemName: PropTypes.string,
  darkText: PropTypes.bool,
}

FaqAccordion.defaultProps = {
  darkText: false,
}

export default FaqAccordion
