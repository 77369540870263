import React from 'react'

import { TextLinkWrapper } from './TextLink.styles'
import renderLinkType from '../../utils/renderLink'

type TextLinkTypes = {
  ariaLabel?: string
  children: React.ReactNode
  className?: string
  eventName?: string
  eventProductId?: string
  href?: string
  newTab?: boolean
  rel?: string
  to?: string
  target?: string
  dataTestId?: string
}

const TextLink = ({
  href,
  to,
  children,
  eventName,
  eventProductId,
  newTab,
  className,
  ariaLabel,
  dataTestId = 'text-link',
  ...props
}: TextLinkTypes) => (
  <TextLinkWrapper
    to={to}
    as={renderLinkType({ href, to })}
    href={href}
    data-dmpg-event-name={eventName && eventName}
    data-event_product_id={eventProductId && eventProductId}
    target={newTab ? '_blank' : undefined}
    rel={newTab ? 'noopener noreferrer' : undefined}
    className={className}
    aria-label={ariaLabel}
    data-testid={dataTestId}
    {...props}
  >
    {children}
  </TextLinkWrapper>
)

export default TextLink
