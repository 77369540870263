import React from 'react'

import { graphql, Script, useStaticQuery } from 'gatsby'

import { CONFIG } from '../../config'

const defaultDescription =
  'SMARTY is a new SIM-only mobile network that’s built to be fair, transparent and smart.'

type HeadProps = {
  description?: string
  lang?: string
  keywords?: string[]
  title: string
  canonical?: string
  children?: React.ReactNode
  /* itemScope, itemType: Google structured data attributes */
  itemScope?: boolean
  itemType?: string | undefined
}

const PageHead = ({
  description = defaultDescription,
  lang = 'en',
  keywords = [],
  title,
  canonical,
  children,
  /* itemScope, itemProp, itemType are Google Microdata attributes */
  itemType,
}: HeadProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const meta = [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:image',
      content: '/assets/images/smarty_logo.png',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: site.siteMetadata.author,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:image',
      content: '/assets/images/smarty_logo.png',
    },
    {
      'http-equiv': 'x-ua-compatible',
      content: 'ie-edge',
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
    },
  ].concat(
    keywords.length > 0
      ? {
          name: 'keywords',
          content: keywords.join(', '),
        }
      : []
  )

  return (
    <>
      <html lang={lang} itemScope itemType={itemType} />
      <title>
        {title} | {site.siteMetadata.title}
      </title>
      <link
        rel="shortcut icon"
        sizes="16x16 32x32"
        type="image/png"
        href={`${CONFIG.MARKETING_URL}favicon.png`}
      />
      <link
        rel="apple-touch-icon"
        href={`${CONFIG.MARKETING_URL}apple-touch-icon.png`}
      />
      {canonical && <link rel="canonical" href={canonical} />}
      <link
        rel="sitemap"
        type="application/xml"
        href={`${CONFIG.MARKETING_URL}sitemap.xml`}
      />
      <Script
        src="https://www.dwin1.com/10933.js"
        type="text/javascript"
        defer
      />
      {meta.map((item, index) => (
        <meta
          key={index}
          name={item.name}
          property={item.property}
          content={item.content}
          httpEquiv={item['http-equiv']}
        />
      ))}
      {children}
    </>
  )
}

export default PageHead
