import React, { FC } from 'react'

import { ContentfulProcessor } from '@smarty-nx/ds'

import classes from './two-tiles.module.scss'
import { isBanner } from '../../../content-type-checks'

export const TwoTiles: FC<Queries.GenericPageSectionFragment> = (section) => (
  <div className={classes.root}>
    {(section.items || []).filter(isBanner).map((item) => (
      <div key={item.id} className={classes.item}>
        <div
          style={{ backgroundImage: `url(${item.image?.url})` }}
          className={classes.image}
        />
        <div className={classes.content}>
          <p className={classes.title}>
            <ContentfulProcessor content={item.title} />
          </p>
          {item.description?.internal.content && (
            <div>
              <ContentfulProcessor
                content={item.description.internal.content}
              />
            </div>
          )}
        </div>
      </div>
    ))}
  </div>
)
