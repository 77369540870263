import React from 'react'

import { getChristmasOpeningHoursBanner } from '@smarty-nx/contentful'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import classes from './ChristmasOpeningHours.module.scss'
import Card from '../Card/Card'

const bannerData = getChristmasOpeningHoursBanner()

const ChristmasOpeningHours = () => {
  if (!bannerData) {
    return null
  }

  return (
    <Card className={classes.darkCard}>
      <h2 className={classes.heading} data-testid="christmas-opening-title">
        {bannerData.title}
      </h2>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          p({ node: _, ...props }) {
            return <p className={classes.description} {...props} />
          },
          table({ node: _, ...props }) {
            return <table className={classes.hoursTable} {...props} />
          },
        }}
      >
        {bannerData.description || ''}
      </ReactMarkdown>
    </Card>
  )
}

export default ChristmasOpeningHours
