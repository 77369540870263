import React from 'react'

import PageHead from '../components/PageHead'
import PageNotFoundMessage from '../components/PageNotFoundMessage'

const PageNotFound = () => <PageNotFoundMessage />

export default PageNotFound

export const Head = () => <PageHead title="404 Page not found" />
