import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

export const Banner = styled.div.attrs({ 'data-testid': 'fluid-banner' })`
  display: flex;
  justify-content: flex-start;
  color: #000;
  flex-direction: column;
  background: #fff;
  margin: 20px 0 0 0;
  padding: 18px;

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
  }

  @media (min-width: ${breakpoints['desktop-small']}px) {
    margin: 40px 0 12px 0;
  }
`

export const FluidBanner = styled(Banner)`
  box-sizing: border-box;
  justify-content: center;
  margin-top: 0;

  padding: 0 24px;
  text-align: left;
  ${(props) =>
    props.isMarketingBanner &&
    `
    max-width: 817px;
    margin-left: -18px;
    margin-right: -18px;
  `}

  @media (min-width: ${breakpoints.tablet}px) {
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: ${breakpoints['desktop-small']}px) {
    padding: 0 10px;
    margin-top: ${(props) => (props.isMarketingBanner ? '40px' : '0')};
  }
`

export const FluidContentContainer = styled.div`
  max-width: 940px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0;
`

export const SmallCopy = styled.div`
  margin: 0 15px;
  color: #333539;
`

export const SmallImage = styled.img.attrs({
  'data-testid': 'fluid-banner-image',
})`
  width: 220px;
  height: 190px;
  object-fit: contain;
  align-self: center;
  width: auto;
  max-height: 60px;
`

export const Title = styled.p.attrs({ 'data-testid': 'fluid-banner-title' })`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin: 0;
`

const Content = styled.p.attrs((props) => ({
  'data-testid': props.testid || 'fluid-banner-content',
}))`
  line-height: 20px;
  margin: 18px 0;
`

export const SmallContent = styled(Content)`
  font-size: 12px;
  line-height: 18px;
  margin: 5px 0 0;

  a {
    font-size: 12px !important;
    line-height: 18px;
  }
`
