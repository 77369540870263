import React, { FC } from 'react'

import { Button, ContentfulProcessor } from '@smarty-nx/ds'

import classes from './columns.module.scss'
import { isBanner } from '../../../content-type-checks'

export const Columns: FC<Queries.GenericPageSectionFragment> = (section) => (
  <>
    {section.header && <h2 className={classes.header}>{section.header}</h2>}
    <div className={classes.container}>
      {section.items?.filter(isBanner).map((item) => (
        <div className={classes.item} key={item.id}>
          <img
            src={item.image?.url || ''}
            alt={item.image?.title || ''}
            className={classes.image}
          />
          <p className={classes.title}>{item.title}</p>
          {item.description?.internal.content && (
            <div className={classes.description}>
              <ContentfulProcessor
                content={item.description.internal.content}
              />
            </div>
          )}
          {item.link?.internal.content && (
            <ContentfulProcessor
              content={item.link?.internal.content || ''}
              components={{
                a({ node: _, children, href, ...props }) {
                  return (
                    <Button
                      level="link"
                      {...(props as Record<string, unknown>)}
                      as={(props) => (
                        <a href={href} {...props} className={classes.link}>
                          {children}
                        </a>
                      )}
                    />
                  )
                },
              }}
            />
          )}
        </div>
      ))}
    </div>
  </>
)
