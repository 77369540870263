import React, { FC } from 'react'

import { Link } from '@smarty-nx/ds'
import { Link as GatsbyLink } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

import FaqAccordion from '../FaqAccordion'

const markdownComponents = {
  a({ node: _, children, ...props }) {
    const { href, ...rest } = props
    return (
      <Link href={href} props={rest} component={GatsbyLink}>
        {children}
      </Link>
    )
  },
  p({ node: _, ...props }) {
    return <p className="margin--regular" {...props} />
  },
}

interface FaqItemProps extends Queries.FaqFragment {
  darkText?: boolean
}

const FaqItem: FC<FaqItemProps> = ({
  faqTitle,
  faqId,
  faqContent,
  faqDataItemName,
  darkText = false,
  faqLink,
}) => (
  <FaqAccordion
    title={faqTitle}
    id={faqId}
    dataTestId={`${faqId}-faq-title`}
    darkText={darkText}
    // data-item-name is passed down to FaqAccordion
    dataItemName={faqDataItemName}
  >
    <span data-testid={`${faqId}-faq-text`}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={markdownComponents}
      >
        {faqContent?.internal?.content || ''}
      </ReactMarkdown>
    </span>
    {faqLink && (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={markdownComponents}
      >
        {faqLink?.internal?.content || ''}
      </ReactMarkdown>
    )}
  </FaqAccordion>
)

export default FaqItem
