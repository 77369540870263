import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import App from './App'
import { CONFIG } from '../../config'
import { AppDispatch } from '../../state/createStore'
import { fetchGroupPlans } from '../../state/groupPlans/group-plans.slice'
import { fetchPlans } from '../../state/plans/plans.slice'
import { RootState } from '../../state/rootReducer'
import { setVoucherCode } from '../../state/shared/shared.slice'

export type AppProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = ({
  smartySharedData: { voucherCode },
  plans,
  groupPlans,
  promotion: { promotion },
  dashboard,
}: RootState) => ({
  voucherCode,
  plans,
  groupPlans,
  promo: promotion,
  planState: dashboard.data?.planStatus,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      setVoucherCode,
      fetchPlans,
      fetchGroupPlans,
    },
    dispatch
  )

const SyncStateFromDashboard = () => {
  useEffect(() => {
    const onMessage = (event) => {
      if (
        event.origin !== CONFIG.DASHBOARD_URL.replace(/\/$/, '') ||
        !event.data.smarty
      ) {
        return
      }

      Object.entries(event.data.smarty).forEach(([key, value]) => {
        window.localStorage.setItem(key, value as string)
      })
    }

    window.addEventListener('message', onMessage, false)

    return () => {
      window.removeEventListener('message', onMessage, false)
    }
  }, [])

  return null
}

const withApp = (Component) => {
  const addApp = (props: AppProps) => (
    <App {...props}>
      {CONFIG.SMARTY_ENV === 'localhost' && <SyncStateFromDashboard />}

      <Component {...props} />
    </App>
  )
  return connect(mapStateToProps, mapDispatchToProps)(addApp)
}

export default withApp
