import React from 'react'

import PropTypes from 'prop-types'

import { LeftColumn, RightColumn, Root } from './FiftyFifty.styles'

/*
  ImageText is a design system component - small refers to image size
  https://www.figma.com/file/JRJ2xtZ27FkTiR6GEvt0yuDd/%E2%9D%96-SMARTY-Design-System?node-id=4426%3A71
*/

const FiftyFifty = ({
  children,
  imageTextSmall,
  inverse,
  leftStyles,
  rightStyles,
  customProps,
  custStyle,
  custStyleProps,
  ...props
}) => (
  <Root
    inverse={inverse}
    imageTextSmall={imageTextSmall}
    customProps={customProps}
    custStyleProps={custStyleProps}
    custStyle={custStyle}
    {...props}
  >
    <LeftColumn
      leftStyles={leftStyles}
      imageTextSmall={imageTextSmall}
      inverse={inverse}
      customProps={customProps}
      custStyleProps={custStyleProps}
    >
      {children[0]}
    </LeftColumn>
    <RightColumn
      rightStyles={rightStyles}
      imageTextSmall={imageTextSmall}
      customProps={customProps}
      custStyle={custStyle}
    >
      {children[1]}
    </RightColumn>
  </Root>
)

export default FiftyFifty

FiftyFifty.propTypes = {
  children: PropTypes.array.isRequired,
  leftStyles: PropTypes.array,
  rightStyles: PropTypes.array,
  // see above Figma link
  imageTextSmall: PropTypes.bool,
  inverse: PropTypes.bool,
  custStyle: PropTypes.bool,
  custStyleProps: PropTypes.bool,
  customProps: PropTypes.bool,
}
