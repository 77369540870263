import { breakpoints, colors } from '@smarty-nx/ds'
import { Highlight, Hits, SearchBox } from 'react-instantsearch-dom'
import styled from 'styled-components'
import 'instantsearch.css/themes/reset.css'

import dsTheme from '../../constants/design-system/theme.json'

const { typography } = dsTheme

export const SearchWrapper = styled.div`
  background-color: white;
`

export const SearchResultLink = styled.a`
  ${typography['Kollektif/SmallEmphasis']}
  text-decoration: none;
`

export const SearchResultDescription = styled.p`
  ${typography['Kollektif/Small']};
  color: ${colors['Brand/PrimaryBlack']};
`

export const StyledHighlight = styled(Highlight)`
  .ais-Highlight-nonHighlighted {
    color: ${colors['Brand/PrimaryBlack']};
  }

  .ais-Highlight-highlighted {
    background-color: ${colors['Brand/PaleBlue']};
  }
`

export const StyledSearchBox = styled(SearchBox)`
  .ais-SearchBox-form {
    display: flex;
    justify-content: center;
    margin-right: 0.5rem;
    margin-left: -0.5rem;

    input[type='search'] {
      width: 100%;
      border: 1px solid ${colors['UI/Grey']};
      border-radius: 3px;
      font-size: 14px;
      line-height: 20px;
      padding: 15px;
    }
    input[type='search']:focus {
      border-color: ${colors['Brand/PaleBlue']};
      box-shadow: 0px 0px 4px rgba(0, 108, 255, 0.214278);
      outline: none;
    }
    button[type='submit'] {
      margin-left: -2.5rem;
    }

    button[type='submit']:focus {
      border-color: ${colors['Brand/PaleBlue']};
      box-shadow: 0px 0px 4px rgba(0, 108, 255, 0.214278);
      outline: none;
    }

    @media (min-width: ${breakpoints['desktop-small']}px) {
      .ais-SearchBox-form {
        margin-right: 1rem;
        margin-left: -1rem;
      }
      button[type='submit'] {
        margin-left: -3rem;
      }
    }
  }
`

export const StyledHits = styled(Hits)`
  .ais-Hits-list {
    padding: 0;
  }

  .ais-Hits-item {
    padding: 16px 0;
    border-bottom: 1px solid ${colors['Brand/PrimaryBlack']};
  }

  .ais-Hits-item:last-child {
    border-bottom: none;
    padding-bottom: 40px;
  }

  @media (min-width: ${breakpoints['desktop-small']}px) {
    .ais-Hits-list {
      margin: 0 3rem 0 2rem;
    }
  }
`
