const init = () => {
  return {
    boot: ({ postponed = true, ...config }) => {
      const intercomLoader = function () {
        ;(function () {
          const w = window
          const ic = w.Intercom
          if (typeof ic === 'function') {
            ic('reattach_activator')
            ic('update', w.intercomSettings)
          } else {
            const d = document
            const i = function () {
              i.c(arguments)
            }
            i.q = []
            i.c = function (args) {
              i.q.push(args)
            }
            w.Intercom = i
            const l = function () {
              const s = d.createElement('script')
              s.type = 'text/javascript'
              s.defer = true
              s.src = `https://widget.intercom.io/widget/${config.app_id}`
              const x = d.getElementsByTagName('script')[0]
              x.parentNode.insertBefore(s, x)
            }
            if (document.readyState === 'complete') {
              l()
            } else if (w.attachEvent) {
              w.attachEvent('onload', l)
            } else {
              w.addEventListener('load', l, false)
            }
          }
        })()
        window.Intercom('boot', config)
        postponed &&
          window.removeEventListener('DOMContentLoaded', intercomLoader)
      }
      if (postponed) {
        window.addEventListener('DOMContentLoaded', intercomLoader)
      } else {
        intercomLoader()
      }
    },
    shutdown: () => {
      window.Intercom && window.Intercom('shutdown')
    },
    update: (props) => {
      window.Intercom && window.Intercom('update', props)
    },
  }
}

export default init
