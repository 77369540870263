import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

const BannerWrapper = styled.div`
  margin: 0 auto;
  border-radius: 3px;
  color: #333;
  position: relative;
  z-index: 2;

  @media (min-width: ${breakpoints.desktop}px) {
    min-width: 940px;
  }
`

const PlanDetails = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: ${({ bgColor }) => bgColor};
  border-top-left-radius: ${({ borderTopRadius }) => borderTopRadius && '3px'};
  border-top-right-radius: ${({ borderTopRadius }) => borderTopRadius && '3px'};
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: left;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    flex-direction: row;
  }

  .mobileCard {
    display: block;
    @media (min-width: ${breakpoints['desktop-small']}px) {
      display: none;
    }
  }

  #bubble {
    position: absolute;
    top: -40px;
    left: 55px;
    right: 10px;
    transform: scaleX(-1);

    span {
      transform: scaleX(-1);
    }
    @media (min-width: ${breakpoints['desktop-small']}px) {
      right: auto;
      left: auto;
      transform: none;

      span {
        transform: none;
      }
    }
  }

  .symbol {
    font-size: 1.5rem;
    margin: 0;
    @media (min-width: ${breakpoints['desktop-small']}px) {
      margin: 0.5rem;
      font-size: 2rem;
    }
  }

  .pricing {
    order: 0;
  }

  .equals {
    order: 1;
  }

  .includes {
    order: 4;
    @media (min-width: ${breakpoints['desktop-small']}px) {
      order: 2;
      text-align: center;
    }
  }

  .includes-data-only {
    margin-top: 1rem;
    @media (min-width: ${breakpoints['desktop-small']}px) {
      margin-top: 0;
    }
  }

  .add {
    order: 3;
  }

  .data {
    order: 2;
    @media (min-width: ${breakpoints['desktop-small']}px) {
      order: 4;
    }
  }

  .pricing,
  .includes,
  .data {
    display: flex;
    flex-direction: column;

    h2 {
      color: #333;
      margin: 0.5rem 0;
      font-size: 2rem;
      line-height: 1.8125rem;

      @media (min-width: ${breakpoints['desktop-small']}px) {
        font-size: 2rem;
      }

      @media (min-width: 600px) {
        font-size: 2.5rem;
      }
    }

    p {
      margin: 0;
      font-weight: bold;
      @media (min-width: ${breakpoints['desktop-small']}px) {
        font-size: 16px;
      }

      @media (min-width: 600px) {
        font-size: 18px;
      }
    }
  }

  .pricing {
    text-align: left;

    span {
      text-decoration: line-through;
    }
  }

  .data {
    text-align: left;
    @media (min-width: ${breakpoints['desktop-small']}px) {
      text-align: right;
    }
  }

  h5 {
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .hide-tablet-down {
    display: none;

    @media (min-width: ${breakpoints['desktop-small']}px) {
      display: inherit;
    }
  }

  .hide-tablet {
    @media (min-width: ${breakpoints['desktop-small']}px) {
      display: none;
    }
  }
`

const BannerBottom = styled.div`
  background: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 18px 16px;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    flex-direction: row;
  }
  p {
    margin-bottom: 16px;
    @media (min-width: ${breakpoints['desktop-small']}px) {
      margin-bottom: 0;
    }
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    flex-direction: row;
  }
`
const StatementTitle = styled.h3`
  font-size: 18px;
  line-height: 19px;
  margin-bottom: 10px;
`

const OfferWrapper = styled.div`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: ${({ bgColor }) => bgColor};
  padding: 16px;
  text-align: center;
`

const OfferSubHeading = styled.p`
  margin: 0;
  font-size: 1rem;
  line-height: 20px;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 28px;
  }
`

export const StrikedDataMobile = styled.span`
  display: inline-block;
  font-weight: bold;
  text-align: center;
  font-size: 18px !important;
  line-height: 26px;
  min-height: 32px;
  text-decoration: line-through;
  margin: 0 8px;
  color: #424449;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: none;
  }
`

export const StrikedDataDesktop = styled.span`
  font-weight: bold;
  text-align: center;
  font-size: 1.5rem !important;
  line-height: 26px;
  min-height: 32px;
  text-decoration: line-through;
  margin: 0 8px;
  display: none;
  color: #424449;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: inline-block;
  }
`

export const DisplayData = styled.h2`
  display: inline-block;
`

const StyledPaypalButton = styled.div`
  display: none;
  margin-top: 1rem;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    min-width: 14rem;
    height: 48px;
    margin-top: 0;
    margin-left: 1.4rem;
    flex-basis: 100%;
  }
`

const Text = styled.p`
  line-height: 20px;
`

const Description = styled.span`
  display: block;
  font-weight: 400;
  font-size: 12px;
`

const Btn = styled.div`
  width: 244px;
  height: 55px;
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 20px;
  text-align: center;
  border: 2px solid black;
  margin-left: 1.4rem;
  cursor: pointer;
  @media (min-width: ${breakpoints.tablet}px) {
    width: 100%;
    margin: 1rem 0 0 0;
  }
`

const Chip = styled.div`
  flex: 1 1 100%;
  text-align: right;
  width: 100%;
  margin-bottom: 8px;
  span {
    padding: 6px;
    border-radius: 3px;
    background: ${colors['UI/DarkGrey']};
    color: ${colors['UI/White']};
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
  }
`

export {
  BannerWrapper,
  ButtonWrapper,
  BannerBottom,
  StatementTitle,
  PlanDetails,
  OfferWrapper,
  OfferSubHeading,
  StyledPaypalButton,
  Text,
  Description,
  Btn,
  Chip,
}
