import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

import { Rectangle } from '../../components/styles/Shapes'

export const ChecklistHeader = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  padding: 6px 0;
  margin-bottom: 0;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 34px;
    line-height: 38px;
  }
`

export const ChecklistIcon = styled.img`
  width: 80px;
  @media (min-width: ${breakpoints.tablet}px) {
    width: 120px;
  }
`

export const PlansContainer = styled.div`
  margin: ${(props) => (props.spacious ? '1.5rem 0' : '1.5rem 0 0 0')};
  @media (min-width: ${breakpoints.tablet}px) {
    margin: ${(props) => (props.spacious ? '2.5rem 0' : '2.5rem 0 0 0')};
  }
`

export const StyledRectangle = styled(Rectangle)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`
export const List = styled.ul`
  margin: 1rem 0 1rem 0;
`

export const ListItem = styled.li`
  align-items: flex-start;
  margin: 0;
  padding-bottom: 1rem;

  p:last-child {
    margin: 0;
  }
`
