import plans from '../../data/plans.json'

export const getPlans = () => {
  if (typeof plans === 'undefined') {
    return []
  } else {
    return plans
  }
}

export const getPlanBySlug = (slug: string) =>
  getPlans().find((plan) => plan.slug === slug)

export const getPlanById = (id: string) =>
  getPlans().find((plan) => plan.id === id)
