import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

const Root = styled.div.attrs({ 'data-testid': 'wifi-calling-banner' })`
  background-color: ${(props) =>
    props.hasDarkBackground
      ? colors['UI/DarkGrey']
      : colors['Brand/PrimaryBlack']};
  padding: 0.75rem 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  p,
  a {
    font-size: 0.75rem;
    line-height: 1.5;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    p,
    a {
      font-size: 1.125rem;
      line-height: 1.444;
    }
  }

  @media (min-width: ${breakpoints['desktop-small']}px) {
    padding: 1rem 1.5rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }
`

const Icon = styled.img.attrs({ 'data-testid': 'wifi-calling-icon' })`
  width: 16px;
  height: 16px;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    width: 33px;
    height: 33px;
    margin-right: 0.25rem;

    ${(props) =>
      props.hideDesktop &&
      `
      display: none;
    `}
  }
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    width: auto;
    align-items: center;
    justify-content: flex-start;
  }
`

export { Root, Icon, TextWrapper }
