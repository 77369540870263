// TODO: create common component for Plan types sections and use it in PlanSection component. Remove this component completely or remove duplicated code
import React from 'react'

import { getPlansSlugs, planCollectionNames } from '@smarty-nx/contentful'
import PropTypes from 'prop-types'

import { PlanDataOnlyContent, PlanDataOnlyContentPhase2 } from './content'
import {
  HeadingSecondary,
  HeadingSecondarySmall,
  HeadingsWrapper,
  HeadingWithCoverage,
  ItemList,
  List,
  MainHeadingWrapper,
  Note,
  PlansWrapper,
  ToolTipHeading,
  TopCoverageWrapper,
} from './styles'
import { PAGE_CONSTANTS } from '../../constants/globalStrings'
import CheckImage from '../../static/images/checkmark.svg'
import { matchPlanMeta } from '../../utils/plans'
import CoverageChecker from '../CoverageChecker'
import { TooltipModal } from '../Modals'
import { PlanCardSmall } from '../PlanCardSmall/PlanCardSmall'
import { CheckIcon, ListItem } from '../styles/Lists'
import { HideOnTablet, HideSmallDesktopUpwards } from '../styles/Responsive'
import Spacer from '../styles/Spacer'

const DATA_ONLY_PLANS = getPlansSlugs(planCollectionNames.DATA_ONLY_PLANS)

const renderToolTip = () => {
  const tooltipCopy = PlanDataOnlyContentPhase2

  return (
    <TooltipModal header={tooltipCopy.heading}>
      <ToolTipHeading>{tooltipCopy.toolTipSubHeading}</ToolTipHeading>
      <List>
        {tooltipCopy.dataSimTooltipList.map((item, index) => (
          <ListItem key={index}>
            <CheckIcon src={CheckImage} alt="" />
            <ItemList>{item}</ItemList>
          </ListItem>
        ))}
      </List>
      <Note>
        {tooltipCopy.note}
        {tooltipCopy.noteText}
      </Note>
    </TooltipModal>
  )
}

const DataOnlySimPlans = ({
  currentPlan,
  plans,
  hideProductAttributes = false,
  hideCoverageChecker = false,
  hideGroupDiscount = false,
  isDataSim = false,
}) => (
  <section id="data-discount-plans">
    <HeadingWithCoverage>
      <HeadingsWrapper>
        <MainHeadingWrapper>
          <HeadingSecondary data-testid="data-plans-heading">
            {PlanDataOnlyContent.heading}
          </HeadingSecondary>
          {renderToolTip()}
        </MainHeadingWrapper>

        <HeadingSecondarySmall
          id="plans-section-data-only-small-heading"
          data-testid="data-only-plans-small-heading"
        >
          {PlanDataOnlyContent.subHeading}
        </HeadingSecondarySmall>
      </HeadingsWrapper>

      {!hideCoverageChecker && (
        <HideOnTablet>
          <CoverageChecker />
        </HideOnTablet>
      )}
    </HeadingWithCoverage>
    <PlansWrapper>
      {DATA_ONLY_PLANS.map((p, index) => {
        const isCurrentPlan = currentPlan && p === currentPlan.slug
        const { priceData, planMeta } = matchPlanMeta(plans, p)
        return (
          <PlanCardSmall
            key={p}
            index={index}
            parentElModule="plan-modules-data-sim"
            to={isCurrentPlan ? null : `/plans/${p}`}
            href={
              isCurrentPlan
                ? `${PAGE_CONSTANTS.GROUPS_ADD_SIM.route}/${p}`
                : null
            }
            planMeta={planMeta}
            priceData={priceData}
            position={index + 1}
            cta={isCurrentPlan ? 'Get' : 'View'}
            hideProductAttributes={hideProductAttributes}
            hideGroupDiscount={hideGroupDiscount}
            isPlanDataOnly
            isDataSim={isDataSim}
          />
        )
      })}
    </PlansWrapper>
    {!hideCoverageChecker && (
      <TopCoverageWrapper>
        <HideSmallDesktopUpwards>
          <CoverageChecker />
        </HideSmallDesktopUpwards>
      </TopCoverageWrapper>
    )}
    <Spacer size={1} />
  </section>
)

DataOnlySimPlans.propTypes = {
  plans: PropTypes.array,
  currentPlan: PropTypes.object,
  hideProductAttributes: PropTypes.bool,
  hideCoverageChecker: PropTypes.bool,
  hideGroupDiscount: PropTypes.bool,
  isDataSim: PropTypes.bool,
}

export default DataOnlySimPlans
