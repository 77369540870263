import React, { FC } from 'react'

import classes from './bubbles.module.scss'

export const Bubbles: FC<{ color: string; colorLight: string }> = ({
  color,
  colorLight,
}) => {
  return (
    <>
      <svg
        viewBox="0 0 182 163"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.mobile}
      >
        <circle
          cx="4"
          cy="4"
          r="4"
          transform="matrix(.99928 -.03781 -.03642 -.99934 1.148 48.63)"
          fill={color}
        />
        <circle
          cx="4"
          cy="4"
          r="4"
          transform="matrix(.99928 -.03781 -.03642 -.99934 165.148 8.63)"
          fill={color}
        />
        <circle
          cx="4"
          cy="4"
          r="4"
          transform="matrix(.99928 -.03781 -.03642 -.99934 173.148 103.631)"
          fill={color}
        />
        <circle
          cx="5"
          cy="5"
          r="5"
          transform="matrix(.99928 -.03781 -.03642 -.99934 7.22 109.705)"
          fill={colorLight}
        />
        <circle
          cx="5"
          cy="5"
          r="5"
          transform="matrix(.99928 -.03781 -.03642 -.99934 165.221 162.705)"
          fill={colorLight}
        />
        <circle
          cx="3"
          cy="3"
          transform="matrix(.99928 -.03781 -.03642 -.99934 22.076 8.556)"
          fill="#333539"
          r="3"
        />
        <circle
          cx="3"
          cy="3"
          transform="matrix(.99928 -.03781 -.03642 -.99934 3.076 157.556)"
          fill="#333539"
          r="3"
        />
        <circle
          cx="3"
          cy="3"
          transform="matrix(.99928 -.03781 -.03642 -.99934 165.076 52.556)"
          fill="#333539"
          r="3"
        />
      </svg>

      <svg
        viewBox="0 0 193 210"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.desktop}
      >
        <circle
          cx="4"
          cy="4"
          r="4"
          transform="matrix(.99928 -.03781 -.03642 -.99934 3.292 29.297)"
          fill={color}
        />
        <circle
          cx="4"
          cy="4"
          r="4"
          transform="matrix(.99928 -.03781 -.03642 -.99934 176.292 10.298)"
          fill={color}
        />
        <circle
          cx="4"
          cy="4"
          r="4"
          transform="matrix(.99928 -.03781 -.03642 -.99934 184.292 152.297)"
          fill={color}
        />
        <circle
          cx="4"
          cy="4"
          r="4"
          transform="matrix(.99928 -.03781 -.03642 -.99934 80.292 207.297)"
          fill={color}
        />
        <circle
          opacity=".5"
          cx="5"
          cy="5"
          r="5"
          transform="matrix(.99928 -.03781 -.03642 -.99934 1.354 117.372)"
          fill={colorLight}
        />
        <circle
          opacity=".5"
          cx="5"
          cy="5"
          r="5"
          transform="matrix(.99928 -.03781 -.03642 -.99934 117.364 10.372)"
          fill={colorLight}
        />
        <circle
          opacity=".5"
          cx="5"
          cy="5"
          r="5"
          transform="matrix(.99928 -.03781 -.03642 -.99934 145.364 209.372)"
          fill={colorLight}
        />
        <circle
          cx="3"
          cy="3"
          transform="matrix(.99928 -.03781 -.03642 -.99934 68.22 10.223)"
          fill="#333539"
          r="3"
        />
        <circle
          cx="3"
          cy="3"
          transform="matrix(.99928 -.03781 -.03642 -.99934 10.22 188.223)"
          fill="#333539"
          r="3"
        />
        <circle
          cx="3"
          cy="3"
          transform="matrix(.99928 -.03781 -.03642 -.99934 184.22 87.223)"
          fill="#333539"
          r="3"
        />
      </svg>
    </>
  )
}
