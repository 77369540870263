import { Plan, PlanV1 } from '@smarty-nx/data'
import { logErrorAndNotify } from '@smarty-nx/debug-utils'
import { colors } from '@smarty-nx/ds'
import _ from 'lodash'

import { PlanData } from './plans.types'
import { FEATURES } from '../config'
import plansMeta from '../constants/plans'

const hasVoucherDataDiscount = (planV1: any) =>
  planV1.attributes?.voucher?.data_amount > planV1?.attributes?.data_in_gb

export const planShape = ({ id, ...props }: any): PlanV1 => ({
  id,
  attributes: { id, ...props },
})

export const mapV1ToV3 = (planV1: any): Plan => {
  const plan = planV1.attributes ? planV1 : planShape(planV1)

  return {
    id: plan.id,
    slug: plan.attributes.slug,
    description: plan.attributes.description,
    name: plan.attributes.name,
    categoryName: plan.attributes.category_name,
    categoryType: plan.attributes.category_type,
    originalPrice: {
      value: Number(parseFloat(plan.attributes.price) / 100),
      currency: 'GBP',
    },
    finalPrice: {
      value: Number(
        parseFloat(plan.attributes.price_with_discount_and_credit) / 100
      ),
      currency: 'GBP',
    },
    finalPriceWithoutCredit: {
      value: Number(parseFloat(plan.attributes.price_with_discount) / 100),
      currency: 'GBP',
    },
    planIsSocialTariff: plan.attributes.plan_is_social_tariff,
    planIsLegacy: plan.attributes.plan_is_legacy,
    dataAllowanceKB: null,
    dataAllowanceGB: plan.attributes.data_in_gb,
    dataAllowanceWithPromosGB: hasVoucherDataDiscount(plan)
      ? plan.attributes?.voucher?.data_amount
      : plan.attributes.data_in_gb,
    discountAmount: plan.attributes.discount_amount,
    planHasDataDiscount: plan.attributes.data_discount,
    planIsDataOnly: plan.attributes.plan_is_data_only,
    remainingDataAllowanceGB: null,
    remainingDataAllowanceKB: null,
    remainingVoiceAllowance: null,
    remainingSmsAllowance: null,
    usedDataDiscount: null,
    availableDataDiscount: null,
    usedAccountBalance: null,
    availableAccountBalance: null,
    renewalDate: '',
    smsAllowance: null,
    voiceAllowance: null,
    voucher: plan.attributes.voucher || null,
    referrals: {
      referralCreditsUsed: false,
      referralCreditsAvailable: 0,
      referralDiscount: null,
    },
    ...(plan.attributes.style && {
      style: {
        planColor: plan.attributes.style.plan_color,
        planColorPale: plan.attributes.style.plan_color_pale,
      },
    }),
    tcChip: plan.attributes.tc_chip,
    tcText: plan.attributes.tc_text,
  }
}

export const checkDataAmountIsDiff = (live, local) => {
  if (local.data_number === Infinity) {
    return [false, local.data_number]
  }

  if (!live.voucher) {
    return [false, local.data_number]
  }

  if (typeof live.voucher.data_amount !== 'number') {
    return [false, local.data_number]
  }

  if (local.data_number !== live.voucher.data_amount) {
    return [true, live.voucher.data_amount]
  }

  return [false, live.voucher.data_amount]
}

export const matchPlanMetaToPriceData = (plans, planSlug) => {
  const match = plans && plans.find((p) => p.slug === planSlug)

  return match || null
}

const defaultFn = (value) => value

const fnForColour = (value) => {
  if (colors?.[value]) {
    return colors[value]
  }
  throw new Error(`Missing color '${value}' in theme.json colours!`)
}

export const checkField = ({ data, key, defaultValue, cb = defaultFn }) => {
  try {
    const value = _.get(data, key)
    if (value) {
      return cb(value)
    }
    throw new Error(`Missing key '${key}' for ${data.slug} in Contentful CMS!`)
  } catch (error: any) {
    logErrorAndNotify(error, { data: error.message })
    return defaultValue
  }
}

export const matchPlanMeta = (
  plans,
  slug: string
): { planMeta: any; priceData: PlanData } => {
  const priceData = matchPlanMetaToPriceData(
    FEATURES.Q4_WINTER_SALE ? plans : plans.map(mapV1ToV3),
    slug
  )
  let planMeta = plansMeta[slug] || null

  if (priceData) {
    planMeta = {
      ...planMeta,
      name: checkField({
        data: priceData,
        key: 'name',
        defaultValue: planMeta?.name,
      }),
      data_number: priceData.dataAllowanceGB,
      description: checkField({
        data: priceData,
        key: 'description',
        defaultValue: planMeta?.description,
      }),
      color: checkField({
        data: priceData,
        key: 'style.planColor',
        defaultValue: planMeta?.color,
        cb: fnForColour,
      }),
      colorLight: checkField({
        data: priceData,
        key: 'style.planColorPale',
        defaultValue: planMeta?.colorLight,
        cb: fnForColour,
      }),
    }
  }

  return { priceData, planMeta }
}

export const returnDataWithUnit = (dataValue: number) =>
  dataValue < 1 ? `${dataValue * 1000}MB` : `${dataValue}GB`
