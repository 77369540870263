import ThisPlanIncludesGroupsEntries from '../../data/thisPlanIncludesGroups.json'

type ThisPlanIncludesGroupsEntry = typeof ThisPlanIncludesGroupsEntries[0]

export const getThisPlanIncludesGroup = (
  planSlug: string
): ThisPlanIncludesGroupsEntry | null => {
  let thisPlanIncludesGroup: ThisPlanIncludesGroupsEntry | null = null

  if (typeof ThisPlanIncludesGroupsEntries !== 'undefined') {
    thisPlanIncludesGroup =
      ThisPlanIncludesGroupsEntries.find(
        (item) => item.planSlug === planSlug
      ) || null
  }
  return thisPlanIncludesGroup
}
