import React, { useState } from 'react'

import { breakpoints } from '@smarty-nx/ds'
import styled, { css } from 'styled-components'

import LoadingDots from '../components/LoadingDots'
import PageHead from '../components/PageHead'
import SiteContainer from '../components/styles/SiteContainer'
import { Paragraph } from '../components/styles/Text'
import withApp from '../components/withApp/withApp'
import { CONFIG } from '../config'

const CoverageCheckerApp = () => {
  const [loaded, setLoaded] = useState(false)

  const LoadingState = styled.div`
    position: absolute;
    top: 16%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media (min-width: ${breakpoints.tablet}px) {
      top: 10%;
    }
  `

  return (
    <SiteContainer
      css={css`
        max-width: 100vw;
        position: relative;
        padding: 0;
      `}
    >
      {!loaded && (
        <LoadingState>
          <LoadingDots
            css={css`
              width: 80px;
            `}
          />
          <Paragraph>Loading coverage map</Paragraph>
        </LoadingState>
      )}
      <div
        loading="lazy"
        css={css`
          opacity: 0;
          visibility: hidden;
          transition: all 350ms cubic-bezier(0.65, 0.05, 0.36, 1);
          will-change: opacity, visibility;
          ${loaded &&
          `  opacity: 1;
           visibility: visible;`}
        `}
      >
        <iframe
          title="coverage-checker-app"
          src={CONFIG.NETWORK_COVERAGE_CHECKER_LINK}
          height="700"
          seamless=""
          align="middle"
          onLoad={() => setLoaded(true)}
          data-testid="coverage-checker-iframe-app"
          style={{
            width: '100%',
            border: 'none',
            background: 'white',
          }}
        />
      </div>
    </SiteContainer>
  )
}

export default withApp(CoverageCheckerApp)

export const Head = () => (
  <PageHead
    title="Coverage checker"
    description="SMARTY is a new SIM-only mobile network that’s built to be fair, transparent and smart."
  >
    <meta name="robots" content="noindex, nofollow" />
  </PageHead>
)
