import React, { FC } from 'react'

import { Button, ButtonProps, ContentfulProcessor } from '@smarty-nx/ds'
import { Link } from 'gatsby'

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface AnchorHTMLAttributes<T> {
    'data-link-external'?: ''
    'data-level'?: ButtonProps['level']
  }
}

interface ContentfulLinkProps extends ButtonProps {
  link: string
  className?: string
}

const isAppDownloadLink = (href: string | undefined) => {
  return href?.includes('apps.apple.com') || href?.includes('play.google.com')
}

export const ContentfulLink: FC<ContentfulLinkProps> = ({
  link,
  ...buttonProps
}) => {
  return (
    <ContentfulProcessor
      content={link}
      components={{
        a({
          node: _,
          'data-link-external': external,
          'data-level': level,
          href,
          children,
          ...contentfulProps
        }) {
          if (isAppDownloadLink(href)) {
            // do not change download links, render them "as is"
            return (
              <a {...contentfulProps} href={href}>
                {children}
              </a>
            )
          }

          return (
            <Button
              as={(linkProps) => {
                if (external !== undefined) {
                  return (
                    <a {...linkProps} href={href}>
                      {children}
                    </a>
                  )
                }

                return (
                  <Link {...linkProps} to={href || ''}>
                    {children}
                  </Link>
                )
              }}
              {...buttonProps}
              level={level || buttonProps.level}
              {...(contentfulProps as Record<string, unknown>)}
            />
          )
        },
      }}
    />
  )
}
