import React from 'react'

import { colors } from '@smarty-nx/ds'
import PropTypes from 'prop-types'

import {
  BubbleBackground,
  BubbleContent,
  BubbleContentWrapper,
  BubbleImgLeft,
  BubbleImgRight,
} from './styles'
import LeftCircles from '../../static/images/leftCircles.svg'
import RightCircles from '../../static/images/rightCircles.svg'
import FullWidthContainer from '../styles/FullWidthContainer'

const BubbleHero = ({ children, background }) => (
  <FullWidthContainer background={background || colors['UI/DarkerGrey']}>
    <BubbleBackground>
      <BubbleImgLeft src={LeftCircles} alt="" aria-hidden />
      <BubbleImgRight src={RightCircles} alt="" aria-hidden />
    </BubbleBackground>
    <BubbleContent>{children}</BubbleContent>
  </FullWidthContainer>
)

BubbleHero.propTypes = {
  children: PropTypes.node,
  background: PropTypes.string,
}

// Also used directly
export { BubbleContentWrapper }

export default BubbleHero
