import React, { FC } from 'react'

import cn from 'classnames'

import classes from './Sim.module.scss'
import { Icon } from '../../icon/icon'

declare module 'react' {
  interface CSSProperties {
    '--sim-border-color'?: string
    '--sim-contained-color'?: string
  }
}

interface SimProps {
  borderColor?: string
}

const Sim: FC<SimProps> = ({ borderColor }) => (
  <Icon
    name="sim"
    className={cn(
      classes.sim,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      classes[`simBorderColor${borderColor}`]
    )}
  />
)

export default Sim
