import React, { FC } from 'react'

import classes from './plans-carousel.module.scss'
import { PlansCarousel as Carousel } from '../../../../modules/components'
import { getSlugsFromContentfulPlansCollections } from '../utils'

export const PlansCarousel: FC<Queries.GenericPageSectionFragment> = ({
  items,
  title,
  header,
  selectedItem,
}) => {
  const { slugs, category } = getSlugsFromContentfulPlansCollections(items)
  const defaultPosition = selectedItem ? slugs.indexOf(selectedItem.slug) : 0

  if (!slugs.length) {
    return null
  }

  return (
    <div
      data-module-id={category}
      data-module_name={category}
      data-module_placement="plan-list"
    >
      <Carousel
        title={title}
        slugs={slugs}
        heading={<div className={classes.header}>{header}</div>}
        dataTestId={category}
        moduleId={category}
        defaultPosition={defaultPosition}
      />
    </div>
  )
}
