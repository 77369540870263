import React, { FC } from 'react'

import { ContentfulProcessor } from '@smarty-nx/ds'
import { graphql, PageProps } from 'gatsby'

import classes from './3g-switch-off.module.scss'
import { Sections } from './sections'
import EllipseCutOut from '../../components/EllipseCutOut'
import PageHead from '../../components/PageHead'
import withApp, { AppProps } from '../../components/withApp/withApp'

type ThirdGenerationSwitchOffProps = AppProps &
  PageProps<Queries.SwitchOff3GQuery>

const ThirdGenerationSwitchOff: FC<ThirdGenerationSwitchOffProps> = ({
  data: { page },
}) => {
  if (!page) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.pageBanner}>
        <div className={classes.pageBannerContent}>
          <img src={page.logo?.url || ''} className={classes.logoIcon} alt="" />
          <h1>{page.heading}</h1>
          <p>
            <ContentfulProcessor
              content={page.subheading?.internal.content || ''}
            />
          </p>
        </div>

        <EllipseCutOut className={classes.cutOut} />
      </div>

      <Sections
        items={
          page.sections as (
            | Queries.GenericBannerFragment
            | Queries.PageSectionFragment
          )[]
        }
      />
    </div>
  )
}

export default withApp(ThirdGenerationSwitchOff)

export const Head = () => (
  <PageHead
    title="Switching off 3G"
    description="SMARTY is a new SIM-only mobile network that’s built to be fair, transparent and smart."
  />
)

export const query = graphql`
  query SwitchOff3G {
    page: contentfulPage(path: { eq: "/3g-switch-off" }) {
      ...Page
    }
  }
`
