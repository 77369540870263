import React from 'react'

import { colors } from '@smarty-nx/ds'

import { discountCardContent } from './content'
import {
  DiscountButton,
  GreenText,
  LeftColumn,
  RightColumn,
  Title,
  Wrapper,
  YellowText,
} from './styles'
import Button from '../../components/Button'
import FullWidthContainer from '../../components/styles/FullWidthContainer'
import GroupPlan from '../../static/images/big-data-plans.png'
import { HideOnTablet, HideSmallDesktopUpwards } from '../styles/Responsive'
import { Paragraph } from '../styles/Text'

const DiscountCard = () => (
  <FullWidthContainer data-testid="get-money-back-card">
    <Wrapper>
      <LeftColumn>
        <Title data-testid="get-money-back-title">
          {discountCardContent.title}
        </Title>
        <HideSmallDesktopUpwards>
          <img src={GroupPlan} alt="" data-testid="get-money-back-image" />
        </HideSmallDesktopUpwards>

        <Paragraph
          className="kollektif-large margin--regular"
          data-testid="get-money-back-subtitle"
        >
          {discountCardContent.textOne}
          <GreenText>{discountCardContent.greenText}</GreenText>
          {discountCardContent.textTwo}
          <YellowText>{discountCardContent.yellowText}</YellowText>
          {discountCardContent.textThree}
        </Paragraph>
      </LeftColumn>
      <RightColumn>
        <HideOnTablet>
          <img src={GroupPlan} alt="" data-testid="get-money-back-image" />
        </HideOnTablet>
        <Button
          href={discountCardContent.CTALink}
          color={colors['Brand/PrimaryGreen']}
          data-testid="get-money-back-button"
          customStyle={DiscountButton}
        >
          {discountCardContent.cta}
        </Button>
      </RightColumn>
    </Wrapper>
  </FullWidthContainer>
)

export default DiscountCard
