import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

export const Para = styled.p`
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 27px 0;
`
export const AltPara = styled.p`
  margin: 10px 0;
  max-width: 590px;
`

export const ListItem = styled.li`
  margin: 10px 0;
  max-width: 590px;
`

export const Flag = styled.img`
  max-height: 17px;
  height: 100%;
  width: auto;
`

export const TopFlag = styled.img`
  max-width: 64px;
  height: auto;
  width: 100%;
  margin-top: 40px;
`

export const Container = styled.div`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  border-radius: 5px;
  padding: ${({ padding }) => padding || '15px 0'};
  color: #333539;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    flex-direction: row;
    * {
      flex: 1;
    }
  }

  .inner-padding {
    padding: 27px 15px;

    img {
      max-width: 140px;
    }
  }
`

export const List = styled.ol`
  flex: 1;
  border-radius: 5px;
  background-color: rgb(243, 243, 243);
  padding: 10px;
  list-style: none;
  margin: 0;
  margin-bottom: 20px;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    margin-right: 20px;
  }

  li {
    border-bottom: 2px solid rgb(189, 189, 189);

    a {
      position: relative;
      display: block;
      padding: 10px;
      padding-left: 60px;
      text-decoration: none;
      font-size: 0.9rem;

      img {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }

      * {
        display: inline-block;
      }
    }
  }
`

export const TdPricing = styled.td`
  text-align: right;
  font-weight: 700;
  span {
    display: block;
    font-weight: 400;
  }
`

export const Card = styled.div`
  background-color: #3b3c41;
  border-radius: 5px;
  padding: 15px;
  color: #fff;
  margin-bottom: 20px;
  height: fit-content;
  box-sizing: border-box;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    max-width: ${({ maxWidth }) => (maxWidth === 'none' ? '' : '300px')};
    width: 100%;
    padding: 25px;
  }

  p {
    margin: 20px 0;
    color: ${({ copyColor }) => copyColor || '#fff'};
  }

  a {
    font-weight: 700;
  }

  h2 {
    margin: 35px 0 27px;
    font-size: 1.7rem;
    line-height: 1.7rem;
  }

  h2.card-heading {
    margin: 0 0 0.5rem;
    color: ${({ headingColor }) => headingColor || '#ffd67c'};
  }
`

export const Heading = styled.h1`
  margin: 40px 0;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 2.1rem;
  line-height: 2.1rem;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }

  span {
    flex: 0.6;

    & + span {
      flex: 0.4;
    }
  }

  img {
    display: none;
    max-width: 150px;
    width: 100%;
    height: auto;
    max-height: none;
    margin: 0 auto;
    vertical-align: middle;
    @media (min-width: ${breakpoints['desktop-small']}px) {
      display: block;
    }
  }
`

export const WifiCard = styled.div`
  background-color: #333539;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  margin: 0 0 20px;
  padding: 28px 15px;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    flex-direction: row;
  }

  img {
    flex: 1;
    order: 0;
    max-width: 290px;
    margin: 0 auto;

    @media (min-width: ${breakpoints['desktop-small']}px) {
      order: 1;
    }
  }

  div {
    flex: 1;
    order: 1;

    @media (min-width: ${breakpoints['desktop-small']}px) {
      order: 0;
    }

    p {
      color: #bdbdbd;
      margin-bottom: 18px;
    }

    h2 {
      margin: 0px 0 27px;
      font-size: 1.7rem;
      line-height: 1.7rem;
    }
  }
`

export const SubHeading = styled.h3`
  font-size: 1.7rem;
  line-height: 2rem;
  margin: 10px 0 15px 0;
  max-width: 590px;
  font-weight: 400;
`

export const Tabs = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  width: 100%;
  height: 95px;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    position: relative;
    bottom: auto;
    left: auto;
  }

  img {
    max-width: 24px;
    margin-bottom: 10px;
  }

  a {
    flex: 1;
    max-height: 95px;
    max-width: 300px;
    width: 100%;
    height: 100%;
    background-color: #333539;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: ${breakpoints['desktop-small']}px) {
      font-size: 1.1rem;
    }

    &.selected {
      background-color: #424449;
      font-weight: 700;
    }
  }
`

export const BreadCrumbs = styled.p`
  padding: 40px 0 0;
`

export const Desktop = styled.span`
  display: none;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: block;
  }
`

export const Mobile = styled.span`
  display: block;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: none;
  }
`

export const Table = styled.table`
  display: block;
  margin: 0;
  padding: 0;
  padding-right: 10px;
  max-width: 100%;

  tbody {
    display: table;
    width: 100%;
  }

  td {
    vertical-align: middle;
    border-bottom: 1px solid ${({ borderColor }) => borderColor || '#EFC873'};
    padding: 12px 0;
  }

  td:first-child {
    padding-left: 18px;
  }

  td:last-child {
    padding-right: 18px;
  }

  thead {
    height: 0;
    display: none;
  }

  thead th {
    border: 0;
  }

  tr:last-child td {
    border: 0;
  }
`

export const Paragraph = styled.p`
  color: #bdbdbd;
  margin: 0px 0 60px;
`

export const ButtonContainer = styled.div`
  margin: 20px 0 40px;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    max-width: 222px;
  }
`
