import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

const Spacer = styled.div<{ size?: number }>`
  padding: ${({ size }) => (size ? `${size * 30}px 0` : '30px 0')};
  @media (max-width: ${breakpoints['tablet-landscape'] - 1}px) {
    padding: ${({ size }) => (size ? `${size * 15}px 0` : '15px 0')};
  }
`

export default Spacer
