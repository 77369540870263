function calculateVAT(price) {
  // prefer to work with whole numbers
  const multiplier = 100

  const VAT = 1.2 * multiplier // percent = 120%
  const result = VAT * price

  return result / multiplier
}

function convertToGBPWithVAT(price) {
  const withVAT = calculateVAT(price).toFixed(2)
  if (withVAT < 1) {
    return `${parseInt(withVAT * 100, 10)}p`
  }

  return `£${withVAT}`
}

export function calculatePriceWithVAT(price) {
  if (price === null) return ''
  return convertToGBPWithVAT(price)
}

export function calculateQuantityWithPrice(price, amount) {
  const withVAT = calculateVAT(price).toFixed(2)
  return Math.floor(amount / withVAT)
}
