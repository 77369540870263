import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

import SiteContainer from '../styles/SiteContainer'

export const BubbleContentWrapper = styled.div<{
  isRaf?: boolean
  smallText?: boolean
}>`
  position: relative;
  margin-top: -100px;
  text-align: center;
  padding-top: ${({ isRaf }) => (isRaf ? '20px' : '157px')};

  h1 {
    font-size: 40px;
    line-height: 40px;
    margin: 18px 0;
  }

  > p {
    font-size: 25px;
    line-height: 35px;
    max-width: 790px;
    margin: 18px auto;

    ${(props) =>
      props.smallText &&
      `
      font-size: 18px;
      line-height: 1.444;
    `}
  }

  a {
    font-size: 18px;
  }

  a.button {
    font-size: 14px;
  }

  @media (min-width: ${breakpoints['desktop-small']}px) {
    h1 {
      font-size: 54px;
      line-height: 54px;
    }
  }
`

export const BubbleBackground = styled.div`
  width: 100%;
  position: relative;
  height: 116px;
  z-index: 0;
`

export const BubbleImgLeft = styled.img`
  display: none;

  @media (min-width: ${breakpoints.tablet}px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 114px;
  }
`

export const BubbleImgRight = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 285px;
  height: 143px;
`

export const BubbleContent = styled(SiteContainer)`
  z-index: 1;
  min-height: 180px;
`
