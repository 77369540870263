import React, { FC } from 'react'

import { Icon } from '@smarty-nx/ds'
import cn from 'classnames'
import { Link } from 'gatsby'
import { Keyboard, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react'
import { SwiperOptions } from 'swiper/types'

import classes from './slider.module.scss'
import { TRACKING_CATEGORIES } from '../../../../../constants/globalStrings'
import { PlanData, PlanMeta } from '../../../../../utils/plans.types'
import { PlanCardCompact } from '../../../../components'
import { SelectedPlan } from '../selected-plan/selected-plan'

import 'swiper/css'
import 'swiper/css/pagination'

interface SliderProps extends SwiperProps {
  list: {
    planMeta: PlanMeta
    priceData: PlanData
  }[]
  dataTestId?: string
  defaultPosition: number
}

const options: SwiperOptions = {
  runCallbacksOnInit: false,
  keyboard: { enabled: true },
  centeredSlides: true,
  loop: false,
  rewind: true,
  slidesPerView: 'auto',
  modules: [Pagination, Navigation, Keyboard],
  pagination: { clickable: true, el: `.${classes.pagination}` },
  navigation: {
    prevEl: `.${classes.leftArrow}`,
    nextEl: `.${classes.rightArrow}`,
  },
}

export const Slider: FC<SliderProps> = ({
  list,
  dataTestId,
  defaultPosition = 0,
  ...props
}) => {
  return (
    <>
      <div
        className={cn('redesign-container', classes.root)}
        data-testid={dataTestId}
      >
        <Icon name="chevron" className={cn(classes.arrow, classes.leftArrow)} />

        <Swiper
          {...options}
          {...props}
          onSlideChangeTransitionEnd={(swiper) => {
            // we need this to fix potential issues after manually updating internal snap grid things
            swiper.updateSlides()
            swiper.slideTo(swiper.activeIndex, 300, false)
          }}
          onInit={(swiper) => {
            defaultPosition > 0 && swiper.slideTo(defaultPosition, 300, false)
          }}
        >
          {list.map((plan, index) => {
            return (
              <SwiperSlide key={plan.planMeta.id}>
                {({ isActive }) => {
                  const position = index + 1

                  if (isActive) {
                    return <SelectedPlan plan={plan} position={position} />
                  }

                  return (
                    <Link
                      to={`/plans/${plan.planMeta.slug}`}
                      className={classes.link}
                      data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                      data-item-name={`carousel-${plan.planMeta.slug}`}
                    >
                      <PlanCardCompact
                        position={position}
                        planMeta={plan.planMeta}
                        priceData={plan.priceData}
                        isLabelled
                      />
                    </Link>
                  )
                }}
              </SwiperSlide>
            )
          })}
        </Swiper>

        <Icon
          name="chevron"
          className={cn(classes.arrow, classes.rightArrow)}
        />
      </div>

      <div className={classes.pagination} />
    </>
  )
}
