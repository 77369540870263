import { breakpoints, colors } from '@smarty-nx/ds'
import styled, { css } from 'styled-components'

/* Root styling incuding flex direction */
export const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ imageTextSmall }) => imageTextSmall && '1.5rem 0'};
  position: relative;
  @media (min-width: ${breakpoints.desktop}px) {
    align-items: center;
    flex-direction: ${({ inverse }) => (inverse ? 'row-reverse' : 'row')};
    padding: ${({ imageTextSmall }) => imageTextSmall && '3.5rem'};
    padding-top: ${(props) => props.custStyleProps && '1rem'};
    padding-bottom: ${(props) => props.custStyleProps && '1rem'};
  }
  ${({ customProps }) =>
    customProps &&
    `
    background: ${colors['UI/LighterGrey']};
    margin-bottom: 1rem;
    padding: 1rem;
    @media (min-width: ${breakpoints.desktop}px) {
      background: ${colors['Brand/PrimaryBlack']};
      margin: 0;
    }
  `}
`

/* Base CSS for the columns */
/*
  ImageText is a design system component - small refers to image size
  https://www.figma.com/file/JRJ2xtZ27FkTiR6GEvt0yuDd/%E2%9D%96-SMARTY-Design-System?node-id=4426%3A71
*/
const half = css`
  flex: none;
  position: relative;
  img,
  svg {
    margin: ${({ imageTextSmall }) => imageTextSmall && '0 auto 1rem'};
    max-width: ${({ imageTextSmall }) => (imageTextSmall ? '16.5rem' : 'none')};
    ${({ customProps }) =>
      customProps &&
      `
    margin: 0 auto 1rem 0;
    width: 64px;
  `};
  }
  @media (min-width: ${breakpoints.desktop}px) {
    flex: 1;
    img,
    svg {
      ${({ customProps }) =>
        customProps &&
        `
         width: 100%;
  `};
      margin: ${({ imageTextSmall }) => imageTextSmall && '0'};
    }
  }
`

/* Individual column CSS */
export const LeftColumn = styled.div`
  ${half};
  ${({ leftStyles }) => leftStyles && leftStyles};

  @media (min-width: ${breakpoints.desktop}px) {
    img,
    svg {
      // only float left or right if it is imageText component
      // left or right is based on the inverse prop
      float: ${(props) =>
        props.imageTextSmall && (props.inverse ? 'right' : 'left')};
      width: ${(props) => props.custStyleProps && '240px'};
      height: ${(props) => props.custStyleProps && '240px'};
    }
  }
`

export const RightColumn = styled.div`
  ${half};
  ${({ rightStyles }) => rightStyles && rightStyles};
  @media (min-width: ${breakpoints.desktop}px) {
    max-width: ${(props) => props.custStyle && 'fit-content'};
  }
`
