import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

export const Columns = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
  }
`

export const HalfWidthColumn = styled.div`
  width: 100%;
  margin-right: ${({ condense }) => (condense ? 0 : '64px')};
  @media (min-width: ${breakpoints.desktop}px) {
    width: 50%;
  }
`

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  @media (min-width: ${breakpoints.desktop}px) {
    margin-bottom: ${({ condense }) => (condense ? 0 : '3rem')};
  }
`

export const ListColumns = styled(List)`
  columns: 1;
  margin: 0 0 0.5rem 0;

  @media (min-width: ${breakpoints.tablet}px) {
    columns: 2;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    columns: ${(props) => (props.isThreeColumns ? 3 : 2)};
    margin: 1rem 2rem 2rem;
  }

  > li {
    margin-bottom: 0.5rem;

    :last-of-type {
      margin-bottom: 0;
    }

    @media (min-width: ${breakpoints.tablet}px) {
      margin-bottom: 1rem;
    }

    @media (min-width: ${breakpoints.desktop}px) {
      margin-bottom: 1.5rem;
    }

    .subtext {
      display: block;
    }

    p {
      font-size: 14px;
      font-weight: bold;
      display: block;
      @media (min-width: ${breakpoints.tablet}px) {
        font-size: 18px;
        font-weight: normal;
        .subtext {
          font-size: 14px;
        }
      }
    }
  }
`

export const ListParagraphSmall = styled.p`
  font-size: 18px;
  line-height: 26px;
`

export const ListItem = styled.li`
  text-decoration: none;
  display: flex;
  margin-bottom: ${(props) => (props.smallMargin ? '6px' : '16px')};
`

/**
 * Our lists use column layouts for styling purposes - this keeps blocks of elements together
 * when break-inside or break-after CSS rules don't work properly
 */
export const ListFragment = styled.span`
  display: inline-block;
`

export const CheckIcon = styled(Image)`
  align-self: flex-start;
  margin-top: 5px;
  width: auto;
  max-width: 15px;
  max-height: 15px;
  margin-right: 8px;
`
