import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

import TimelineBg from '../../static/images/timeline.svg'
import { FourColumn } from '../styles/Columns'

const Title = styled.h2`
  padding: 24px 0;
  @media (min-width: ${breakpoints.tablet}px) {
    text-align: center;
  }
`

const Icon = styled.img`
  margin-right: 8px;
  max-width: 30px;
`

const LargeIcon = styled.img`
  margin-bottom: 2rem;
  max-width: 100px;
`

const Timeline = styled.ol`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

const Step = styled.li`
  background-image: url('${TimelineBg}');
  background-repeat: repeat-y;
  background-position: 24px 0px;
  padding: 0 0 1px 0;

  &:last-child {
    padding: 0;

    li:last-child {
      background-color: white;
    }
  }
`

const StepHeading = styled.h3`
  background-color: ${colors['UI/HighlightGrey']};
  border-radius: 3px;
  margin: 0 0 40px;
  padding: 4px 16px 4px 8px;
  display: flex;
  align-items: center;
`

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding-bottom: 16px;

  img {
    margin-top: -6px;
  }
`

const WhyCard = styled.div`
  background-color: rgba(149, 149, 149, 0.25);
  padding: 1.6rem 1rem 1rem;

  @media (min-width: ${breakpoints.tablet}px) {
    text-align: center;

    img {
      margin-left: auto;
      margin-right: auto;
      width: 90px;
      height: 90px;
    }
  }
`

const WhyCardTitle = styled.h3`
  font-size: 18px;
  line-height: 19px;
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 24px;
    line-height: 28px;
  }
`

const StepsContainer = styled(FourColumn)`
  justify-content: center;
`

export {
  Icon,
  LargeIcon,
  Timeline,
  Step,
  StepHeading,
  ListItem,
  Title,
  WhyCard,
  WhyCardTitle,
  StepsContainer,
}
