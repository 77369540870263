import React, { FC } from 'react'

import { PageSectionItem } from '@smarty-nx/contentful'
import { ContentfulProcessor, Link } from '@smarty-nx/ds'
import { Link as GatsbyLink } from 'gatsby'

import classes from './tile.module.scss'

export const Tile: FC<PageSectionItem> = ({
  title,
  description,
  link,
  image: { url },
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>

      <div className={classes.description}>{description}</div>

      <ContentfulProcessor
        content={link}
        components={{
          a({ node: _, children, href, ...props }) {
            return (
              <Link
                props={props}
                href={href}
                className={classes.link}
                component={GatsbyLink}
              >
                {children}
              </Link>
            )
          },
        }}
      />

      <img src={url} className={classes.image} alt={title} />
    </div>
  )
}
