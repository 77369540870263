import { colors } from '@smarty-nx/ds'
import styled from 'styled-components'

export const Rectangle = styled.div<{
  width?: string
  background?: string
  padding?: string
  textAlign?: string
  opacity?: number
  margin?: number
}>`
  width: ${({ width }) => width};
  background: ${({ background }) => background};
  border-radius: 3px;
  opacity: ${({ opacity }) => opacity};
  padding: ${({ padding }) => padding};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  color: ${colors['UI/White']};
  margin: ${({ margin }) => margin};
  position: relative;
`
