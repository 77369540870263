import React, { FC } from 'react'

import { getAppDownloadBanner } from '@smarty-nx/contentful'
import { ContentfulProcessor } from '@smarty-nx/ds'
import cn from 'classnames'

import classes from './app-download.module.scss'

const banner = getAppDownloadBanner()

export const AppDownloadSection: FC = () => {
  if (!banner) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={cn('redesign-container', classes.container)}>
        <img
          className={classes.image}
          src={banner.image?.url}
          alt={banner.image?.title}
        />

        <div className={classes.content}>
          <div className={classes.title}>{banner.title}</div>

          <div className={classes.description}>
            <ContentfulProcessor content={banner.description || ''} />
          </div>

          <div className={classes.links}>
            <ContentfulProcessor content={banner.link || ''} />
          </div>
        </div>
      </div>
    </div>
  )
}
