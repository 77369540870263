import URLSearchParams from '@ungap/url-search-params'

import { VOUCHER_CODE_KEY } from '../global.constants'

export default (searchParams) => {
  const urlParams = new URLSearchParams(searchParams)
  const code = urlParams.get(VOUCHER_CODE_KEY)

  // Param values can resolve to empty strings, so lets be consistent and return null
  if (code === null || code === '') {
    return null
  }

  return code
}
