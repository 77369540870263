import planCollectionEntries from '../../data/plansEntries.json'
import { planCollectionNames } from '../..'

export const getPlanCollection = (planCollectionName: string) => {
  if (typeof planCollectionEntries === 'undefined') {
    return []
  } else {
    return (
      planCollectionEntries.find((item) => item.category === planCollectionName)
        ?.plansList || []
    )
  }
}

export const getPlansSlugs = (planCollectionName: string) =>
  getPlanCollection(planCollectionName).map((plan) => plan.slug)

export const getPlansIds = (planCollectionName: string) =>
  getPlanCollection(planCollectionName).map((plan) => plan.id)

export const filterSupportedPlans = (plan: { id: string }) =>
  !getPlansIds(planCollectionNames.UNSUPPORTED_PLANS).includes(plan.id)

export const filterVoicePlans = (plan: { id: string }) =>
  getPlansIds(planCollectionNames.VOICE_PLANS).includes(plan.id)

export const filterSocialPlans = (plan: { id: string }) =>
  getPlansIds(planCollectionNames.SOCIAL_PLANS).includes(plan.id)

export const filterDataOnlyPlans = (plan: { id: string }) =>
  getPlansIds(planCollectionNames.DATA_ONLY_PLANS).includes(plan.id)

export const filterMoneyBackPlans = (plan: { id: string }) =>
  getPlansIds(planCollectionNames.MONEY_BACK_PLANS).includes(plan.id)

export const filterFamilyPlans = (plan: { id: string }) =>
  getPlansIds(planCollectionNames.FAMILY_PLANS).includes(plan.id)

export const getPlanCollectionName = (planId: string) =>
  [
    planCollectionNames.VOICE_PLANS,
    planCollectionNames.DATA_ONLY_PLANS,
    planCollectionNames.MONEY_BACK_PLANS,
    planCollectionNames.FAMILY_PLANS,
  ].find((collection: string) => getPlansIds(collection).includes(planId))

export const isUnlimitedPlan = (planId = '') =>
  getPlansIds(planCollectionNames.UNLIMITED_PLANS).includes(planId)

export const isDataOnlyPlan = (planId = '') =>
  getPlansIds(planCollectionNames.DATA_ONLY_PLANS).includes(planId)

export const isUnsupportedPlan = (planId = '') =>
  getPlansIds(planCollectionNames.UNSUPPORTED_PLANS).includes(planId)

export const isFamilyPlan = (planId = '') =>
  getPlansIds(planCollectionNames.FAMILY_PLANS).includes(planId)
