import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface SharedState {
  voucherCode: string | null
}

const initialState: SharedState = {
  voucherCode: null,
}

const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setVoucherCode: (state, action: PayloadAction<string>) => {
      state.voucherCode = action.payload
    },
  },
})

export const { setVoucherCode } = sharedSlice.actions

export default sharedSlice.reducer
