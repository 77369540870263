import React, { FC } from 'react'

import { getTimelinePageSection } from '@smarty-nx/contentful'
import cn from 'classnames'

import { Tile } from './_parts'
import classes from './how-it-works.module.scss'

const section = getTimelinePageSection()

export const HowItWorksSection: FC = () => {
  if (!section) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={cn('redesign-container', classes.container)}>
        <div className={classes.title}>{section.title}</div>

        <div className={classes.tiles}>
          {section.items.map((item, i) => (
            <Tile
              key={item.type}
              {...item}
              className={i < section.items.length - 1 && classes.line}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
