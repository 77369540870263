import React from 'react'

import { breakpoints, colors } from '@smarty-nx/ds'
import { css } from 'styled-components'

import { Header, Icon, Paragraph, StyledFullWidthContainer } from './styles'
import { TwoColumn } from '../../components/styles/Columns'
import SiteContainer from '../../components/styles/SiteContainer'
import Bars from '../../static/images/bars.svg'

const AllPlans = () => (
  <StyledFullWidthContainer background={colors['UI/DarkGrey']}>
    <SiteContainer>
      <TwoColumn>
        <div>
          <Header>Is it really unlimited?</Header>
          <Paragraph>
            Yep. It’s truly unlimited data.
            <br /> Because that’s simple, and honest.
            <br />
            <span className="restrictions"> No restrictions.</span>
            <span className="caps"> No speed caps.</span>
          </Paragraph>
        </div>
        <div
          css={css`
            align-items: center;
            padding-top: 2rem;
            @media (min-width: ${breakpoints.tablet}px) {
              align-items: flex-end;
              padding-top: 0;
            }
          `}
        >
          <Icon src={Bars} alt="" />
        </div>
      </TwoColumn>
    </SiteContainer>
  </StyledFullWidthContainer>
)

export default AllPlans
