import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

export const WhyCard = styled.div`
  background-color: rgba(149, 149, 149, 0.25);
  padding: 1.6rem 1rem 1rem;

  @media (min-width: ${breakpoints.tablet}px) {
    text-align: center;

    img {
      margin-left: auto;
      margin-right: auto;
      width: 90px;
      height: 90px;
    }
  }
`

export const WhyCardTitle = styled.h3`
  font-size: 18px;
  line-height: 19px;
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 24px;
    line-height: 28px;
  }
`
