import React, { FC } from 'react'

import {
  useWindowSize,
  getDataUnit,
  pricePresenter,
  PLAN_IDS,
  DATA_ITEM_CATEGORIES,
} from '@smarty-nx/code-utils'
import { Plan } from '@smarty-nx/data'
import cn from 'classnames'
import { Link as GatsbyLink } from 'gatsby'
import { Link } from 'react-router-dom'

import classes from './MultipleOffersBanner.module.scss'
import Sim from './Sim/Sim'
import { breakpoints } from '../../../index'
import { Button } from '../button/button'

interface MultipleOffersBannerProps {
  offers?: Plan[]
  LinkComponent?: Link | GatsbyLink<{ to: string }>
  ctaLink?: string
  dataItemName?: string
  dataItemCategory?: string
}

const renderTextContent = (plan: Plan) => {
  const {
    id,
    name,
    voucher,
    finalPriceWithoutCredit,
    dataAllowanceWithPromosGB,
  } = plan

  const isDataDiscountPlan = plan.planHasDataDiscount
  const isPlanIsDataOnly = plan.planIsDataOnly
  const hasVoucher = voucher !== null
  const isMultipleOffers =
    Array.isArray(voucher?.multiple_offers) &&
    Boolean(voucher?.multiple_offers.length)

  let categoryName = 'classic'
  if (isDataDiscountPlan) {
    categoryName = 'money-back'
  } else if (isPlanIsDataOnly) {
    categoryName = 'data-only'
  }

  return (
    <div className={classes.offerTextContent}>
      <div className={classes.planContentWrapper}>
        <div className={classes.mainPlanInfo}>
          {id === PLAN_IDS.UNLIMITED || id === PLAN_IDS.DATA_ONLY_UNLIMITED ? (
            <div className={classes.planName}>{name}</div>
          ) : (
            <div className={classes.dataBlock}>
              <div
                className={classes.planAmount}
                data-testid={`${categoryName}-data-amount`}
              >
                {dataAllowanceWithPromosGB}
              </div>
              <div data-testid={`${categoryName}-data-unit`}>
                {getDataUnit(dataAllowanceWithPromosGB)}
              </div>
            </div>
          )}

          <div>{pricePresenter(finalPriceWithoutCredit.value)}</div>
        </div>

        {hasVoucher && (
          <div className={classes.chipText}>
            {isMultipleOffers
              ? 'Multiple offers'
              : voucher.offer_chip_text || voucher.offerChipText}
          </div>
        )}
      </div>
    </div>
  )
}

export const MultipleOffersBanner: FC<MultipleOffersBannerProps> = ({
  offers = [],
  LinkComponent = Link,
  dataItemCategory = DATA_ITEM_CATEGORIES.NAVIGATION,
  dataItemName = 'multiple-offers-banner',
  ctaLink = '',
}) => {
  const [firstOffer, secondOffer] = offers
  const { width = 0 } = useWindowSize()
  const btnText =
    width > breakpoints['desktop-small']
      ? 'See current offers'
      : 'Current offers'
  const Component = LinkComponent as React.ElementType

  return (
    <div className={classes.root}>
      <div className={classes.imagesBlock}>
        <div className={classes.imagesContent}>
          <div className={cn(classes.offer, classes.firstOffer)}>
            <div className={classes.offerImg}>
              <Sim
                borderColor={firstOffer?.style?.planColor?.replace('/', '')}
              />
            </div>
            {renderTextContent(firstOffer)}
          </div>
          <div className={cn(classes.offer, classes.secondOffer)}>
            <div className={classes.offerImg}>
              <Sim
                borderColor={secondOffer?.style?.planColor?.replace('/', '')}
              />
            </div>
            {renderTextContent(secondOffer)}
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <h2 className={classes.h2}>Extra offers for you!</h2>
        <p className={classes.description}>
          As a loyal SMARTY customer we would like to give you more. Check out
          the current offer plans.
        </p>
        <Button
          level="primary"
          data-item-category={dataItemCategory}
          data-item-name={dataItemName}
          as={(props) => <Component {...props} to={ctaLink} />}
        >
          {btnText}
        </Button>
      </div>
    </div>
  )
}
