import React from 'react'

import PropTypes from 'prop-types'

import {
  Heading,
  Highlight,
  Root,
  SmallParagraph,
  StatementWrapper,
} from './styles'
import Icon from '../../components/styles/Icon'
import TextLink from '../../components/TextLink'
import ThreeLogo from '../../static/images/threeLogoUrl.svg'

const CoverageBlock = ({ hasFullCoverage, ...props }) => (
  <Root {...props}>
    <StatementWrapper>
      <Heading data-testid="coverage-heading">Reliable network by</Heading>
      <Icon
        src={ThreeLogo}
        width="auto"
        height="40px"
        margin="0 0 0 10px"
        alt="Three"
        loading="lazy"
        data-testid="three-logo"
      />
    </StatementWrapper>

    <SmallParagraph data-testid="coverage-text">
      {hasFullCoverage ? (
        <TextLink to="/coverage-checker" dataTestId="coverage-link">
          Check your coverage here
        </TextLink>
      ) : (
        <>
          <Highlight>99.5%</Highlight> coverage (outdoors),{' '}
          <TextLink to="/coverage-checker" dataTestId="coverage-link">
            check yours here
          </TextLink>
        </>
      )}
    </SmallParagraph>
  </Root>
)

CoverageBlock.propTypes = {
  hasFullCoverage: PropTypes.bool,
}

export default CoverageBlock
