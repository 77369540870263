import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  border-radius: 8px;
  background: ${({ background }) => background};
  color: ${colors['UI/DarkerGrey']};
  position: relative;
  z-index: 2;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    border-right: ${({ hasBorder }) => (hasBorder ? '1px solid grey' : '')};
  }
`
