import React, { FC, ReactNode } from 'react'

import cn from 'classnames'

import classes from './supporting-text.module.scss'

interface SupportingTextProps {
  children: ReactNode
  isError?: boolean
  dataTestId?: string
  className?: string
}

export const SupportingText: FC<SupportingTextProps> = ({
  isError,
  children,
  dataTestId = 'supporting-text',
  className,
}) => (
  <div
    data-testid={dataTestId}
    className={cn(classes.wrapper, isError && classes.error, className)}
  >
    {children}
  </div>
)
