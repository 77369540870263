import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

export const Heading = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  width: 100%;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    font-size: 34px;
    line-height: 38px;
  }
`

export const SmallParagraph = styled.p`
  font-size: 16px;
  line-height: 24px;
`

export const Highlight = styled.span`
  color: ${colors['Brand/Yellow']};
`

export const Root = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 24px 0;
  align-items: center;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    flex-direction: row;
    align-items: baseline;
    padding: 32px 0;
  }
`

export const StatementWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    margin-bottom: 0px;
  }
`
