import { breakpoints, colors } from '@smarty-nx/ds'
import styled, { css } from 'styled-components'

import { theme } from '../../constants/theme'

const Wrapper = styled.div`
  display: block;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: flex;
    justify-content: space-between;
  }

  img {
    margin: 0 auto 1.5rem;
    max-width: 400px;

    @media (min-width: ${breakpoints.tablet}px) {
      margin: 0 auto 2.5rem;
    }
  }
`

const LeftColumn = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    width: 55%;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    width: 63%;
  }
`

const RightColumn = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    width: 40%;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    width: 32%;
  }
`

const DiscountButton = css`
  margin: 1.5rem auto 0;
  @media (min-width: ${breakpoints.tablet}px) {
    max-width: none;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    float: right;
    max-width: 17rem;
  }
`

const GreenText = styled.span`
  color: ${colors['Brand/PrimaryGreen']};
`

const YellowText = styled.span`
  color: ${colors['Brand/Yellow']};
`

const Title = styled.h2`
  font-family: ${theme.fonts.fontFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 38px;
  margin-bottom: 43px;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    font-size: 46px;
    line-height: 54px;
  }
`

export {
  Wrapper,
  LeftColumn,
  RightColumn,
  DiscountButton,
  GreenText,
  YellowText,
  Title,
}
