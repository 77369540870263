import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

const backgroundColor = (level, color) =>
  level === 'tertiary' ? colors['UI/LightestGrey'] : color

type ButtonProps = {
  $foreColor: string | null
  $custStyle?: boolean
  $level?: string
  $color?: string
  $linkStyle?: boolean
  $centered?: boolean
  $wide?: boolean
  $fullWidth?: boolean
  $customStyle?: string
}

export const ButtonWrapper = styled.button<ButtonProps>`
  color: ${(props) => props.$foreColor || colors['UI/DarkGrey']};
  font-weight: 700;
  font-family: inherit;
  padding: 0.9rem 1.5rem 0.9rem;
  cursor: pointer;
  text-decoration: none;
  border-radius: ${(props) => (props.$custStyle ? '8px' : '3px')};
  border: ${(props) =>
    props.$level === 'tertiary' ? `1px solid ${colors['UI/Grey']}` : 'none'};
  background: ${(props) =>
    props.$linkStyle
      ? 'transparent'
      : backgroundColor(props.$level, props.$color)};
  text-align: center;
  font-size: 14px;
  display: block;
  margin: ${(props) => (props.$centered ? '0 auto' : null)};
  text-decoration: ${(props) =>
    (props.$linkStyle || props.$level === 'tertiary') && 'underline'};
  max-width: 100%;
  width: 100%;
  @media (min-width: ${breakpoints['desktop-small']}px) {
    max-width: ${(props) => (props.$wide ? '24rem' : '17rem')};
    max-width: ${(props) => props.$fullWidth && '100%'};
  }
  a {
    text-decoration: none;
  }
  ${(props) => props.$customStyle || ''}
`
