import { triggerProductEvent } from './dataLayer'
import { buildAssetsProductsProps } from './dmpgAttributes'
import { CHECKOUT_TYPES, TRACKING_STRINGS } from '../constants/globalStrings'

export const renderPaypalButton = ({
  paypalCheckoutInstance,
  funding,
  flow = 'vault',
  billingAgreementDescription,
  displayName,
  enableShippingAddress = true,
  shippingAddressEditable = true,
  handleSuccessCallback,
  handleErrorCallback,
  handleCancelCallback,
  buttonSelector,
  plan,
}) => {
  if (!window || !window.paypal) {
    return
  }
  const fundingSource = funding || window.paypal.FUNDING.PAYPAL
  window.paypal
    .Buttons({
      fundingSource,
      style: {
        label: 'buynow',
        height: 55,
      },
      createBillingAgreement() {
        /** Triggers an add to cart event when the order is created */
        triggerProductEvent(
          TRACKING_STRINGS.ADD_TO_CART,
          buildAssetsProductsProps(plan).products[0],
          CHECKOUT_TYPES.PAYPAL
        )
        return paypalCheckoutInstance.createPayment({
          flow,
          billingAgreementDescription,
          enableShippingAddress,
          shippingAddressEditable,
          displayName,
          commit: false,
        })
      },

      onApprove(data, actions) {
        return paypalCheckoutInstance.tokenizePayment(data).then((payload) => {
          if (handleSuccessCallback) {
            handleSuccessCallback({ payload, data, actions })
          }
        })
      },

      onCancel(data) {
        if (handleCancelCallback) {
          handleCancelCallback(data)
        }
      },

      onError(err) {
        if (handleErrorCallback) {
          handleErrorCallback(err)
        }
      },
    })
    .render(buttonSelector)
}

export const canUseApplePay = () =>
  window.location.protocol === 'https:' &&
  window.ApplePaySession &&
  window.ApplePaySession.supportsVersion(3) &&
  window.ApplePaySession.canMakePayments()

export const handleApplePayButton = ({
  applePayInstance,
  displayName,
  label,
  amount,
  handleSuccessCallback,
  handleErrorCallback,
}) => {
  if (canUseApplePay()) {
    const paymentRequest = applePayInstance.createPaymentRequest({
      total: {
        label,
        amount,
      },
      requiredBillingContactFields: ['postalAddress'],
    })

    const session = new window.ApplePaySession(3, paymentRequest)

    session.onvalidatemerchant = (event) => {
      applePayInstance
        .performValidation({
          validationURL: event.validationURL,
          displayName,
        })
        .then((merchantSession) => {
          session.completeMerchantValidation(merchantSession)
        })
        .catch((validationErr) => {
          // You should show an error to the user, e.g. 'Apple Pay failed to load.'
          console.error('Error validating merchant:', validationErr)
          handleErrorCallback(validationErr)
          session.abort()
        })
    }

    session.onpaymentauthorized = (event) => {
      applePayInstance
        .tokenize({
          token: event.payment.token,
        })
        .then((payload) => {
          handleSuccessCallback(payload)
          session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
        })
        .catch((tokenizeErr) => {
          console.error('Error tokenizing Apple Pay:', tokenizeErr)
          handleErrorCallback(tokenizeErr)
          session.completePayment(window.ApplePaySession.STATUS_FAILURE)
        })
    }

    session.begin()
  }
}
