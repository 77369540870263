import React, { FC } from 'react'

import cn from 'classnames'

import classes from './plans.module.scss'
import { usePlans } from '../../hooks'
import { PlanTilesBatch } from '../../modules/components'
import {
  DataDiscountInfoModal,
  DataOnlyInfoModal,
  VoiceInfoModal,
} from '../../modules/page-section/plan-batches/_parts'
import { getSlugsFromContentfulPlansCollections } from '../page-template/page-sections/utils'

const planSectionModalMapping = {
  voice_plans: <VoiceInfoModal />,
  data_only_plans: <DataOnlyInfoModal />,
  money_back_plans: <DataDiscountInfoModal />,
}

export const PlansSection: FC<Queries.GenericPageSectionFragment> = ({
  items,
  title,
  header,
  sectionName,
}) => {
  const { slugs, category } = getSlugsFromContentfulPlansCollections(items)
  const { list } = usePlans(slugs)

  if (!list.length) {
    return null
  }

  return (
    <div
      className={cn('redesign-container', classes.container)}
      data-module-id="plan-modules"
      data-module_name="simple-plans"
      data-module_placement="products-section"
      id={sectionName}
    >
      <PlanTilesBatch
        dataTestId={sectionName}
        moduleId={sectionName}
        moduleName={sectionName}
        title={title}
        subtitle={header || ''}
        plans={list}
        info={planSectionModalMapping[category]}
      />
    </div>
  )
}
