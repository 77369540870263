import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

const HeadingSecondary = styled.h2`
  margin-bottom: 1rem;
`

const ContentContainer = styled.div`
  overflow: hidden;

  @media (min-width: ${breakpoints.desktop}px) {
    max-width: 90%;
  }
`

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.25;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    font-size: 1.125rem;
    line-height: 1.44;
  }
`

export { HeadingSecondary, ContentContainer, Text }
