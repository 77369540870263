import React, { FC, SVGProps } from 'react'

import cn from 'classnames'

import classes from './icon.module.scss'

interface IconProps extends SVGProps<SVGSVGElement> {
  name: string
  alt?: string
}

export const Icon: FC<IconProps> = ({ className, name, ...props }) => {
  const href = `#${name}`

  return (
    <svg className={cn(classes.root, className)} {...props}>
      <use href={href} />
    </svg>
  )
}
