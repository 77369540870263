export const faqSectionContent = {
  faqTitleId: 'faqs-title',
  faqTitle: 'FAQs',
  moreInfoText: 'Have more questions?',
  moreInfoLink: 'https://help.smarty.co.uk',
  moreInfoDataItemName: 'have-more-questions-link',
  viewFullTermsAndConditions:
    'View the full terms and conditions of our referral scheme.',
  referAFriendTerms: 'https://smarty.co.uk/refer-a-friend-terms',
  onlyReferFriendFAQs: 'These FAQs are about our referrals scheme only.',
  viewFAQsOnNetwork: 'View FAQs about our network.',
}
