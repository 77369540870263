import React, { FC, useState } from 'react'

import { getThisPlanIncludesGroup } from '@smarty-nx/contentful'
import { BulletPointItem, Icon } from '@smarty-nx/ds'
import cn from 'classnames'

import classes from './this-plan-includes.module.scss'
import { Plan } from '../../../../page-section/popular-plans/types'
import { WiFi } from '../index'

declare module 'react' {
  interface CSSProperties {
    '--border-color'?: string
    '--checkmark-color-primary'?: string
    '--checkmark-color-secondary'?: string
  }
}

interface ThisPlanIncludesProps {
  selectedPlan: Plan
}

export const ThisPlanIncludes: FC<ThisPlanIncludesProps> = ({
  selectedPlan,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const handleClick = () => setIsOpen(!isOpen)
  const content = getThisPlanIncludesGroup(selectedPlan.planMeta.slug)
  return (
    <div className={classes.root}>
      <div
        className={
          isOpen
            ? classes.heading
            : cn(classes.heading, classes.headingCollapsed)
        }
      >
        This plan includes
        <Icon
          onClick={handleClick}
          name={isOpen ? 'expanded-roundel' : 'collapsed-roundel'}
          className={classes.toggleRoundel}
        />
      </div>
      <div className={classes.bodyRoot}>
        <div
          className={
            isOpen ? classes.body : cn(classes.body, classes.bodyCollapsed)
          }
          style={{
            '--border-color': selectedPlan.planMeta.color,
            '--checkmark-color-primary': selectedPlan.planMeta.colorLight,
            '--checkmark-color-secondary': selectedPlan.planMeta.color,
          }}
        >
          <ul className={classes.items}>
            {content?.planIncludes.map((item) => {
              return (
                <BulletPointItem
                  key={item.title}
                  text={item.title}
                  subText={item.subText}
                  as="li"
                />
              )
            })}
          </ul>
          <WiFi />
        </div>
      </div>
    </div>
  )
}
