import { breakpoints } from '@smarty-nx/ds'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 24px 0;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    flex-direction: row;
    justify-content: space-around;
  }
`

const Image = styled.img`
  display: block;
  width: 100%;
`

const renderStates = css`
  display: ${(props) => (props.showOnMobile ? 'block' : 'none')};
  @media (min-width: ${breakpoints['desktop-small']}px) {
    display: block;
  }
`

const LogoLink = styled.a`
  ${renderStates};
`

const DefaultImage = styled(Image)`
  max-height: 96px;
  width: auto;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    padding: 0 20px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 0 40px;
  }
`

const ImageLarge = styled(Image)`
  ${renderStates};
  max-width: 160px;
`

const ImageSmall = styled(ImageLarge)`
  ${renderStates};
  margin-bottom: 0;
  max-width: 140px;
  @media (min-width: ${breakpoints.tablet}px) {
    max-width: 180px;
  }
`

const HuggerPadding = css`
  padding: 24px 0;
`

export {
  Wrapper,
  Image,
  DefaultImage,
  ImageSmall,
  ImageLarge,
  LogoLink,
  HuggerPadding,
}
