// returns GB if greater than or equal to 1, MB if not
export const getDataUnit = (
  dataValue: number | null
): string | null | number => {
  if (dataValue) {
    return dataValue >= 1 ? 'GB' : 'MB'
  }

  return dataValue
}

export const getDataAllowance = (dataValue: number) =>
  dataValue >= 1 ? dataValue : dataValue * 1000

export const getFormattedDataAllowance = (dataValue: number) =>
  `${getDataAllowance(dataValue)}${getDataUnit(dataValue)}`
