// covers 01-31 day
const first2secondDay: Record<string, RegExp> = {
  '0': /[1-9]/, // 01 - 09
  '1': /\d/, // 10 - 19
  '2': /\d/, // 20 - 29
  '3': /[01]/, // 30 -31
}

// covers 01-12 month
const first2secondMonth: Record<string, RegExp> = {
  '0': /[1-9]/, // 01 - 09
  '1': /[0-2]/, // 10 - 12
}

export const mask = (value: string) => {
  const [firstDayDigit, , , firstMonthDigit] = value

  return [
    /[0-3]/,
    first2secondDay[firstDayDigit] || /\d/, // anything other defaults to any digit
    '/',
    /[01]/,
    first2secondMonth[firstMonthDigit] || /[1-9]/, // anything other defaults to any of 1 - 9 range
    '/',
    /[1-2]/,
    /\d/,
    /\d/,
    /\d/,
  ]
}
