import React, { FC } from 'react'

import { useInMediaRange } from '@smarty-nx/code-utils'
import { Tabs as SmartyTabs } from '@smarty-nx/ds'
import { kebabCase } from 'voca'

import classes from './tabs.module.scss'
import { TRACKING_CATEGORIES } from '../../../../constants/globalStrings'
import { isPageSection } from '../../../content-type-checks'
import { ListOfBanners } from '../list-of-banners/list-of-banners'

export const Tabs: FC<Queries.GenericPageSectionFragment> = (section) => {
  const isMobile = useInMediaRange(0, 479)
  const tabs = (section?.items || []).filter(isPageSection).map((section) => ({
    label: section.title,
    content: <ListOfBanners {...section} isFullHeightImage />,
    trackingProps: {
      'data-item-category': TRACKING_CATEGORIES.GENERAL_INTERACTION,
      'data-item-name': kebabCase(section.title),
    },
  }))

  return (
    <>
      {section.title && <div className={classes.title}>{section.title}</div>}
      <SmartyTabs
        className={classes.tabs}
        tabs={tabs}
        alignment={isMobile ? 'left' : 'center'}
        header={
          section.header && (
            <div className={classes.header}>{section.header}</div>
          )
        }
      />
    </>
  )
}
