import React, { FC } from 'react'

import { ContentfulProcessor } from '@smarty-nx/ds'
import cn from 'classnames'

import classes from './list-of-banners.module.scss'
import { ContentfulLink } from '../../../../components/contentful-link'
import { isBanner } from '../../../content-type-checks'

interface ListOfBannersProps extends Queries.GenericPageSectionFragment {
  isFullHeightImage?: boolean
}

export const ListOfBanners: FC<ListOfBannersProps> = (section) => (
  <div className={classes.root}>
    {/* Page section heading */}
    {section.header && <p className={classes.heading}>{section.header}</p>}

    {/* List of banners */}
    {section.items?.filter(isBanner).map((item) => (
      <div key={item.id} className={classes.item}>
        {item.image?.url && (
          <div
            className={cn(
              classes.imageBlock,
              section?.isFullHeightImage && classes.fullHeight
            )}
            style={{ backgroundImage: `url(${item.image?.url || ''})` }}
          />
        )}
        <div className={classes.content}>
          {item.title && <p className={classes.title}>{item.title}</p>}
          <div className={classes.text}>
            <ContentfulProcessor
              content={item.description?.internal.content || ''}
            />
          </div>
          {item.link?.internal.content && (
            <ContentfulLink
              link={item.link?.internal.content}
              className={classes.link}
            />
          )}
        </div>
      </div>
    ))}

    {/* Page section link */}
    {section.link?.internal.content && (
      <ContentfulLink
        link={section.link?.internal.content}
        className={classes.sectionLink}
      />
    )}
  </div>
)
