import React from 'react'

import { colors } from '@smarty-nx/ds'
import PropTypes from 'prop-types'

import {
  allPlansContent,
  dataOnlyPlanDetailsList,
  dataOnlyPlansContent,
  planDetailsLongList,
  socialTariffList,
  socialTariffPlansContent,
} from './AllPlans.content'
import {
  ChecklistHeader,
  ChecklistIcon,
  PlansContainer,
  StyledRectangle,
} from './AllPlans.styles'
import {
  CheckIcon,
  ListColumns,
  ListItem,
  ListParagraphSmall,
} from '../../components/styles/Lists'
import { Rectangle } from '../../components/styles/Shapes'
import WifiCallingBanner from '../../components/WifiCallingBanner'
import CheckImage from '../../static/images/checkmark.svg'
import FlyingSim from '../../static/images/flyingSimSmall.svg'

export const CheckItem = ({ iconTestId, text }) => (
  <ListItem>
    <CheckIcon src={CheckImage} alt="" data-testid={iconTestId} />
    <ListParagraphSmall>{text}</ListParagraphSmall>
  </ListItem>
)

CheckItem.propTypes = {
  iconTestId: PropTypes.string,
  text: PropTypes.string,
}

export const renderPlanDetailsList = (iconTestId, list) =>
  list.map((item, index) => (
    <CheckItem key={index} iconTestId={iconTestId} text={item} />
  ))

const AllPlans = ({
  hasIcon,
  headerTestId,
  iconTestId,
  isSocialTariff = false,
  isThreeColumns = false,
  listTestId,
  reducedHeaderSpacing,
  currentPlan,
  headerClassName,
}) => {
  const isDataOnly = currentPlan && currentPlan.plan_is_data_only
  let data = {
    title: isDataOnly ? dataOnlyPlansContent.title : allPlansContent.title,
    list: isDataOnly ? dataOnlyPlanDetailsList : planDetailsLongList,
  }

  if (isSocialTariff) {
    data.title = socialTariffPlansContent.title
    data.list = socialTariffList
  }

  return (
    <PlansContainer spacious>
      <StyledRectangle
        background={colors['UI/DarkGrey']}
        padding={reducedHeaderSpacing ? '10px 0' : '10px 24px'}
        textAlign="left"
        className={headerClassName}
      >
        <ChecklistHeader data-testid={headerTestId}>
          {data.title}
        </ChecklistHeader>
        {hasIcon ? <ChecklistIcon src={FlyingSim} alt="" /> : null}
      </StyledRectangle>
      <Rectangle
        background="rgba(149, 149, 149, .25)"
        padding="24px 16px 16px"
        textAlign="left"
      >
        <ListColumns isThreeColumns={isThreeColumns} data-testid={listTestId}>
          {renderPlanDetailsList(iconTestId, data.list)}
        </ListColumns>
        <WifiCallingBanner />
      </Rectangle>
    </PlansContainer>
  )
}

AllPlans.propTypes = {
  hasIcon: PropTypes.bool,
  headerTestId: PropTypes.string,
  iconTestId: PropTypes.string,
  isSocialTariff: PropTypes.bool,
  isThreeColumns: PropTypes.bool,
  listTestId: PropTypes.string,
  headerClassName: PropTypes.string,
  reducedHeaderSpacing: PropTypes.bool,
  currentPlan: PropTypes.shape(),
}

export default AllPlans
