import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import Checkmark from '../../static/images/checkmark.svg'
import { ChecklistItem } from '../styles/Checklist'

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.direction === 'row' && 'space-around'};
`

const PlanAdditionalInfo = ({ dataDiscount, direction = 'column' }) => (
  <Container id="additional-info" direction={direction}>
    <ChecklistItem fontSize="0.85rem" color="#444">
      <img src={Checkmark} alt="checkmark" />
      Data discount up to {dataDiscount} p/m
    </ChecklistItem>
    <ChecklistItem fontSize="0.85rem" color="#444">
      <img src={Checkmark} alt="checkmark" />1 month plan, cancel anytime
    </ChecklistItem>
    <ChecklistItem fontSize="0.85rem" color="#444">
      <img src={Checkmark} alt="checkmark" />
      No credit check
    </ChecklistItem>
    <ChecklistItem fontSize="0.85rem" color="#444">
      <img src={Checkmark} alt="checkmark" />
      Fast 5G &amp; 4G data
    </ChecklistItem>
    <ChecklistItem fontSize="0.85rem" color="#444">
      <img src={Checkmark} alt="checkmark" />
      Tethering/personal hotspot included
    </ChecklistItem>
    <ChecklistItem fontSize="0.85rem" color="#444">
      <img src={Checkmark} alt="checkmark" />
      Unlimited calls &amp; texts
    </ChecklistItem>
  </Container>
)

PlanAdditionalInfo.propTypes = {
  dataDiscount: PropTypes.string,
  direction: PropTypes.string,
}

export default PlanAdditionalInfo
