export const VOUCHER_CODE_KEY = 'vc'

// app links
export const IOS_APP =
  'https://apps.apple.com/app/apple-store/id1540812668?pt=421745&mt=8&ct=smarty_web_download'
export const ANDROID_APP =
  'https://play.google.com/store/apps/details?id=com.hutchison3g.sundae&referrer=utm_source%3Dsmarty%26utm_medium%3Dweb%26utm_content%3Dappstore_logo'

export const SMARTY_PRICE_GUIDE =
  'https://cdn.smarty.co.uk/files/SMARTY-Price-Guide.pdf'

export const TERMS_LINK =
  'https://cdn.smarty.co.uk/files/SMARTY-Refer-a-Friend.pdf'

export const TC_LINK =
  'https://cdn.smarty.co.uk/files/SMARTY-Terms-And-Conditions.pdf'

// Graphql Contentful typenames
export const FAQ = 'ContentfulFaq'
export const GENERIC_BANNER = 'ContentfulGenericBanner'
export const PAGE_SECTION = 'ContentfulPageSection'
export const PLANS_COLLECTION = 'ContentfulPlansCollection'
