export const convertPriceToDisplayGross = (price) => price / 100

export const convertPriceToDisplay = (price: number): string => {
  const inPounds = price / 100
  const asFixed = inPounds.toFixed(2)
  return `£${asFixed}`
}

export const convertDataToDisplay = (data, dataAmount) =>
  dataAmount > data ? dataAmount : data

export const pricePresenter = (value?: string) =>
  value ? value.replace(/\.00$/, '') : ''

export const toPriceString = (priceInPence: number): string => {
  const price = priceInPence / 100
  return Number.isInteger(price) ? `£${price}` : `£${price.toFixed(2)}`
}
