import { filterSupportedPlans } from '@smarty-nx/contentful'

import { isPlansCollection, isString } from '../../content-type-checks'

const getSlugs = (plansList: Queries.PlansFragment['plansList']) =>
  plansList?.flatMap((plan) => plan?.slug)

export const getSlugsFromContentfulPlansCollections = (
  items: Queries.GenericPageSectionFragment['items']
): {
  category: string
  slugs: string[]
} => {
  let category

  const plansCollection = items
    ?.filter(isPlansCollection)
    .map((item) => {
      category = item.category
      return item.plansList
    })
    .map((plans) => {
      return (plans || []).filter((plan) => plan && filterSupportedPlans(plan))
    })

  return {
    category,
    slugs: (plansCollection || [])?.flatMap(getSlugs).filter(isString),
  }
}
