import React from 'react'

import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import { Container, Image } from './styles'
import MaintenanceSVG from '../../static/images/maintenance.svg'
import SiteContainer from '../styles/SiteContainer'

const MaintenanceMessage = ({ endAt }) => (
  <SiteContainer>
    <Container>
      <Image
        src={MaintenanceSVG}
        alt="Site in maintenance."
        data-testid="maintenance-warning"
      />
      <h1 data-testid="maintenance-title">Site in maintenance</h1>
      <p data-testid="maintenance-message">
        We’re doing some maintenance at the moment. Come back soon. Sorry for
        any inconvenience.
      </p>
      {endAt && (
        <p data-testid="maintenance-end-at">
          The maintenance is expected to complete at{' '}
          {new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          }).format(endAt)}
        </p>
      )}
      <p data-testid="maintenance-signoff">— The SMARTY team.</p>
      <Link to="/" data-testid="go-to-website-link">
        Go to website
      </Link>
    </Container>
  </SiteContainer>
)

MaintenanceMessage.propTypes = {
  endAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
}

export default MaintenanceMessage
