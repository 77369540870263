import styled from 'styled-components'

export const StyledWrapper = styled.section<{
  compact?: boolean
  borderBottom?: boolean
}>`
  padding: ${({ compact }) => (compact ? '17px 0' : '2em 0 3em')};
  border-bottom: ${({ borderBottom }) => borderBottom && '1px solid #999'};
  min-height: ${({ compact }) => !compact && '140px'};
  max-width: 940px;
  margin: 0 auto;
`
