import { breakpoints } from '@smarty-nx/ds'
import styled, { css } from 'styled-components'

const MAX_WIDTH = 296

const OfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
    align-items: flex-start;
  }
`

const OfferItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  width: 100%;
  margin-bottom: 2.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    align-items: center;
    max-width: ${MAX_WIDTH}px;
  }
`

const customIcon = css`
  width: 120px;
  height: 80px;
  margin: 0 auto 0.5rem;

  @media (min-width: ${breakpoints.desktop}px) {
    width: 153px;
    height: 102px;
    margin-bottom: 1rem;
  }
`

const HeadingSecondary = styled.h2`
  margin-bottom: 1rem;
  width: 100%;
`

const OfferParagraph = styled.p`
  font-size: 16px;
  line-height: 1.25;

  @media (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 0;
  }
`

export {
  OfferContainer,
  OfferItem,
  customIcon,
  HeadingSecondary,
  OfferParagraph,
}
