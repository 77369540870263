import React, { MouseEventHandler } from 'react'

import { tooltipIconContent } from './TooltipIcon.content'
import { TooltipWrapper } from './TooltipIcon.styles'
import { IMAGES_PATH } from '../../constants/globalStrings'

interface TooltipIconProps {
  children: React.ReactNode
  callBack: MouseEventHandler<HTMLButtonElement>
  imageSrc?: string
}

const TooltipIcon = (props: TooltipIconProps) => {
  const {
    callBack,
    children,
    imageSrc = `${IMAGES_PATH}/general/tooltip-icon.svg`,
  } = props

  return (
    <TooltipWrapper>
      <button
        onClick={callBack}
        aria-label={tooltipIconContent.ariaLabel}
        data-item-category="GI"
        data-item-name="info_icon"
      >
        <img src={imageSrc} alt="" />
        {children}
      </button>
    </TooltipWrapper>
  )
}

export default TooltipIcon
