import React, { FC } from 'react'

import { getWhatWeOfferPageSection } from '@smarty-nx/contentful'
import cn from 'classnames'

import { Tile } from './_parts'
import classes from './what-we-offer.module.scss'

const section = getWhatWeOfferPageSection()

export const WhatWeOfferSection: FC = () => {
  if (!section) {
    return null
  }
  return (
    <div className={classes.root}>
      <div className={cn('redesign-container', classes.container)}>
        <div className={classes.title}>{section.title}</div>

        <div className={classes.tiles}>
          {section.items.map((item) => (
            <Tile key={item.type} {...item} />
          ))}
        </div>
      </div>
    </div>
  )
}
