export const updateTypename = (item: Record<string, unknown>) => {
  if (item.__typename === 'Plans') {
    return { ...item, __typename: `Contentful${item.__typename}Collection` }
  }

  return { ...item, __typename: `Contentful${item.__typename}` }
}
export const updateLink = (item: Record<string, unknown>) => {
  const link = item.link
  return { ...item, link: link ? { internal: { content: link } } : link }
}
const updateDescription = (item: Record<string, unknown>) => {
  const description = item.description
  return {
    ...item,
    description: description
      ? { internal: { content: description } }
      : description,
  }
}
const updateFaqContent = (item: Record<string, unknown>) => {
  const faqContent = item.faqContent
  return {
    ...item,
    faqContent: faqContent ? { internal: { content: faqContent } } : faqContent,
  }
}
const updateFaqLink = (item: Record<string, unknown>) => {
  const faqLink = item.faqLink
  return {
    ...item,
    faqLink: faqLink ? { internal: { content: faqLink } } : faqLink,
  }
}
const updatePlanList = (item: Record<string, any>) => {
  const plansList: undefined | { items: unknown[] } = item.plansList

  if (!plansList) {
    return item
  }

  return {
    ...item,
    plansList: plansList.items,
  }
}

const updateInnerItems = (item: Record<string, any>) => {
  const innerItems: undefined | { items: Record<string, any>[] } =
    item.itemsCollection

  if (!innerItems) {
    return item
  }

  return {
    ...item,
    items: innerItems.items
      .map(updateTypename)
      .map(updateLink)
      .map(updateDescription),
  }
}

export const updateItems = (item: Record<string, any>) => {
  const items = item.itemsCollection.items
    .map(updateTypename)
    .map(updateLink)
    .map(updateDescription)
    .map(updateFaqContent)
    .map(updateFaqLink)
    .map(updatePlanList)
    .map(updateInnerItems)

  return { ...item, items }
}
