import {
  FAQ,
  GENERIC_BANNER,
  PAGE_SECTION,
  PLANS_COLLECTION,
} from '../global.constants'

export const isBanner = (item: any): item is Queries.GenericBannerFragment =>
  item.__typename === GENERIC_BANNER

export const isFaq = (item: any): item is Queries.FaqFragment =>
  item.__typename === FAQ

export const isPageSection = (
  section: any
): section is Queries.GenericPageSectionFragment =>
  section && section.__typename === PAGE_SECTION

export const isPlansCollection = (item: any): item is Queries.PlansFragment =>
  item && item.__typename === PLANS_COLLECTION

export const isString = (value: any): value is string =>
  value && typeof value === 'string'

export const filterByAuthState =
  (isAuthenticated: boolean) => (item: Queries.GenericPageSectionFragment) => {
    return item.authState === null || item.authState === isAuthenticated
  }
