import { breakpoints, colors } from '@smarty-nx/ds'
import styled from 'styled-components'

import { theme } from '../../constants/theme'

const AccordionWrapper = styled.div`
  position: relative;

  overflow-y: hidden;
  border-bottom: 1px solid #959595;
  transition: all 0.5s ease;
  margin-bottom: 0.5rem;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: 1.5rem;
    &:first-child {
      margin-top: 0;
    }
  }

  p,
  ul,
  li {
    color: ${(props) =>
      props.darkText ? colors['Brand/PrimaryBlack'] : colors['UI/LightGrey']};
  }
`

const AccordionArrow = styled.div`
  display: block;
  height: auto;
  position: absolute;
  right: 5px;
  top: 1rem;
  transition: all 0.2s ease;
  transform: ${(props) => props.isOpen && 'scaleY(-1)'};
  width: 30px;

  img {
    width: 100%;
  }
`

const AccordionButton = styled.button`
  border: 0;
  background: none;
  padding: 0 3.5em 0 0;
  width: 100%;
  text-align: left;
  cursor: pointer;

  h3 {
    color: ${(props) =>
      props.darkText
        ? colors['Brand/PrimaryBlack']
        : colors['Brand/PrimaryGreen']};
    font-family: ${theme.fonts.fontFamily};
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin: 1rem 0 1rem;

    @media (min-width: ${breakpoints.tablet}px) {
      font-size: 24px;
      line-height: 1.2;
      margin: 1rem 0 0.6rem;
    }
  }
`

const AccordionContent = styled.div`
  margin-bottom: 1.2rem;

  p {
    margin-bottom: 0.5rem;
    font-size: 16px;
    line-height: 20px;

    @media (min-width: ${breakpoints.tablet}px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
`

export { AccordionWrapper, AccordionArrow, AccordionContent, AccordionButton }
