import React from 'react'

import { breakpoints } from '@smarty-nx/ds'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 24px 0;
  align-items: center;

  @media (min-width: ${breakpoints['desktop-small']}px) {
    flex-direction: row;
    justify-content: space-around;
  }
`

const Hugger = ({ children, ...props }) => <Root {...props}>{children}</Root>

export default Hugger

Hugger.propTypes = {
  children: PropTypes.node.isRequired,
}
