import React, { FC } from 'react'

import { getHomePagePlansCarouselSection } from '@smarty-nx/contentful'
import { Button } from '@smarty-nx/ds'
import { Link } from 'gatsby'

import classes from './popular-plans.module.scss'
import { PlansCarousel } from '../../components'

const section = getHomePagePlansCarouselSection()

const defaultPosition =
  section && section.selectedItem
    ? section.items.findIndex((item) => item.slug === section.selectedItem.slug)
    : 0

export const PopularPlansSection: FC = () => {
  if (!section || !section.items.length) {
    return null
  }
  const slugs = section.items.map((item) => item.slug)

  return (
    <div
      className={classes.root}
      data-module-id="popular-plans"
      data-module_name="popular_plans"
      data-module_placement="homepage"
      id="plans"
    >
      <PlansCarousel
        title={section.title}
        slugs={slugs}
        moduleId="popular-plans"
        heading={
          <Button
            level="tertiary"
            as={(props) => <Link {...props} to={'/all-plans'} />}
          >
            View all plans
          </Button>
        }
        defaultPosition={defaultPosition}
      />
    </div>
  )
}
