import { SmdlAssets, SmdlModule, SmdlModuleEvent } from '../../window'
import { TRACKING_STRINGS } from '../constants/globalStrings'
import {
  addTrackEvent,
  findAssets,
  findModules,
  moduleEvent,
} from '../static/scripts/dmpg'

/**
 *  Triggers dmpg event and builds product with id.
 * Also takes an optional checkout opton for cart events
 * */
export const triggerProductEvent = (
  event: string,
  product: { id: string; name: string; category: string } & Record<
    string,
    unknown
  >,
  checkoutType: string
) => {
  addTrackEvent(
    {
      event,
      checkoutType,
      product,
    },
    { extras: true }
  )
}

export const triggerModuleLoadEvent = (
  parentElement: Document,
  slug: string,
  moduleId: string
) => {
  const output = moduleEvent(parentElement, slug, moduleId)
  addTrackEvent(output)
}

export const triggerCustomModuleLoadEvent = (
  modules: SmdlModule[],
  assets?: SmdlAssets
) => {
  const output: SmdlModuleEvent = {
    event: TRACKING_STRINGS.MODULE_LOAD,
    modules,
  }

  if (assets) {
    output.assets = assets
  }

  addTrackEvent(output)
}

export const triggerModuleClickEvent = (
  id: string,
  type: string,
  moduleId: string
) => {
  addTrackEvent({
    event: TRACKING_STRINGS.MODULE_CLICK,
    moduleItem: {
      id: id,
      type: type,
      moduleId: moduleId,
    },
  })
}

export const triggerProductViewEvent = (
  product: { id: string; name: string; category: string } & Record<
    string,
    unknown
  >
) => {
  addTrackEvent({
    event: TRACKING_STRINGS.PRODUCT_VIEW,
    product,
  })
}

export const triggerModuleLoadEvent2 = (
  moduleId: string,
  event = TRACKING_STRINGS.MODULE_LOAD
) => {
  const moduleRoot = document.querySelector(
    `[data-module-id="${moduleId}"]`
  ) as HTMLElement

  addTrackEvent({
    event,
    modules: findModules(moduleId),
    assets: findAssets(moduleRoot),
  })
}
