import React from 'react'

import PropTypes from 'prop-types'

import { Wrapper } from './styles'

const PlanCardInner = ({ background, hasBorder, children, dataTestId }) => (
  <Wrapper
    background={background}
    hasBorder={hasBorder}
    data-testid={dataTestId}
  >
    {children}
  </Wrapper>
)

PlanCardInner.propTypes = {
  background: PropTypes.string,
  hasBorder: PropTypes.bool,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
}
export default PlanCardInner
