import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Promotion } from '@smarty-nx/data'

import api from '../../utils/api'
import { RootState } from '../rootReducer'

interface PromotionState {
  promotion: Promotion | null
  loading: boolean
}

export const fetchPromotions = createAsyncThunk<
  Promotion | void,
  void,
  { state: RootState }
>('promotions', async (_, { getState }) => {
  const state = getState()
  const jwt = state.dashboard.data?.auth.jwt

  if (jwt) {
    const result = await api.get('/v1/users/promotion', {
      headers: {
        Authorization: `Bearer: ${jwt}`,
      },
    })

    return result?.data?.data.attributes
  }
})

const initialState: PromotionState = {
  promotion: null,
  loading: false,
}

const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchPromotions.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchPromotions.fulfilled, (state, action) => {
      state.loading = false

      if (action.payload) {
        state.promotion = action.payload
      }
    })

    builder.addCase(fetchPromotions.rejected, (state) => {
      state.loading = false
    })
  },
})

export default promotionSlice.reducer
