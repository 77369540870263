import React, { FC } from 'react'

import { getHomepageBanner } from '@smarty-nx/contentful'
import { BulletPointItem, ContentfulProcessor } from '@smarty-nx/ds'
import cn from 'classnames'
import { Link } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'

import classes from './herobanner.module.scss'
import { ContentfulLink } from '../../../components/contentful-link'
import { FEATURES } from '../../../config'

type HeroBannerType = ReturnType<typeof getHomepageBanner> & {
  benefits?: string[]
  subHeading?: string
  mainImageLink?: string
}

const banner: HeroBannerType = getHomepageBanner()

export const HeroBanner: FC = () => (
  <div
    className={cn('redesign-container', classes.root)}
    data-module-name="hero"
  >
    <div className={cn(classes.container)}>
      <div className={classes.left}>
        <h1 className={classes.heading}>{banner.heading}</h1>

        {!!banner.subHeading && (
          <div className={classes.subheading}>
            <ContentfulProcessor content={banner.subHeading} />
          </div>
        )}

        {!!banner.benefits?.length && (
          <div className={classes.benefits}>
            {banner.benefits.map((text) => (
              <BulletPointItem text={text} key={text} />
            ))}
          </div>
        )}

        <div className={classes.ctas}>
          <ContentfulLink link={banner.primaryCta} level="primary" />
          <ContentfulLink link={banner.secondaryCta} level="secondary" />
        </div>

        {!FEATURES.HOME_PAGE_BANNER_CAROUSEL &&
          banner.additionalImages.length > 0 && (
            <div className={classes.images}>
              {banner.additionalImages.map(({ file, description, title }) => (
                <img key={file.url} src={file.url} alt={description || title} />
              ))}
            </div>
          )}
      </div>

      <div className={classes.right}>
        <div className={classes.mainImage}>
          {FEATURES.HOME_PAGE_BANNER_CAROUSEL ? (
            banner.mainImageLink ? (
              <ContentfulProcessor
                content={banner.mainImageLink}
                components={{
                  a({ node: _, href, ...props }) {
                    return (
                      <Link to={href || ''} {...props}>
                        <img
                          src={banner.mainImage.file.url}
                          alt={banner.mainImage.title}
                        />
                      </Link>
                    )
                  },
                }}
              />
            ) : (
              <img
                src={banner.mainImage.file.url}
                alt={banner.mainImage.title}
              />
            )
          ) : (
            <img src={banner.mainImage.file.url} alt={banner.mainImage.title} />
          )}
        </div>
      </div>
    </div>

    {FEATURES.HOME_PAGE_BANNER_CAROUSEL && banner.carousel && (
      <>
        {banner.carousel?.title && (
          <h2 className={classes.carouselTitle}>{banner.carousel?.title}</h2>
        )}
        <Swiper
          watchOverflow
          allowTouchMove
          slidesPerView="auto"
          navigation={false}
          spaceBetween={0}
          className={classes.swiper}
        >
          {banner.carousel?.items.map((item) => (
            <SwiperSlide key={item.type} className={classes.slide}>
              <img src={item.image?.url || ''} alt={item.image?.title || ''} />
              {item?.description && (
                <div className={classes.description}>
                  <ContentfulProcessor content={item.description} />
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    )}
  </div>
)
