import React, { FC } from 'react'

import { ContentfulProcessor, Icon } from '@smarty-nx/ds'

import classes from './heading.module.scss'

export const AllPlansHeading: FC<Queries.GenericBannerFragment> = ({
  title,
  description,
}) => {
  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.description}>
        <ContentfulProcessor
          content={description?.internal.content || ''}
          components={{
            span({ node: _, ...props }) {
              return <span className={classes.text} {...props} />
            },
            a({ node: _, children, href, ...props }) {
              return (
                <a className={classes.link} href={href || ''} {...props}>
                  {children}
                  <Icon name="arrow" className={classes.icon} />
                </a>
              )
            },
          }}
        />
      </div>
    </div>
  )
}
