import React from 'react'

export const content = {
  chatTitle:
    'Chat to our SMARTY Bot 24/7, or our customer support team from 8am–8pm',
  chatOpeningHours: (
    <span>
      Chat to our SMARTY bot 24/7, or our customer support team are available
      daily from 8am – 8pm to assist.
    </span>
  ),
  waitTimesAndOutsideOpeningTimes: (
    <>
      *Our wait times may vary depending on how busy we are. Our open days
      exclude Christmas Day and New Years Day. If you need help outside of these
      days or hours, check our out{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://help.smarty.co.uk/"
      >
        Help Centre
      </a>{' '}
      or{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://community.smarty.co.uk/"
      >
        Community
      </a>
      .
    </>
  ),
  ctaText: 'Start a conversation',
  dataItemName: 'customer-dedicated-email',
  emailText:
    "To chat to us, click 'start a conversation' below or select the web chat icon located at the bottom of your screen.",
}
