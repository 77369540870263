import React from 'react'

import PropTypes from 'prop-types'

import { BlockQuote, QuoteMark, Root, Source } from './styles'
import Mark from '../../static/images/QuotationMark.svg'
import MarkAlt from '../../static/images/QuotationMarkAlt.svg'

const Quote = ({
  text,
  source,
  useAltQuoteMark = false,
  hideQuoteMark = false,
  ...props
}) => {
  if (!text) return null
  return (
    <Root {...props}>
      {!hideQuoteMark && (
        <QuoteMark src={useAltQuoteMark ? MarkAlt : Mark} alt="" />
      )}
      <BlockQuote>{text}</BlockQuote>
      {source && <Source>{source}</Source>}
    </Root>
  )
}

export default Quote

Quote.propTypes = {
  text: PropTypes.string.isRequired,
  source: PropTypes.string,
  useAltQuoteMark: PropTypes.bool,
  hideQuoteMark: PropTypes.bool,
}
