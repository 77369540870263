import { breakpoints } from '@smarty-nx/ds'
import styled, { css } from 'styled-components'

import { Paragraph, Title } from '../../components/styles/Text'
import dsTheme from '../../constants/design-system/theme.json'

const { imagery, spacing, typography } = dsTheme

// there is not yet a design solution for mobile fonts so we'll use pixel values
export const Heading = styled(Title)`
  ${(props) =>
    props.custStyle
      ? typography['Kollektif/Large']
      : typography['Kollektif/ExtraLarge']}
  margin-bottom: ${(props) => (props.sell ? '16px' : '24px')};
  width: 100%;
  @media (min-width: ${breakpoints.desktop}px) {
    ${(props) =>
      props.custStyle
        ? typography['Kollektif/ExtraLarge']
        : typography['Kollektif/Mega']}
  }
`
export const SubHeading = styled(Paragraph)`
  ${typography['Kollektif/MediumEmphasis']}
  margin-bottom: ${(props) => (props.sell ? '16px' : '24px')};
  width: 100%;
`

export const BenefitsWrapper = styled.ul`
  ${spacing.ZeroMargin}
  ${spacing.ZeroPadding}
  padding-left: ${(props) => props.custStyle && '0'} !important;
  margin-bottom: 2rem;
  width: 100%;
  @media (min-width: ${breakpoints.desktop}px) {
    padding-left: ${(props) => props.custStyle && '0'} !important;
    margin-bottom: ${(props) =>
      props.custStyle ? '1.5rem' : '2.5rem'} !important;

`

export const Image = styled.div`
  background-image: url(${(props) => props.imageUrl && props.imageUrl});
  ${imagery.BackgroundDefault}
  height: 100%;
  position: relative;
`

export const wrapperStyles = css`
  height: 100%;
  max-width: none;
  min-width: 100%;
  overflow: hidden;
`

export const ContentWrapper = styled.div`
  ${wrapperStyles};
  margin-bottom: 1.5rem;
  @media (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 0;
  }
`

export const ImageWrapper = styled.div`
  ${wrapperStyles};
  height: 230px;
  @media (min-width: ${breakpoints.tablet}px) {
    height: 350px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    width: ${(props) => props.custStyle && '378px'};
    height: ${(props) => (props.custStyle ? '328px' : '400px')};
  }
`

export const reverseRightStyles = css`
  order: -1;
  margin-bottom: 2rem;
  @media (min-width: ${breakpoints.desktop}px) {
    order: 1;
    margin-bottom: 0;
  }
`

export const reverseLeftStyles = css`
  margin-bottom: -1.5rem;
  @media (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 0;
  }
`
