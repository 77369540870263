import { useEffect, useRef } from 'react'

import { useInViewport } from 'react-in-viewport'

export const useRunCallbackOnEnteringViewport = (callback: () => void) => {
  const callbackRef = useRef(callback)
  const ref = useRef<HTMLDivElement>(null)

  const { inViewport, enterCount } = useInViewport(
    ref,
    {},
    { disconnectOnLeave: true }
  )

  useEffect(() => {
    if (inViewport && enterCount === 1) {
      callbackRef.current()
    }
  }, [enterCount, inViewport])

  return ref
}
