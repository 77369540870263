import React, { FC } from 'react'

import { getReasonsToChoosePageSection } from '@smarty-nx/contentful'
import { Button, ContentfulProcessor, SliderOnMobile } from '@smarty-nx/ds'
import cn from 'classnames'
import { Link } from 'gatsby'

import { Tile } from './_parts'
import classes from './reasons-to-choose.module.scss'

const section = getReasonsToChoosePageSection()

export const ReasonsToChooseSection: FC = () => {
  if (!section) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={cn('redesign-container', classes.container)}>
        <div className={classes.title}>{section.title}</div>
      </div>

      <SliderOnMobile
        options={{ className: classes.slides, spaceBetween: 12 }}
        navigation={false}
        controlsClassName={classes.controls}
      >
        {section.items.map((item) => (
          <Tile {...item} key={item.type} />
        ))}
      </SliderOnMobile>

      <div className={cn('redesign-container', classes.container)}>
        <div className={classes.bottom}>
          <ContentfulProcessor
            content={section.link || ''}
            components={{
              a({ node: _, href, ...props }) {
                return (
                  <Button
                    level="secondary"
                    className={classes.bottomLink}
                    {...(props as Record<string, unknown>)}
                    as={(props) => <Link {...props} to={href || ''} />}
                  />
                )
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}
