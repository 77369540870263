import React from 'react'

import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'

import PageHead from '../../components/PageHead'
import {
  AltPara,
  Card,
  Container,
  Flag,
  Heading,
  List,
  SubHeading,
} from '../../components/styles/Roaming'
import SiteContainer from '../../components/styles/SiteContainer'
import GlobeSVG from '../../static/images/alt-globe.svg'
import { alphabeticalOrder } from '../../utils/sorting'

export default function International({ data }) {
  return (
    <SiteContainer>
      <p style={{ padding: '40px 0 0' }}>
        <Link to="/roaming">Roaming & international</Link>
        <span style={{ padding: '0 10px' }}>{'>'}</span> International
      </p>
      <Heading>
        <span>Travelling outside the EU?</span>
        <span>
          <Flag src={GlobeSVG} alt="" />
        </span>
      </Heading>
      <SubHeading>
        Buy “Out of plan” add-ons, then{' '}
        <Link to="/pay-as-you-go">pay as you go</Link>.
      </SubHeading>
      <AltPara>
        To call and text abroad from the UK, buy “Out of plan” add-ons then{' '}
        <Link to="/pay-as-you-go">pay as you go</Link>. The cost depends on the
        country you want to contact.
      </AltPara>
      <AltPara>
        Select the country you are travelling to to see the costs of using your
        phone while there.
      </AltPara>
      <Container style={{ marginBottom: '60px' }}>
        <List>
          {data.allRoamingPrice.edges.sort(alphabeticalOrder).map(
            ({ node }) =>
              node.country && (
                <li key={node.slug}>
                  <Link to={`/roaming/international/${node.slug}`}>
                    <Flag
                      src={`/assets/images/flags/${node.iso_country_code.toLowerCase()}.png`}
                      alt=""
                    />
                    {node.display_country}
                  </Link>
                </li>
              )
          )}
        </List>
        <Card>
          <h2 className="card-heading">Before you go</h2>
          <h2>Phone settings update required</h2>
          <p>
            Your SMARTY SIM won’t work abroad unless you make a one-off change
            to your phone settings.
          </p>
          <Link to="/roaming-setup">Set up your phone now</Link>
        </Card>
      </Container>
    </SiteContainer>
  )
}

International.propTypes = {
  data: {
    allRoamingPrice: {
      edges: {
        node: {
          display_country: PropTypes.string.isRequired,
          international_calls: PropTypes.number,
          slug: PropTypes.string.isRequired,
          iso_country_code: PropTypes.string.isRequired,
        },
      },
    },
  },
}

export const query = graphql`
  query {
    allRoamingPrice(filter: { european_country: { eq: false } }) {
      edges {
        node {
          display_country
          country
          slug
          iso_country_code
        }
      }
    }
  }
`

export const Head = () => (
  <PageHead title="International calling price finder" />
)
