import { breakpoints } from '@smarty-nx/ds'
import styled from 'styled-components'

import { Paragraph } from '../styles/Text'

export const Wrapper = styled.div`
  display: flex;
  height: 45px;
  justify-content: space-between;
  width: 100%;
`

export const CoverageParagraph = styled(Paragraph)`
  align-self: center;
  width: 65%;
  @media (min-width: ${breakpoints.tablet}px) {
    max-width: 400px;
    line-height: 45px;
    font-size: 16px;
    white-space: nowrap;
    width: 100%;
  }
`
