import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { CONFIG } from '../../config'

const defaultDescription =
  'SMARTY is a new SIM-only mobile network that’s built to be fair, transparent and smart.'

function SEO({
  description = defaultDescription,
  lang = 'en',
  additionalMeta = [],
  keywords = [],
  title,
  canonical,
  preventTitleTemplate,
  children,
  /* itemScope, itemProp, itemType are Google Microdata attributes */
  itemScope,
  itemType,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const meta = [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:image',
      content: '/assets/images/smarty_logo.png',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: site.siteMetadata.author,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:image',
      content: '/assets/images/smarty_logo.png',
    },
    {
      'http-equiv': 'x-ua-compatible',
      content: 'ie-edge',
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
    },
  ]
    .concat(
      keywords.length > 0
        ? {
            name: 'keywords',
            content: keywords.join(', '),
          }
        : []
    )
    .concat(additionalMeta)

  return (
    <Helmet
      htmlAttributes={{
        lang,
        itemScope,
        itemType: itemType || null,
      }}
      title={title}
      titleTemplate={
        preventTitleTemplate ? '' : `%s | ${site.siteMetadata.title}`
      }
      meta={meta}
    >
      {children}
      <link
        rel="shortcut icon"
        sizes="16x16 32x32"
        type="image/png"
        href={`${CONFIG.MARKETING_URL}favicon.png`}
      />
      <link
        rel="apple-touch-icon"
        href={`${CONFIG.MARKETING_URL}apple-touch-icon.png`}
      />
      {canonical && <link rel="canonical" href={canonical} />}
      <link
        rel="sitemap"
        type="application/xml"
        href={`${CONFIG.MARKETING_URL}sitemap.xml`}
      />
      <script
        src="https://www.dwin1.com/10933.js"
        type="text/javascript"
        defer="defer"
      />
    </Helmet>
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  additionalMeta: PropTypes.array,
  keywords: PropTypes.array,
  title: PropTypes.string.isRequired,
  canonical: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  preventTitleTemplate: PropTypes.bool,
  children: PropTypes.any,
  /* itemScope, itemType: Google structured data attributes */
  itemScope: PropTypes.any,
  itemType: PropTypes.string,
}

export default SEO
