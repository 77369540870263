import styled from 'styled-components'

export const BottomLeftRoundelWrapper = styled.div`
  position: relative;

  img {
    width: 64px;
    height: 64px;
    left: ${(props) => (props.isPlanCardSmall ? '-15px' : '17px')};
    position: absolute;
    top: ${(props) => (props.isPlanCardSmall ? '-50px' : '-40px')};
  }
`

export const BottomRightRoundelWrapper = styled.div`
  position: relative;

  img {
    width: 64px;
    height: 64px;
    right: -20px;
    position: absolute;
    top: -20px;
  }
`

export const TopRightRoundelWrapper = styled.div`
  position: relative;

  img {
    width: 64px;
    height: 64px;
    position: absolute;
    right: 194px;
    top: -182px;
  }
`

export const RoundelImage = styled.img`
  max-width: 64px;
  z-index: 2;
`
