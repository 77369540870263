import React from 'react'

import { colors } from '@smarty-nx/ds'
import PropTypes from 'prop-types'

import { WhyCard, WhyCardTitle } from './styles'
import Addons from '../../static/images/addons.svg'
import Checkmark from '../../static/images/checkmark.svg'
import NoContract from '../../static/images/noContracts.svg'
import { ThreeColumn } from '../styles/Columns'
import Icon from '../styles/Icon'
import { Paragraph } from '../styles/Text'

export const WhySmartyPlans = ({ darkTheme }) => (
  <section>
    <ThreeColumn>
      <WhyCard data-testid="one-month-plan-card">
        <Icon
          src={NoContract}
          alt=""
          width="64px"
          height="64px"
          margin="0 0 30px"
          data-testid="one-month-plan-icon"
        />
        <WhyCardTitle data-testid="one-month-plan-title">
          1 month plans
        </WhyCardTitle>
        <Paragraph
          color={darkTheme && colors['UI/LightGrey']}
          data-testid="one-month-plan-text"
        >
          Cancel or change plans anytime. No contracts, no credit checks and no
          surprises at the end of the month. Pay in advance and enjoy the
          flexibility.
        </Paragraph>
      </WhyCard>
      <WhyCard data-testid="keep-your-number-card">
        <Icon
          src={Checkmark}
          alt=""
          width="64px"
          height="64px"
          margin="0 0 30px"
          data-testid="keep-your-number-icon"
        />
        <WhyCardTitle data-testid="keep-your-number-title">
          Keep your number
        </WhyCardTitle>
        <Paragraph
          color={darkTheme && colors['UI/LightGrey']}
          data-testid="keep-your-number-text"
        >
          Simply text ‘PAC’ to 65075 to get a code valid for 1 month. We’ll walk
          you through steps to enter this code to transfer the phone number you
          want to keep.
        </Paragraph>
      </WhyCard>
      <WhyCard data-testid="tether-without-worring-card">
        <Icon
          src={Addons}
          alt=""
          width="64px"
          height="64px"
          margin="0 0 30px"
          data-testid="tether-without-worring-icon"
        />
        <WhyCardTitle data-testid="tether-without-worring-title">
          Tether without worrying
        </WhyCardTitle>
        <Paragraph
          color={darkTheme && colors['UI/LightGrey']}
          data-testid="tether-without-worring-text"
        >
          You can tether and hot-spot all of your data with no speed caps as
          long as you’re in the UK.
        </Paragraph>
      </WhyCard>
    </ThreeColumn>
  </section>
)

WhySmartyPlans.propTypes = {
  darkTheme: PropTypes.bool,
}
