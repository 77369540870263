import { useEffect, useRef } from 'react'

export const useOnKey = (
  keyCode: string,
  callback: (e: KeyboardEvent) => void
) => {
  const callbackRef = useRef(callback)

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.code === keyCode) {
        callbackRef.current(e)
      }
    }

    document.addEventListener('keydown', handler)

    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [keyCode])
}
