import React from 'react'

import { getPlansSlugs, planCollectionNames } from '@smarty-nx/contentful'
import PropTypes from 'prop-types'

import {
  HeadingSecondary,
  HeadingSecondarySmall,
  HeadingsWrapper,
  HeadingWithCoverage,
  List,
  ListItem,
  MainHeadingWrapper,
  PlansSectionContainer,
  PlansSectionHeading,
  PlansWrapper,
  ToolTipHeading,
  ToolTipPara,
} from './styles'
import CheckImage from '../../static/images/checkmark.svg'
import { matchPlanMeta } from '../../utils/plans'
import { TooltipModal } from '../Modals'
import { PlanCardSmall } from '../PlanCardSmall/PlanCardSmall'
import { CheckIcon } from '../styles/Lists'

const VOICE_PLAN_SLUGS = getPlansSlugs(planCollectionNames.VOICE_PLANS)
const DATA_ONLY_PLAN_SLUGS = getPlansSlugs(planCollectionNames.DATA_ONLY_PLANS)
const MONEY_BACK_PLAN_SLUGS = getPlansSlugs(
  planCollectionNames.MONEY_BACK_PLANS
)

const renderVoicePlansHeader = () => (
  <HeadingWithCoverage>
    <HeadingsWrapper>
      <MainHeadingWrapper>
        <HeadingSecondary data-testid="voice-plans-heading">
          Voice plans
        </HeadingSecondary>
        <TooltipModal header="Voice plans">
          <ToolTipHeading>
            Your choice of data, with unlimited calls &amp; texts. That’s simple
            and SMARTY.
          </ToolTipHeading>
          <ToolTipHeading>
            As always, 1 month plans. No contracts, no credit checks and no
            surprises at the end of the month. Cancel or change plans anytime.
            Pay in advance and enjoy the flexibility.
          </ToolTipHeading>
        </TooltipModal>
      </MainHeadingWrapper>
      <HeadingSecondarySmall
        id="voice-plans-small-heading"
        data-testid="voice-plans-small-heading"
      >
        Unlimited calls &amp; texts
      </HeadingSecondarySmall>
    </HeadingsWrapper>
  </HeadingWithCoverage>
)

const renderDataOnlyPlansHeader = () => (
  <HeadingWithCoverage>
    <HeadingsWrapper>
      <MainHeadingWrapper>
        <HeadingSecondary data-testid="data-only-plans-heading">
          Data Only plans
        </HeadingSecondary>
        <TooltipModal header="Data Only plans">
          <ToolTipHeading>
            No calls and texts, just great value data.
          </ToolTipHeading>
          <List>
            <ListItem>
              <CheckIcon src={CheckImage} alt="" />
              <ToolTipPara>
                Use data in the UK and roaming EU countries{' '}
              </ToolTipPara>
            </ListItem>
            <ListItem>
              <CheckIcon src={CheckImage} alt="" />
              <ToolTipPara>
                Unrestricted hotspotting and no speed limits
              </ToolTipPara>
            </ListItem>
            <ListItem>
              <CheckIcon src={CheckImage} alt="" />
              <ToolTipPara>No contract: 1 month plan</ToolTipPara>
            </ListItem>
          </List>
          <ToolTipHeading>
            <strong>Note: </strong>
            Data Only plans cannot be switched or upgraded to Voice plans or
            Money Back plans just yet.
          </ToolTipHeading>
        </TooltipModal>
      </MainHeadingWrapper>
      <HeadingSecondarySmall
        id="data-only-plans-small-heading"
        data-testid="data-only-plans-small-heading"
      >
        Just great value data
      </HeadingSecondarySmall>
    </HeadingsWrapper>
  </HeadingWithCoverage>
)

const renderMoneyBackPlansHeader = () => (
  <HeadingWithCoverage>
    <HeadingsWrapper>
      <MainHeadingWrapper>
        <HeadingSecondary data-testid="money-back-plans-heading">
          Data Discount plans
        </HeadingSecondary>
        <TooltipModal header="Data Discount plans">
          <ToolTipHeading>
            Get money back for unused data at the end of every month.
          </ToolTipHeading>
          <ToolTipHeading>
            Perfect for people who don’t use all their data and want to save
            money.
          </ToolTipHeading>
          <ToolTipHeading>
            The way it works is simple. The base cost of our data discount plans
            is £5, which covers unlimited standard calls, texts and running the
            network. The rest is simply for data. So we think it’s only fair to
            take the data you haven’t used and give you money back for it - at
            the same rate you bought it, £1 per GB.
          </ToolTipHeading>
          <ToolTipHeading>
            We even calculate using remaining MBs, not GBs.
          </ToolTipHeading>
          <ToolTipHeading>
            Any unused data credit gets used towards the amount you need to pay
            for your next month when you renew your plan.
          </ToolTipHeading>
        </TooltipModal>
      </MainHeadingWrapper>
      <HeadingSecondarySmall
        id="money-back-plans-small-heading"
        data-testid="money-back-plans-small-heading"
      >
        Get money back for unused data
      </HeadingSecondarySmall>
    </HeadingsWrapper>
  </HeadingWithCoverage>
)

const PlanSection = ({ currentPlan, plans }) => (
  <PlansSectionContainer id="plans">
    <PlansSectionHeading>All the data you need for less</PlansSectionHeading>
    {renderVoicePlansHeader()}
    <PlansWrapper data-testid="voice-plans">
      {VOICE_PLAN_SLUGS.map((p, index) => {
        const isCurrentPlan = currentPlan && p === currentPlan.slug
        const { priceData, planMeta } = matchPlanMeta(plans, p)
        return (
          <PlanCardSmall
            key={p}
            index={index}
            to={isCurrentPlan ? null : `/plans/${p}`}
            href={isCurrentPlan ? `/order/${p}` : null}
            planMeta={planMeta}
            priceData={priceData}
            // position is a data layer modules prop item
            // indicates the position of PlanCardSmall in the modules array
            // +1 to array value of 0
            position={index + 1}
            cta={isCurrentPlan ? 'Get this' : 'View'}
          />
        )
      })}
    </PlansWrapper>
    {renderDataOnlyPlansHeader()}
    <PlansWrapper data-testid="data-only-plans">
      {DATA_ONLY_PLAN_SLUGS.map((p, index) => {
        const isCurrentPlan = currentPlan && p === currentPlan.slug
        const { priceData, planMeta } = matchPlanMeta(plans, p)
        return (
          <PlanCardSmall
            key={p}
            index={index}
            to={isCurrentPlan ? null : `/plans/${p}`}
            href={isCurrentPlan ? `/order/${p}` : null}
            planMeta={planMeta}
            priceData={priceData}
            position={index + VOICE_PLAN_SLUGS.length + 1}
            cta={isCurrentPlan ? 'Get' : 'View'}
            isPlanDataOnly
          />
        )
      })}
    </PlansWrapper>
    {renderMoneyBackPlansHeader()}
    <PlansWrapper data-testid="money-back-plans">
      {MONEY_BACK_PLAN_SLUGS.map((p, index) => {
        const isCurrentPlan = currentPlan && p === currentPlan.slug
        const { priceData, planMeta } = matchPlanMeta(plans, p)
        return (
          <PlanCardSmall
            key={p}
            index={index}
            to={isCurrentPlan ? null : `/plans/${p}`}
            href={isCurrentPlan ? `/order/${p}` : null}
            planMeta={planMeta}
            priceData={priceData}
            // indicates the position of PlanCardSmall in the modules array
            // +12 because there are 11 plans above, + 1 to increase array value from 0 // TODO - stop this nonsense
            position={
              index + VOICE_PLAN_SLUGS.length + DATA_ONLY_PLAN_SLUGS.length + 1
            }
            cta={isCurrentPlan ? 'Get' : 'View'}
            isDiscountPlan
          />
        )
      })}
    </PlansWrapper>
  </PlansSectionContainer>
)

PlanSection.propTypes = {
  currentPlan: PropTypes.object,
  plans: PropTypes.array,
}

export default PlanSection
