import React, { forwardRef, InputHTMLAttributes } from 'react'

import cn from 'classnames'

import newClasses from './radio-button-new.module.scss'
import oldClasses from './radio-button.module.scss'

type Theme = 'dark' | 'light'

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  dataTestId: string
  description?: string
  theme?: Theme
  rootClassName?: string
  forceNewDesign?: boolean
  noMargin?: boolean
}

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      value,
      label,
      description,
      dataTestId,
      theme = 'dark',
      rootClassName = '',
      forceNewDesign = false,
      noMargin = false,
      ...rest
    },
    ref
  ) => {
    const isNoDescription = !description
    const isLightTheme = theme === 'light'

    const classes = forceNewDesign ? newClasses : oldClasses

    return (
      <div
        className={cn(
          classes.root,
          {
            [classes.centered]: isNoDescription,
            [classes.light]: isLightTheme,
            [classes.noMargin]: noMargin,
          },
          rootClassName
        )}
      >
        <input
          type="radio"
          ref={ref}
          value={value}
          data-testid={dataTestId}
          className={classes.input}
          {...rest}
        />
        <label
          data-testid={`${dataTestId}-label`}
          htmlFor={rest.id}
          className={cn(classes.label, {
            [classes.singleLabel]: isNoDescription,
          })}
        >
          {label}
          {description && (
            <div
              data-testid={`${dataTestId}-description`}
              className={classes.description}
            >
              {description}
            </div>
          )}
        </label>
      </div>
    )
  }
)
