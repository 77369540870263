import React, { FC } from 'react'

import { Icon } from '@smarty-nx/ds'

import classes from './wifi.module.scss'
import { TRACKING_CATEGORIES } from '../../../../../constants/globalStrings'

export const WiFi: FC = () => {
  return (
    <div className={classes.root}>
      <Icon name="wifi" className={classes.icon} />
      <div className={classes.text}>
        All plans include WiFi Calling to supercharge your signal
      </div>
      <a
        href="https://smarty.co.uk/blog/wifi-calling"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="wifi-calling-link"
        data-item-category={TRACKING_CATEGORIES.NAVIGATION}
        data-item-name="learn-more-wifi-calling"
        className={classes.link}
      >
        Learn more
      </a>
    </div>
  )
}
