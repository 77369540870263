import planUpsells from '../../data/planUpsells.json'

const getPlanUpsells = () => {
  if (typeof planUpsells === 'undefined') {
    return []
  } else {
    return planUpsells
  }
}

interface PlanUpsell {
  active: boolean
  planFrom: {
    id: string
  }
}

export const getPlanUpsell = (id: string) =>
  getPlanUpsells().find(
    (mapping: PlanUpsell) => mapping.planFrom.id === id && mapping.active
  )
