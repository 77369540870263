import { useEffect } from 'react'

export const useOnetrust = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const cookieConsent = urlParams.get('cookieConsent')
    const intervalId = setInterval(() => {
      if (window.OneTrust && cookieConsent) {
        window.OneTrust.ToggleInfoDisplay()
        window?.history.replaceState({}, document.title, '/')
        clearInterval(intervalId)
      }
    }, 1000)
  }, [])
}
