const lightRed = '#FFF0F0'
const lightGreen = '#eaf7f4'
const dark = '#424449'
const white = '#FFF'
const red = '#E00000'
const yellow = '#FFD67C'

export const theme = {
  colors: {
    background: {
      backgroundColor: dark,
      backgroundColorDarker: '#3A3C41',
      backgroundColorDarkest: '#333539',
      unlimitedLight: '#E7E2FF',
      rafYellow: yellow,
      green: '#8ED6C9',
      rafWhite: white,
    },
    card: {
      default: {
        backgroundColor: white,
        color: dark,
      },
      help: {
        backgroundColor: lightGreen,
        color: dark,
      },
      error: {
        backgroundColor: lightRed,
        textColor: red,
      },
      warning: {
        backgroundColor: yellow,
        textColor: dark,
      },
    },
    body: {
      light: white,
      dark,
    },
    // Highlights for headers, timeline stages etc
    highlights: {
      highlightGrey: '#57585C',
    },
    button: {
      buttonColor: '#8ED6C9',
      buttonColorHover: '#72aba1',
    },
    plans: {
      mini: '#FF7C8C',
      xsmall: '#EFB5E8',
      small: '#82C6FF',
      medium: yellow,
      large: '#FF9265',
      unlimited: '#B8ACF3',
      thirtyGB: '#FF9569',
      fiftyGB: '#92E9F6',
      threeGB: '#FCD8AE',
      fourGB: '#FFD67C',
    },
    pale: {
      mini: '#FFE0E4',
      xsmall: '#FFECFD',
      small: '#E4F3FF',
      medium: '#FFF9EB',
      large: '#FFF9EB',
      unlimited: '#E7E2FF',
      thirtyGB: '#FFEEE7',
      fiftyGB: '#DFF8F8',
      threeGB: '#FCF2E9',
    },
    fonts: {
      fontColor: white,
      green: '#8ED6C9',
      paleGreen: '#E8F7F4',
      yellow,
      grey: '#BDBDBD',
      lightGrey: '#D9D9D9',
      dark: '#333539',
      lightRed,
    },
  },
  fonts: {
    fontFamily: 'Kollektif, Arial, sans-serif',
    fontFamilySecondary: 'Montserrat, Arial, sans-serif',
    fontSize: '16px',
    fontSizeSmall: '14px',
  },
  sizes: {
    navbarHeight: '62px',
    siteBaseWidth: '940px',
    mobile: '479px',
    tablet: '767px',
    navbarMobile: '850px',
    desktopSmall: '1100px',
    blockWrapperWidth: '1020px',
  },
}
